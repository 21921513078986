import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getReturnWarehouseInfoTemplate } from '@/api/stock.js';
import util from '@/libs/util.js';
import axios from 'axios';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '模板导入',
      dialogVisible: false,
      uploadFileType: ['.xls', '.xlsx'],
      uploadURL: '',
      uploadToken: '',
      loading: false,
      errorMsg: null,
      successMsg: null,
      warehouseId: null,
      supplierId: null,
      errDialogVisible: false,
      errProductList: [],
      errMsg: null
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(warehouseId, supplierId) {
      var that = this;
      that.dialogVisible = true;
      that.errorMsg = null;
      that.successMsg = null;
      that.uploadURL = process.env.VUE_APP_API + '/erpReturnWarehouse/uploadExcel';
      that.uploadToken = {
        Authorization: util.cookies.get('token'),
        Os: 'web',
        PLATFORM: 'Web'
      };
      that.warehouseId = warehouseId;
      that.supplierId = supplierId;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    errDialogClose: function errDialogClose() {
      var that = this;
      that.errDialogVisible = false;
    },
    myUpload: function myUpload(content) {
      var _this = this;

      var that = this;
      that.errorMsg = null;
      that.successMsg = null;
      var form = new FormData();
      form.append('file', content.file);
      var config = {
        headers: content.headers,
        timeout: 1000 * 180
      };

      if (content.file.size / 1024 / 1024 > 30) {
        that.errorMsg = '不允许上传大于30MB的文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      var FileExt = content.file.name.replace(/.+\./, '');

      if (that.uploadFileType.indexOf('.' + FileExt.toLowerCase()) === -1) {
        that.errorMsg = '不允许上传此类型文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      this.loading = true;
      axios.post(content.action + '?warehouseId=' + that.warehouseId + '&manufacturerId=' + that.supplierId, form, config).then(function (res) {
        if (res.data.code !== 0) {
          that.errorMsg = res.data.msg;
          that.$message.error(that.errorMsg);
        } else {
          if (res.data.data.other.errCount > 0) {
            that.errMsg = res.data.data.other.errMsg;
            that.errDialogVisible = true;

            if (res.data.data.other.errProductList) {
              that.errProductList = res.data.data.other.errProductList;
              console.log('that.errProductList', that.errProductList);
            }
          }

          if (res.data.data.total > 0) {
            that.$notify.success('成功导入' + res.data.data.total + '条');
            that.successMsg = '成功导入' + res.data.data.total + '条';
            that.$emit('confirm', that.detailListFormat(res.data.data.list));
          } else {
            that.errorMsg = '导入失败，未找到相关数据';
            that.$message.error(that.errorMsg);
          }
        }
      }).catch(function (error) {
        if (error.response) {
          that.errorMsg = '文件上传失败,' + error.response.data;
        } else if (error.request) {
          that.errorMsg = '文件上传失败，服务器端无响应';
        } else {
          that.errorMsg = '文件上传失败，请求封装失败';
        }

        that.$message.error(that.errorMsg);
      }).finally(function () {
        _this.loading = false;
      });
    },
    templateDownload: function templateDownload() {
      getReturnWarehouseInfoTemplate({
        fileName: 'goodsStockImport.xlsx'
      }).then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = '寄售退货导入模板.xlsx'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    detailListFormat: function detailListFormat(rows) {
      var _this2 = this;

      var resultRows = [];
      rows.forEach(function (row, index) {
        resultRows.push({
          id: row.ID,
          rowIndex: index,
          productNumber: row.specs.productNumber,
          productName: row.main.productName,
          specificationModel: row.specs.specificationModel,
          stockQuantity: row.inventory.stock_quantity,
          normalQuantity: row.inventory.normalQuantity,
          consumedQuantity: row.inventory.consumedQuantity,
          receiptQuantity: row.inventory.receiptQuantity,
          issueLocking: row.inventory.issueLocking,
          salesPendingApproval: row.inventory.salesPendingApproval,
          productPackageLocking: row.inventory.productPackageLocking,
          batchNumber: row.inventory.batch_number,
          manufacturerDate: row.inventory.manufacturer_date,
          validityDate: row.inventory.validity_date,
          sterilizationBatchNumber: row.inventory.sterilization_batch_number,
          sterilizationDate: row.inventory.sterilization_date,
          trackingCode: row.inventory.trackingCode,
          unit: row.specs.unit,
          packNum: row.specs.packNum,
          packUnit: row.specs.packUnit,
          unitPrice: row.specs.wholesalePrice,
          registrationNumber: row.main.registrationNumber,
          productPlace: row.main.productPlace,
          brandName: row.main.brandName,
          wholesalePurchasePrice: row.specs.wholesalePurchasePrice,
          consignmentPurchasePrice: row.specs.consignmentPurchasePrice,
          marketPrice: row.specs.marketPrice,
          consignmentPrice: row.specs.consignmentPrice,
          wholesalePrice: row.specs.wholesalePrice,
          expirationDate: row.main.expirationDate,
          returnWarehouseQuantity: Number(row.returnWarehouseQuantity),
          inventoryId: row.inventoryId,
          returnWarehouseId: row.returnWarehouseId,
          afterTaxPrice: Number(row.inventory.afterTaxPrice).toFixed(2),
          mainId: row.mainId,
          specsId: row.specsId,
          inWarehouseQuantity: row.inWarehouseQuantity,
          returnedQuantity: row.returnedQuantity,
          availableQuantity: row.inWarehouseQuantity - row.returnedQuantity,
          checked: true,
          expireDays: _this2.expireDays(row.inventory.validity_date),
          returnPrice: Number(row.afterTaxPrice).toFixed(2),
          importQuantity: row.importQuantity,
          inWarehouseInfoId: row.ID
        });
      });
      return resultRows;
    },
    expireDays: function expireDays(sdate) {
      return sdate ? Math.ceil(dayjs(sdate).diff(dayjs(), 'days', true)) : 9999;
    }
  }
};