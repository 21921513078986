import { request } from '@/api/_service.js';
export function listSpecial(params) {
  return request({
    url: '/product/getSpecsList',
    method: 'get',
    params: params
  });
}
export function listSpecialExport(params) {
  return request({
    url: '/product/getSpecsList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function getSpecsTemplate(params) {
  return request({
    url: '/product/getSpecsTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findSpecial(params) {
  return request({
    url: '/product/findSpecs',
    method: 'get',
    params: params
  });
}
export function createSpecial(data) {
  return request({
    url: '/product/createSpecs',
    method: 'post',
    data: data
  });
}
export function updateSpecial(data) {
  return request({
    url: '/product/updateSpecs',
    method: 'put',
    data: data
  });
}
export function deleteSpecial(data) {
  return request({
    url: '/product/deleteSpecs',
    method: 'delete',
    data: data
  });
}
export function deleteSpecialByIds(data) {
  return request({
    url: '/product/deleteSpecsByIds',
    method: 'delete',
    data: data
  });
}
export function updatePrice(data) {
  return request({
    url: '/product/updateSpecs2',
    method: 'put',
    data: data
  });
}
export function updateSpecs3(data) {
  return request({
    url: '/product/updateSpecs3',
    method: 'put',
    data: data
  });
}