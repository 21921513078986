var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "物资及规格导入",
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-upload",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "flex-start"
                  },
                  attrs: {
                    "show-file-list": false,
                    "auto-upload": true,
                    "on-progress": _vm.uploadProgress,
                    "on-success": _vm.uploadSuccess,
                    "on-error": _vm.uploadError,
                    "on-change": _vm.uploadChange,
                    action: _vm.uploadURL,
                    headers: _vm.uploadToken,
                    accept: _vm.uploadFileType.toString()
                  }
                },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("点击导入物资")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                { on: { click: _vm.download } },
                [
                  _c("d2-icon", { attrs: { name: "download" } }),
                  _vm._v(" 导入模板下载 ")
                ],
                1
              )
            ],
            1
          ),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "remark" }, [
              _c("p", [_vm._v("备注：")]),
              _c("p", [
                _vm._v("1、导入功能可新增物资或者更新已存在物资数据。")
              ]),
              _c("p", [_vm._v("2、红色部分为医院管理字段")]),
              _c("p", [_vm._v("3、“ * ” 为必填项")])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }