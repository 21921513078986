var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "margin-bottom": "10px"
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { "label-width": "70px", size: "small", inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.form,
                    callback: function($$v) {
                      _vm.form = $$v
                    },
                    expression: "form"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-popover",
                        {
                          ref: "queryPopover",
                          attrs: {
                            placement: "bottom-start",
                            title: "选择订单",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading"
                                }
                              ],
                              staticStyle: { width: "800px" },
                              attrs: {
                                data: _vm.orderList,
                                border: "",
                                "highlight-current-row": "",
                                size: "mini",
                                "max-height": "260px",
                                "header-cell-style": {
                                  background: "#f5f7fa",
                                  borderColor: "#ebeef5",
                                  color: "#333"
                                }
                              },
                              on: {
                                "row-click": _vm.orderRowClick,
                                "row-dblclick": _vm.orderRowDbClick
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  property: "orderNumber",
                                  label: "订单编号",
                                  width: "200",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "inWarehouseType",
                                  label: "类型",
                                  width: "200",
                                  align: "center",
                                  formatter: function(row) {
                                    return _vm.inTypeFormatter(
                                      row.inWarehouseType
                                    )
                                  },
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "manufacturerName",
                                  label: "供应商",
                                  width: "200",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "inWarehouseName",
                                  label: "入库人",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "quantitySum",
                                  label: "入库总数量",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "inWarehouseTime",
                                  label: "入库时间",
                                  width: "200",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { margin: "10px 0" } },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  background: "",
                                  layout:
                                    "total, sizes, prev, pager, next, jumper",
                                  align: "center",
                                  total: _vm.orderForm.total,
                                  "page-sizes": [5, 10, 20, 50, 100],
                                  "current-page": _vm.orderForm.page,
                                  "page-size": _vm.orderForm.pageSize
                                },
                                on: {
                                  "current-change": _vm.orderPageChange,
                                  "size-change": _vm.orderSizeChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "primary" },
                              on: { click: _vm.loadOrder },
                              slot: "reference"
                            },
                            [_vm._v("选择订单")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库单号" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入入库单号" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.formSubmit.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.form.orderNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "orderNumber", $$v)
                          },
                          expression: "form.orderNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("查 询")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [
                _c("span", [_vm._v("仓库：" + _vm._s(_vm.warehouseName))]),
                _c("span", { staticStyle: { margin: "0 10px" } }, [
                  _vm._v("入库数合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.inWarehouseQuantitySum))
                  ])
                ]),
                _c("span", [
                  _vm._v("导入数合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.deliveryQuantitySum))
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "detailListTable",
              attrs: {
                data: _vm.detailList,
                border: "",
                "highlight-current-row": "",
                size: "mini",
                "max-height": "260px",
                "row-class-name": _vm.rowClassName,
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "40",
                  fixed: "",
                  selectable: _vm.canBeSelect
                }
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "60",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "productNumber",
                  label: "产品编号",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "batchNumber",
                  label: "产品批号",
                  width: "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "",
                  label: "产地/品牌",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            _vm._s(scope.row.productPlace) +
                              "/" +
                              _vm._s(scope.row.brandName)
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2727652499
                )
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "productName",
                  label: "物资名称",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "normalQuantity",
                  label: "可用数量",
                  align: "center",
                  fixed: "right"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "inWarehouseQuantity",
                  label: "入库数",
                  align: "center",
                  fixed: "right"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "150",
                  property: "deliveryQuantity",
                  label: "导入数",
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              controls: false,
                              min: 0,
                              max: row.normalQuantity
                            },
                            model: {
                              value: row.deliveryQuantity,
                              callback: function($$v) {
                                _vm.$set(row, "deliveryQuantity", $$v)
                              },
                              expression: "row.deliveryQuantity"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  712962276
                )
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "specificationModel",
                  label: "规格型号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "unit",
                  label: "单位",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "registrationNumber",
                  label: "注册证号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "genericName",
                  label: "通用名",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  align: "center",
                  total: _vm.detailForm.total,
                  "page-sizes": [5, 10, 20, 50, 100],
                  "current-page": _vm.detailForm.page,
                  "page-size": _vm.detailForm.pageSize
                },
                on: {
                  "current-change": _vm.detailPageChange,
                  "size-change": _vm.detailSizeChange
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.importConfirm }
                },
                [_vm._v("确认导入")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }