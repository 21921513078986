import { render, staticRenderFns } from "./return_edit.vue?vue&type=template&id=66abbe73&"
import script from "./return_edit.vue?vue&type=script&lang=js&"
export * from "./return_edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\ERP\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('66abbe73')) {
      api.createRecord('66abbe73', component.options)
    } else {
      api.reload('66abbe73', component.options)
    }
    module.hot.accept("./return_edit.vue?vue&type=template&id=66abbe73&", function () {
      api.rerender('66abbe73', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/stock/outwarehouse/procurement/return_edit.vue"
export default component.exports