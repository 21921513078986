import { render, staticRenderFns } from "./hospital.vue?vue&type=template&id=d9b758ce&"
import script from "./hospital.vue?vue&type=script&lang=js&"
export * from "./hospital.vue?vue&type=script&lang=js&"
import style0 from "./hospital.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\ERP\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d9b758ce')) {
      api.createRecord('d9b758ce', component.options)
    } else {
      api.reload('d9b758ce', component.options)
    }
    module.hot.accept("./hospital.vue?vue&type=template&id=d9b758ce&", function () {
      api.rerender('d9b758ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/financial/config/centralized/components/tree/hospital.vue"
export default component.exports