var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.dialogClose,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("盒子列表：")])
                ])
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("包名称：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.packTypeRow.name))
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("包编码：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.packTypeRow.barCode))
                  ])
                ])
              ]),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini" },
                      on: { change: _vm.boxChange },
                      model: {
                        value: _vm.boxRowID,
                        callback: function($$v) {
                          _vm.boxRowID = $$v
                        },
                        expression: "boxRowID"
                      }
                    },
                    _vm._l(_vm.boxList, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("盒名称：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.boxRow.name))
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("盒容量：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.boxRow.usage) +
                        " / " +
                        _vm._s(_vm.boxRow.capacity)
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm.listType === "box"
            ? _c("div", { staticClass: "module-list" }, [
                _vm.boxRow.boxType === 1
                  ? _c(
                      "div",
                      _vm._l(_vm.moduleList, function(item, index) {
                        return _c(
                          "table",
                          {
                            key: "box-" + index,
                            staticStyle: {
                              border: "1px solid #EBF1F6",
                              margin: "10px 0",
                              width: "100%"
                            }
                          },
                          [
                            _c("caption", [_vm._v(_vm._s(item.name))]),
                            _c(
                              "tr",
                              { staticStyle: { height: "30px" } },
                              [
                                _c("td"),
                                _vm._l(item.xLabel, function(xItem, xIndex) {
                                  return _c("td", { key: xIndex }, [
                                    _vm._v(
                                      " " + _vm._s(item.xLabel[xIndex]) + " "
                                    )
                                  ])
                                })
                              ],
                              2
                            ),
                            _vm._l(item.yLabel, function(yItem, yIndex) {
                              return _c(
                                "tr",
                                {
                                  key: yIndex,
                                  staticStyle: { height: "30px" }
                                },
                                [
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(item.yLabel[yIndex]) + " "
                                    )
                                  ]),
                                  _vm._l(item.xLabel, function(xItem, xIndex) {
                                    return _c(
                                      "td",
                                      {
                                        key: xIndex,
                                        attrs: { align: "center" }
                                      },
                                      [
                                        !_vm.takenCheck(item, xIndex, yIndex)
                                          ? _c("i", {
                                              class:
                                                "fa fa-icon fa-circle-o" +
                                                (_vm.currentPos.id ===
                                                  item.ID &&
                                                _vm.currentPos.x === xIndex &&
                                                _vm.currentPos.y === yIndex
                                                  ? " fa-selected"
                                                  : ""),
                                              attrs: { "aria-hidden": "true" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.posClick(
                                                    item,
                                                    xIndex,
                                                    yIndex
                                                  )
                                                }
                                              }
                                            })
                                          : _c("i", {
                                              class: _vm.getClassList(
                                                item,
                                                xIndex,
                                                yIndex
                                              ),
                                              attrs: { "aria-hidden": "true" },
                                              on: {
                                                click: function(e) {
                                                  return _vm.posView(
                                                    item,
                                                    xIndex,
                                                    yIndex,
                                                    e
                                                  )
                                                }
                                              }
                                            })
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.boxRow.boxType === 2 || _vm.boxRow.boxType === 3
                  ? _c(
                      "div",
                      [
                        _vm.isScanInWarehouse
                          ? _c("ScanInwarehouseComponent", {
                              ref: "ScanInwarehouseComponentRef",
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                lastMainCode: _vm.lastMainCode,
                                "tip-message": _vm.tipMessage
                              },
                              on: {
                                add: _vm.addToBatchList,
                                "update:lastMainCode": function($event) {
                                  _vm.lastMainCode = $event
                                },
                                "update:last-main-code": function($event) {
                                  _vm.lastMainCode = $event
                                }
                              },
                              model: {
                                value: _vm.scanBarCode,
                                callback: function($$v) {
                                  _vm.scanBarCode = $$v
                                },
                                expression: "scanBarCode"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.stockLoading,
                                expression: "stockLoading"
                              }
                            ],
                            ref: "stockListTable",
                            attrs: {
                              data: _vm.stockList.slice(
                                (_vm.stockForm.page - 1) *
                                  _vm.stockForm.pageSize,
                                _vm.stockForm.page * _vm.stockForm.pageSize
                              ),
                              border: "",
                              "highlight-current-row": "",
                              size: "mini",
                              "header-cell-style": {
                                background: "#f5f7fa",
                                borderColor: "#ebeef5",
                                color: "#333"
                              }
                            },
                            on: { "row-click": _vm.rowClick }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "序号",
                                width: "60",
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (_vm.stockForm.page - 1) *
                                                _vm.stockForm.pageSize +
                                                scope.$index +
                                                1
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2621610912
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "productNumber",
                                label: "产品编号",
                                align: "center",
                                "show-overflow-tooltip": ""
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "200",
                                prop: "productName",
                                label: "物资名称",
                                align: "center",
                                "show-overflow-tooltip": ""
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "genericName",
                                label: "通用名",
                                align: "center",
                                "show-overflow-tooltip": ""
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "specificationModel",
                                label: "规格型号",
                                align: "center",
                                "show-overflow-tooltip": ""
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "batchNumber",
                                label: "生产批号",
                                align: "center",
                                "show-overflow-tooltip": ""
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "quantityUsed",
                                label: "出库数量",
                                align: "center"
                              }
                            }),
                            !_vm.isScanInWarehouse
                              ? _c("el-table-column", {
                                  attrs: {
                                    width: "150",
                                    prop: "consumedQuantity",
                                    label: "使用数量",
                                    align: "center"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input-number", {
                                              attrs: {
                                                min: 0,
                                                max: row.quantityUsed,
                                                size: "mini"
                                              },
                                              model: {
                                                value: row.consumedQuantity,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "consumedQuantity",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.consumedQuantity"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1801751724
                                  )
                                })
                              : _vm._e(),
                            _vm.isScanInWarehouse
                              ? _c("el-table-column", {
                                  attrs: {
                                    width: "150",
                                    prop: "quantityBacak",
                                    label: "回库数量",
                                    align: "center"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input-number", {
                                              attrs: {
                                                min: 0,
                                                max: row.quantityUsed,
                                                size: "mini"
                                              },
                                              model: {
                                                value: row.quantityBacak,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "quantityBacak",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.quantityBacak"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2580614062
                                  )
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "trackingCode",
                                label: "序列号",
                                align: "center",
                                "show-overflow-tooltip": ""
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "unit",
                                label: "单位",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "200",
                                prop: "registrationNumber",
                                label: "注册证号",
                                align: "center",
                                "show-overflow-tooltip": ""
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "manufacturerDate",
                                label: "生产日期",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "validityDate",
                                label: "有效期",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "200",
                                prop: "",
                                label: "产地/品牌",
                                align: "center",
                                "show-overflow-tooltip": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          _vm._s(scope.row.productPlace) +
                                            "/" +
                                            _vm._s(scope.row.brandName)
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2727652499
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "sterilizationBatchNumber",
                                label: "灭菌批号",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "100",
                                prop: "sterilizationDate",
                                label: "灭菌效期",
                                align: "center"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { margin: "10px 0" },
                            attrs: { align: "center" }
                          },
                          [
                            _c("el-pagination", {
                              attrs: {
                                background: "",
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                                "page-sizes": [5, 10, 20, 30, 50],
                                total: _vm.stockForm.total,
                                "current-page": _vm.stockForm.page,
                                "page-size": _vm.stockForm.pageSize
                              },
                              on: {
                                "current-change": _vm.stockPageChange,
                                "size-change": _vm.stockSizeChange
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.listType === "detail"
            ? _c(
                "div",
                { staticClass: "product-list" },
                [
                  _c("YTable", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.stockLoading,
                        expression: "stockLoading"
                      }
                    ],
                    ref: "dragTableRef",
                    attrs: {
                      hasIndex: true,
                      hasPagination: true,
                      pagination: _vm.stockForm,
                      "route-name":
                        "stock-outwarehouse-consum-info-detail-table",
                      columns: _vm.columns,
                      data: _vm.stockList.slice(
                        (_vm.stockForm.page - 1) * _vm.stockForm.pageSize,
                        _vm.stockForm.page * _vm.stockForm.pageSize
                      ),
                      sortClass: _vm.sortClass
                    },
                    on: {
                      pageChange: _vm.stockPageChange,
                      sizeChange: _vm.stockSizeChange,
                      sortChange: _vm.sortChange
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "productPlace",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(row.productPlace) +
                                  "/" +
                                  _vm._s(row.brandName) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1224712334
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-popover",
            {
              ref: "consumInfoPopover",
              attrs: {
                placement: "right",
                title: "物资详情",
                width: "450",
                trigger: "click"
              },
              model: {
                value: _vm.consumInfoPopoverVisible,
                callback: function($$v) {
                  _vm.consumInfoPopoverVisible = $$v
                },
                expression: "consumInfoPopoverVisible"
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-close",
                staticStyle: {
                  position: "absolute",
                  top: "0",
                  right: "0",
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  cursor: "pointer"
                },
                on: { click: _vm.consumInfoPopoverClose }
              }),
              _vm.currentConsumInfo.consumInfo
                ? _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("产品编号：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(_vm.currentConsumInfo.consumInfo.productNumber)
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("产品名称：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(_vm.currentConsumInfo.consumInfo.productName)
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("生产批号：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(_vm.currentConsumInfo.consumInfo.batchNumber)
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("规格型号：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentConsumInfo.consumInfo.specificationModel
                          )
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("注册证号：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentConsumInfo.consumInfo.registrationNumber
                          )
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("生产日期：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentConsumInfo.consumInfo.manufacturerDate
                          )
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("使用期限：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(_vm.currentConsumInfo.consumInfo.validityDate)
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("序列号：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(_vm.currentConsumInfo.consumInfo.trackingCode)
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("产品材料：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(_vm.currentConsumInfo.consumInfo.material)
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("灭菌方式：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentConsumInfo.consumInfo.sterilizationWays
                          )
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("灭菌批号：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentConsumInfo.consumInfo
                              .sterilizationBatchNumber
                          )
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("灭菌效期：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentConsumInfo.consumInfo.sterilizationDate
                          )
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("包装方式：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(
                          _vm._s(_vm.currentConsumInfo.consumInfo.packNum) +
                            _vm._s(_vm.currentConsumInfo.consumInfo.unit) +
                            "/" +
                            _vm._s(_vm.currentConsumInfo.consumInfo.packUnit)
                        )
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("条码主码：")
                      ]),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(_vm._s(_vm.currentConsumInfo.consumInfo.barCode))
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _vm._v("生产厂家：")
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _vm._v(
                          _vm._s(_vm.currentConsumInfo.consumInfo.manufacturer)
                        )
                      ]),
                      _vm.checked
                        ? _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                class:
                                  _vm.currentConsumInfo.consumInfo
                                    .consumedNot === 1
                                    ? "select-normal"
                                    : "select-primary",
                                staticStyle: {
                                  padding: "10px",
                                  margin: "10px 0 0 0"
                                }
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      "true-label": 2,
                                      "false-label": 1
                                    },
                                    on: { change: _vm.usedChange },
                                    model: {
                                      value:
                                        _vm.currentConsumInfo.consumInfo
                                          .consumedNot,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.currentConsumInfo.consumInfo,
                                          "consumedNot",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentConsumInfo.consumInfo.consumedNot"
                                    }
                                  },
                                  [_vm._v("已使用此物资")]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "10px 0" },
              attrs: { align: "center" }
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("关 闭")
              ]),
              _vm.listType === "box" && _vm.checked
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("提 交")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }