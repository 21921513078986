var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购订单")]),
              _c("el-breadcrumb-item", [_vm._v("创建采购入库单")])
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "font-size": "14px", color: "#606266" } },
            [
              _vm._v(
                " 采购单号：" + _vm._s(_vm.orderInfo.purchaseOrderNumber) + " "
              )
            ]
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "show-message": false,
              "label-suffix": ":",
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "供应商" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.manufacturerName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库人", prop: "inWarehouseUid" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", filterable: "" },
                            model: {
                              value: _vm.form.inWarehouseUid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "inWarehouseUid", $$v)
                              },
                              expression: "form.inWarehouseUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "inWarehouseUid" + item.id,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库时间", prop: "inWarehouseTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            format: "yyyy-MM-dd HH:mm:ss",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetime",
                            placeholder: "选择日期时间"
                          },
                          model: {
                            value: _vm.form.inWarehouseTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "inWarehouseTime", $$v)
                            },
                            expression: "form.inWarehouseTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "仓库" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.warehouseName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "归属公司" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.orderInfo.subsidiary.companyName) +
                            " "
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "随货同行单号", prop: "dealerOrderNo" }
                      },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "随货同行单号",
                              maxlength: "100",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.dealerOrderNo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "dealerOrderNo", $$v)
                              },
                              expression: "form.dealerOrderNo"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "备注",
                              maxlength: "200",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", size: "mini" },
                    on: { click: _vm.scanInput }
                  },
                  [_vm._v("扫码添加")]
                )
              ],
              1
            ),
            _c("div", { staticStyle: { "font-size": "14px" } }, [
              _c("span", [
                _vm._v("订单数量："),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.totalOrderQuantity))
                ])
              ]),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v("已加批次数："),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.totalBatchQuantity))
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                }
              },
              [
                _c("div", { staticStyle: { width: "450px" } }, [
                  _c("input", {
                    ref: "scanInputBoxRef",
                    staticClass: "bar-input",
                    attrs: { placeholder: "请扫描产品条码", clearable: "" },
                    on: { change: _vm.barCodeSearch }
                  })
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailTableRef",
          attrs: {
            hasExpand: true,
            hasIndex: true,
            hasPagination: true,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.detailList.slice(
              (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
              _vm.detailForm.page * _vm.detailForm.pageSize
            ),
            rowClassName: _vm.rowClassName
          },
          on: {
            pageChange: _vm.detailPageChange,
            sizeChange: _vm.detailSizeChange,
            "row-click": _vm.rowClick
          },
          scopedSlots: _vm._u([
            {
              key: "expand",
              fn: function(props) {
                return [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: props.row.batchList,
                        size: "mini",
                        "highlight-current-row": "",
                        rowClassName: _vm.subRowClassName
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "#",
                          type: "index",
                          width: "56",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "批号",
                          prop: "batchNumber",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-input", {
                                    attrs: { clearable: "", size: "mini" },
                                    model: {
                                      value: row.batchNumber,
                                      callback: function($$v) {
                                        _vm.$set(row, "batchNumber", $$v)
                                      },
                                      expression: "row.batchNumber"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "序列号",
                          prop: "trackingCode",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-input", {
                                    attrs: { clearable: "", size: "mini" },
                                    model: {
                                      value: row.trackingCode,
                                      callback: function($$v) {
                                        _vm.$set(row, "trackingCode", $$v)
                                      },
                                      expression: "row.trackingCode"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "数量",
                          prop: "inWarehouseQuantity",
                          width: "100",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      controls: false,
                                      min: 0,
                                      size: "mini"
                                    },
                                    model: {
                                      value: row.inWarehouseQuantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row,
                                          "inWarehouseQuantity",
                                          $$v
                                        )
                                      },
                                      expression: "row.inWarehouseQuantity"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产日期",
                          prop: "manufacturerDate",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "生产日期"
                                    },
                                    model: {
                                      value: row.manufacturerDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "manufacturerDate", $$v)
                                      },
                                      expression: "row.manufacturerDate"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "有效期",
                          prop: "validityDate",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "有效期"
                                    },
                                    model: {
                                      value: row.validityDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "validityDate", $$v)
                                      },
                                      expression: "row.validityDate"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "灭菌批号",
                          prop: "sterilizationBatchNumber",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-input", {
                                    attrs: { clearable: "", size: "mini" },
                                    model: {
                                      value: row.sterilizationBatchNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row,
                                          "sterilizationBatchNumber",
                                          $$v
                                        )
                                      },
                                      expression: "row.sterilizationBatchNumber"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "灭菌效期",
                          prop: "sterilizationDate",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "灭菌效期"
                                    },
                                    model: {
                                      value: row.sterilizationDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "sterilizationDate", $$v)
                                      },
                                      expression: "row.sterilizationDate"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "货架", width: "150", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "date-input",
                                      attrs: { size: "mini", readonly: true },
                                      model: {
                                        value: row.shelfName,
                                        callback: function($$v) {
                                          _vm.$set(row, "shelfName", $$v)
                                        },
                                        expression: "row.shelfName"
                                      }
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-caret-bottom"
                                        },
                                        on: {
                                          click: function(e) {
                                            return _vm.showShelf(e, row)
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "货位", width: "150", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "date-input",
                                      attrs: { size: "mini", readonly: true },
                                      model: {
                                        value: row.locationNumber,
                                        callback: function($$v) {
                                          _vm.$set(row, "locationNumber", $$v)
                                        },
                                        expression: "row.locationNumber"
                                      }
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-caret-bottom"
                                        },
                                        on: {
                                          click: function(e) {
                                            return _vm.showLocation(e, row)
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "备注",
                          prop: "remark",
                          width: "150",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-input", {
                                    attrs: { clearable: "", size: "mini" },
                                    model: {
                                      value: row.remark,
                                      callback: function($$v) {
                                        _vm.$set(row, "remark", $$v)
                                      },
                                      expression: "row.remark"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "50", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.batchRemove(props, row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "certId",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", size: "mini" },
                      model: {
                        value: row.certId,
                        callback: function($$v) {
                          _vm.$set(row, "certId", $$v)
                        },
                        expression: "row.certId"
                      }
                    },
                    _vm._l(row.certList, function(item) {
                      return _c("el-option", {
                        key: "certId" + item.id,
                        attrs: { label: item.cert.regNoCn, value: item.certId }
                      })
                    }),
                    1
                  )
                ]
              }
            },
            {
              key: "manuLicenseCodeSlot",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        clearable: "",
                        placeholder: "请选择生产许可证号"
                      },
                      model: {
                        value: row.manuLicenseCode,
                        callback: function($$v) {
                          _vm.$set(row, "manuLicenseCode", $$v)
                        },
                        expression: "row.manuLicenseCode"
                      }
                    },
                    _vm._l(
                      row.brandId && _vm.manuLicenseMap[row.brandId]
                        ? _vm.manuLicenseMap[row.brandId]
                        : [],
                      function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.license_code,
                            attrs: {
                              label: item.license_code,
                              value: item.license_code
                            }
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.license_code))
                            ])
                          ]
                        )
                      }
                    ),
                    1
                  )
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.batchView(row)
                            }
                          }
                        },
                        [_vm._v("查看批次")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.batchAdd(row)
                            }
                          }
                        },
                        [_vm._v("添加批次")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "el-popover",
          {
            ref: "shelfNumberPopover",
            attrs: { placement: "right-start", width: "250", trigger: "click" },
            model: {
              value: _vm.shelfNumberVisible,
              callback: function($$v) {
                _vm.shelfNumberVisible = $$v
              },
              expression: "shelfNumberVisible"
            }
          },
          [
            _c("ShelfComponents", {
              ref: "ShelfComponents",
              on: { select: _vm.shelfSelect }
            })
          ],
          1
        ),
        _c(
          "el-popover",
          {
            ref: "locationNumberPopover",
            attrs: { placement: "right-start", width: "300", trigger: "click" },
            model: {
              value: _vm.locationNumberVisible,
              callback: function($$v) {
                _vm.locationNumberVisible = $$v
              },
              expression: "locationNumberVisible"
            }
          },
          [
            _c("LocationComponents", {
              ref: "LocationComponents",
              on: { select: _vm.locationSelect }
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary" },
                        on: { click: _vm.formSubmit }
                      },
                      [_vm._v("提交")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }