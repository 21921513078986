//
//
//
//
//
//
import util from '@/libs/util';
import axios from 'axios';
import { MessageBox } from 'element-ui';
export default {
  name: 'app',
  watch: {
    $route: function $route(to) {
      // 处理发包缓存问题
      if (to) {
        if (to.fullPath.lastIndexOf('/') > 0) {
          this.contractCache();
        }
      }
    },
    '$i18n.locale': 'i18nHandle'
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale);
  },
  methods: {
    contractCache: function contractCache() {
      axios.get('/version.json', {
        params: {
          _t: new Date().getTime()
        }
      }).then(function (res) {
        var VUE_APP_VERSION = res.data.version;
        var VUE_APP_VERSION_CACHE = window.localStorage.getItem('vueVersion');

        if (VUE_APP_VERSION !== VUE_APP_VERSION_CACHE) {
          MessageBox.confirm('检测到版本更新，需要刷新页面。<br>⚠️<span style="color:#f56c6c">注意：如果有未保存的表单数据，请保存或提交之后再刷新页面更新。</span>', '系统提示', {
            confirmButtonText: '刷新',
            cancelButtonText: '不刷新',
            dangerouslyUseHTMLString: true
          }).then(function () {
            window.localStorage.setItem('vueVersion', VUE_APP_VERSION);
            window.location.reload();
          });
        }
      }).catch(function (e) {});
    },
    i18nHandle: function i18nHandle(val, oldVal) {
      util.cookies.set('lang', val);
      document.querySelector('html').setAttribute('lang', val);
    }
  }
};