var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售报价")]),
              _c("el-breadcrumb-item", [_vm._v("销售出库销售单")]),
              _c("el-breadcrumb-item", [_vm._v("销售出库销售单详情")])
            ],
            1
          ),
          _c("div", { staticStyle: { "font-size": "14px" } }, [
            _vm._v("销售单号：" + _vm._s(_vm.orderInfo.salesOrderNo))
          ])
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading"
              }
            ],
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "label-suffix": ":",
              "show-message": false,
              disabled: _vm.orderInfo.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", required: true } },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.orderInfo.warehouse.name,
                            callback: function($$v) {
                              _vm.$set(_vm.orderInfo.warehouse, "name", $$v)
                            },
                            expression: "orderInfo.warehouse.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务部门", prop: "departmentName" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c(
                              "el-select",
                              {
                                attrs: { clearable: "", placeholder: "请选择" },
                                model: {
                                  value: _vm.form.departmentName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "departmentName", $$v)
                                  },
                                  expression: "form.departmentName"
                                }
                              },
                              _vm._l(_vm.departmentList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: {
                                    label: item.departmentName,
                                    value: item.departmentName
                                  }
                                })
                              }),
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.form.departmentName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.orderInfo.stockout.customerType !== 2,
                        expression: "orderInfo.stockout.customerType!==2"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "经销商", required: true } },
                      [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.distributorName))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.orderInfo.stockout.customerType === 2,
                        expression: "orderInfo.stockout.customerType===2"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "医院", required: true } },
                      [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.stockout.hospital2.name))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择归属公司",
                                  clearable: "",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.form.subsidiaryId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "subsidiaryId", $$v)
                                  },
                                  expression: "form.subsidiaryId"
                                }
                              },
                              _vm._l(_vm.subsidiaryList, function(item) {
                                return _c("el-option", {
                                  key: "subsidiaryId" + item.ID,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.ID,
                                    disabled: item.isValid === 0
                                  }
                                })
                              }),
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(
                                _vm._s(_vm.orderInfo.subsidiary.companyName)
                              )
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "配送方式", prop: "shippingMethodName" }
                      },
                      [
                        _vm.orderInfo.status === 1
                          ? _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.form.shippingMethodName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "shippingMethodName",
                                      $$v
                                    )
                                  },
                                  expression: "form.shippingMethodName"
                                }
                              },
                              _vm._l(_vm.shippingMethodList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.name }
                                })
                              }),
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.shippingMethodName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "快递单号", prop: "courierNumber" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c("el-input", {
                              attrs: {
                                placeholder: "请输入快递单号",
                                "suffix-icon": "el-icon-edit"
                              },
                              model: {
                                value: _vm.form.courierNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "courierNumber", $$v)
                                },
                                expression: "form.courierNumber"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.courierNumber))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "运输费", prop: "freight" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    placeholder: "请输入运输费",
                                    controls: false,
                                    precision: 2,
                                    min: 0
                                  },
                                  model: {
                                    value: _vm.form.freight,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "freight", $$v)
                                    },
                                    expression: "form.freight"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      right: "10px",
                                      top: "0px",
                                      color: "#C0C4CC",
                                      "font-size": "12px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon el-icon-edit"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.form.freight.toFixed(2)))
                            ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "收货地址" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.stockout.addressManu))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "收货电话" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.stockout.mobileManu))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "销售总金额", prop: "totalSalesAmount" }
                      },
                      [
                        _vm.orderInfo.status === 1
                          ? _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    placeholder: "销售总金额",
                                    controls: false,
                                    precision: 2,
                                    min: 0
                                  },
                                  model: {
                                    value: _vm.form.totalSalesAmount,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "totalSalesAmount",
                                        $$v
                                      )
                                    },
                                    expression: "form.totalSalesAmount"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      right: "10px",
                                      top: "0px",
                                      color: "#C0C4CC",
                                      "font-size": "12px"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon el-icon-edit"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(
                                _vm._s(_vm.form.totalSalesAmount.toFixed(2))
                              )
                            ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "医院", prop: "hospitalId" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.form.hospitalId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "hospitalId", $$v)
                                  },
                                  expression: "form.hospitalId"
                                }
                              },
                              _vm._l(_vm.hospitalList, function(item, index) {
                                return _c("el-option", {
                                  key: "hospitalId" + index,
                                  attrs: {
                                    label: item.name,
                                    value: item.ID,
                                    disabled: !item.status === 0
                                  }
                                })
                              }),
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.hospitalName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "医生", prop: "surgeon" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c("el-input", {
                              attrs: {
                                placeholder: "医生",
                                maxlength: "250",
                                "suffix-icon": "el-icon-edit",
                                clearable: ""
                              },
                              model: {
                                value: _vm.form.surgeon,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "surgeon", $$v)
                                },
                                expression: "form.surgeon"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.surgeon))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "病人姓名", prop: "patientName" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c("el-input", {
                              attrs: {
                                placeholder: "病人姓名",
                                maxlength: "250",
                                "suffix-icon": "el-icon-edit",
                                clearable: ""
                              },
                              model: {
                                value: _vm.form.patientName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "patientName", $$v)
                                },
                                expression: "form.patientName"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.patientName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "住院号", prop: "inpatientNo" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c("el-input", {
                              attrs: {
                                placeholder: "住院号",
                                maxlength: "250",
                                "suffix-icon": "el-icon-edit",
                                clearable: ""
                              },
                              model: {
                                value: _vm.form.inpatientNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "inpatientNo", $$v)
                                },
                                expression: "form.inpatientNo"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.inpatientNo))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手术日期", prop: "operationTime" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd"
                              },
                              model: {
                                value: _vm.form.operationTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "operationTime", $$v)
                                },
                                expression: "form.operationTime"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.operationTime))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll"
                      }
                    ],
                    attrs: { sm: 12, lg: 8, xl: 6 }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手术名称", prop: "surgicalName" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c("el-input", {
                              attrs: {
                                placeholder: "手术名称",
                                maxlength: "250",
                                "suffix-icon": "el-icon-edit",
                                clearable: ""
                              },
                              model: {
                                value: _vm.form.surgicalName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "surgicalName", $$v)
                                },
                                expression: "form.surgicalName"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.surgicalName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    attrs: {
                      sm: 24,
                      lg: _vm.showAll ? 16 : 16,
                      xl: _vm.showAll ? 24 : 12
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _vm.orderInfo.status === 1
                          ? _c("el-input", {
                              attrs: {
                                "suffix-icon": "el-icon-edit",
                                clearable: ""
                              },
                              model: {
                                value: _vm.form.remarks,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "remarks", $$v)
                                },
                                expression: "form.remarks"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.remarks))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "form-more" }, [
              _c("span", { on: { click: _vm.showAllClick } }, [
                _vm._v(" " + _vm._s(_vm.showAll ? "合并" : "展开") + " "),
                _c("i", {
                  class: _vm.showAll
                    ? "el-icon-arrow-up el-icon--right"
                    : "el-icon-arrow-down el-icon--right"
                })
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "font-size": "14px"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
              [
                _c(
                  "div",
                  {
                    class:
                      _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabChange(1)
                      }
                    }
                  },
                  [_vm._v("销售详情")]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.activeTabId === 2 ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabChange(2)
                      }
                    }
                  },
                  [_vm._v("退货详情")]
                )
              ]
            ),
            _vm.activeTabId === 1
              ? _c("span", { staticStyle: { "margin-right": "10px" } }, [
                  _c("span", [
                    _vm._v("销售总数："),
                    _c("span", { staticStyle: { color: "#f00" } }, [
                      _vm._v(_vm._s(_vm.totalQuantity()))
                    ])
                  ]),
                  _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("销售金额："),
                    _c("span", { staticStyle: { color: "#f00" } }, [
                      _vm._v(_vm._s(_vm.totalAmount()))
                    ])
                  ]),
                  _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v(" 终端销售额："),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.terSalesAmount()))
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.activeTabId === 2
              ? _c("span", [
                  _c("span", [
                    _vm._v("退货总数："),
                    _c("span", { staticStyle: { color: "#f00" } }, [
                      _vm._v(_vm._s(_vm.totalQuantity()))
                    ])
                  ]),
                  _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("退货金额："),
                    _c("span", { staticStyle: { color: "#f00" } }, [
                      _vm._v(_vm._s(_vm.totalAmount()))
                    ])
                  ])
                ])
              : _vm._e()
          ]
        ),
        _vm.activeTabId === 1
          ? _c(
              "div",
              [
                _c(
                  "YTable",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.detailLoading,
                        expression: "detailLoading"
                      }
                    ],
                    key: "detail-table" + _vm.timestamp,
                    ref: "detailListTable",
                    attrs: {
                      hasIndex: true,
                      hasPagination: true,
                      pagination: _vm.detailForm,
                      "route-name": _vm.$route.name + "detail-table",
                      columns: _vm.detailColumns,
                      rowClassName: _vm.rowClassName,
                      data: _vm.detailList.slice(
                        (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
                        _vm.detailForm.page * _vm.detailForm.pageSize
                      )
                    },
                    on: {
                      "row-click": _vm.detailRowClick,
                      pageChange: _vm.detailFormPageChange,
                      sizeChange: _vm.detailFormSizeChange
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "isConsign",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.isConsign === 1
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "success",
                                        effect: "dark",
                                        size: "mini"
                                      }
                                    },
                                    [_vm._v("是")]
                                  )
                                : _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "info",
                                        effect: "dark",
                                        size: "mini"
                                      }
                                    },
                                    [_vm._v("否")]
                                  )
                            ]
                          }
                        },
                        {
                          key: "cenCollection",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c(
                                    "el-select",
                                    {
                                      attrs: { size: "mini", clearable: "" },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      model: {
                                        value: row.cenCollection,
                                        callback: function($$v) {
                                          _vm.$set(row, "cenCollection", $$v)
                                        },
                                        expression: "row.cenCollection"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: 2, label: "集采" }
                                      }),
                                      _c("el-option", {
                                        attrs: { value: 3, label: "非集采" }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      row.cenCollection === 2
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "mini",
                                                effect: "dark",
                                                type: "success"
                                              }
                                            },
                                            [_vm._v("集采")]
                                          )
                                        : row.cenCollection === 3
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "mini",
                                                effect: "dark",
                                                type: "info"
                                              }
                                            },
                                            [_vm._v("非集采")]
                                          )
                                        : _c("span", [_vm._v("-")])
                                    ],
                                    1
                                  )
                            ]
                          }
                        },
                        {
                          key: "unitSellingPrice",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      min: 0,
                                      precision: 2,
                                      controls: false
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.unitSellingPrice,
                                      callback: function($$v) {
                                        _vm.$set(row, "unitSellingPrice", $$v)
                                      },
                                      expression: "row.unitSellingPrice"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(_vm._s(row.unitSellingPrice))
                                  ])
                            ]
                          }
                        },
                        {
                          key: "discountRate",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c(
                                    "div",
                                    { staticStyle: { position: "relative" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: 0,
                                          precision: 2,
                                          size: "mini",
                                          controls: false
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.detailSaveOne(row)
                                          }
                                        },
                                        model: {
                                          value: row.discountRate,
                                          callback: function($$v) {
                                            _vm.$set(row, "discountRate", $$v)
                                          },
                                          expression: "row.discountRate"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px"
                                          }
                                        },
                                        [_vm._v("%")]
                                      )
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(row.discountRate.toFixed(2)) + "%"
                                    )
                                  ])
                            ]
                          }
                        },
                        {
                          key: "terUnitPrice",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      min: 0,
                                      precision: 2,
                                      size: "mini",
                                      controls: false
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.terUnitPrice,
                                      callback: function($$v) {
                                        _vm.$set(row, "terUnitPrice", $$v)
                                      },
                                      expression: "row.terUnitPrice"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(_vm._s(row.terUnitPrice.toFixed(2)))
                                  ])
                            ]
                          }
                        },
                        {
                          key: "remarks",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c("el-input", {
                                    attrs: { size: "mini" },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.remarks,
                                      callback: function($$v) {
                                        _vm.$set(row, "remarks", $$v)
                                      },
                                      expression: "row.remarks"
                                    }
                                  })
                                : _c("span", [_vm._v(_vm._s(row.remarks))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3884480883
                    )
                  },
                  [
                    _c("template", { slot: "discountRateHeader" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "5px" } },
                            [_vm._v("折扣（%）")]
                          ),
                          _vm.orderInfo.status === 1
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editRate()
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          : _vm._e(),
        _vm.activeTabId === 2
          ? _c(
              "div",
              [
                _c("YTable", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.returnLoading,
                      expression: "returnLoading"
                    }
                  ],
                  key: "returnTable" + _vm.timestamp,
                  ref: "returnListTable",
                  attrs: {
                    hasIndex: true,
                    hasPagination: true,
                    pagination: _vm.returnForm,
                    "route-name": _vm.$route.name + "return-table",
                    columns: _vm.returnColumns,
                    rowClassName: _vm.rowClassName,
                    data: _vm.returnList.slice(
                      (_vm.returnForm.page - 1) * _vm.returnForm.pageSize,
                      _vm.returnForm.page * _vm.returnForm.pageSize
                    )
                  },
                  on: {
                    pageChange: _vm.returnFormPageChange,
                    sizeChange: _vm.returnFormSizeChange
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "出库人：" +
                      _vm._s(_vm.orderInfo.stockout.nameWarehousePersonnel)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "出库时间：" + _vm._s(_vm.orderInfo.stockout.deliveryTime)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "出库复核人：" + _vm._s(_vm.orderInfo.stockout.nameReviewer)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "出库复核时间：" + _vm._s(_vm.orderInfo.stockout.auditTime)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "回库人：" +
                      _vm._s(_vm.orderInfo.stockout.returningPersonnel)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "回库时间：" + _vm._s(_vm.orderInfo.stockout.returnDate)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("报价人：" + _vm._s(_vm.orderInfo.quotationPersonnel))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("报价时间：" + _vm._s(_vm.orderInfo.quotationDate))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.orderInfo.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formStorage)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v(" 暂存")
                          ]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.offerSubmit)
                              }
                            }
                          },
                          [_vm._v("报价")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.orderInfo.ID },
                        on: { click: _vm.orderPrint }
                      },
                      [
                        _c("i", { staticClass: "fa fa-print" }),
                        _vm._v(" 打印 ")
                      ]
                    ),
                    _vm.orderInfo.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelOrder }
                          },
                          [_vm._v("驳回")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelPrice }
                          },
                          [_vm._v("撤销报价")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("DetailAddComponents", {
        ref: "DetailAddComponents",
        on: { confirm: _vm.detailAddConfirm }
      }),
      _c("DetailAdjustComponents", {
        ref: "DetailAdjustComponents",
        on: { confirm: _vm.detailAdjustConfirm }
      }),
      _c("RateComponent", {
        ref: "RateComponentRef",
        on: { update: _vm.updateRate }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }