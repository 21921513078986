var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px 0",
            "text-align": "right",
            position: "absolute",
            right: "20px",
            top: "30px"
          }
        },
        [
          _c(
            "el-popover",
            {
              ref: "optionsPopover",
              attrs: { placement: "bottom", trigger: "click" }
            },
            [
              _c("TableHeadComponent", {
                ref: "TableHeadComponentRef",
                attrs: {
                  "cache-name": _vm.cacheName,
                  "remote-columns-list": _vm.remoteColumnsList
                },
                on: { "save-table-set": _vm.saveTableSet }
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { slot: "reference", type: "primary", size: "mini" },
                  slot: "reference"
                },
                [_vm._v("字段自定义")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.printClick }
            },
            [_vm._v("点击打印")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-download"
              },
              on: { click: _vm.exportExcel }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _vm.loading === false
            ? _c(
                "div",
                {
                  ref: "printContainerDirect",
                  staticStyle: { width: "100%" },
                  attrs: { id: "printContainerDirect" }
                },
                [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("h3", [_vm._v(_vm._s(_vm.corporateName))]),
                    _c("h3", [_vm._v("直销销售单报价")]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          right: "0",
                          top: "10px"
                        }
                      },
                      [_c("img", { attrs: { id: "barcode" } })]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "font-size": "12px",
                        "margin-top": "20px",
                        color: "#000"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("仓库：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.warehouse.name))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("医院：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.hospitalName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("产品线：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.productLineName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("销售人员：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.nameSalesperson))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("维护人员：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(
                              _vm._s(_vm.orderInfo.nameMaintenancePersonnel)
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("跟台人员：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.nameAttendant))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("手术类型：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.surgeryListFormatter(
                                  _vm.orderInfo.nameOperationType
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("手术医生：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.surgeon))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("手术日期：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.operationTime))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("住院号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.inpatientNo))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("床位号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.bedNo))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("病人姓名：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.patientName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("病人性别：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.patientGender))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("病人年龄：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.patientAge))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("使用科室：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.usingDepartment))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("配送方式：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.shippingMethodName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("收货电话：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.stockout.mobileManu))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("收货地址：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.stockout.addressManu))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("备注：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.remarks))
                          ])
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "detail-table" }, [
                    _c("table", [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _vm._l(_vm.remoteColumnsList, function(
                              item,
                              index
                            ) {
                              return [
                                item.checked === true
                                  ? _c("th", { key: "field-" + index }, [
                                      _vm._v(_vm._s(item.label))
                                    ])
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.detailList, function(item, index) {
                            return _c(
                              "tr",
                              { key: "data-col-" + index },
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true
                                      ? _c(
                                          "td",
                                          { key: "data-item-" + index2 },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataFormatter(
                                                  item[item2.prop],
                                                  item2.type
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          }),
                          _vm._l(_vm.getSummary(), function(item, index) {
                            return _c(
                              "tr",
                              { key: index },
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true
                                      ? _c(
                                          "td",
                                          {
                                            key: "summary-" + index2,
                                            staticClass: "summary"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataFormatter(
                                                  item[item2.prop],
                                                  item2.type
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                        "flex-wrap": "wrap",
                        "font-size": "12px",
                        color: "#000"
                      }
                    },
                    [
                      _c("div", { staticStyle: { width: "50%" } }, [
                        _vm._v(
                          " 出库人/时间： " +
                            _vm._s(
                              _vm.orderInfo.stockout.nameWarehousePersonnel
                            ) +
                            " / " +
                            _vm._s(_vm.orderInfo.stockout.deliveryTime) +
                            " "
                        )
                      ]),
                      _c("div", { staticStyle: { width: "50%" } }, [
                        _vm._v(
                          " 回库人/时间： " +
                            _vm._s(_vm.orderInfo.stockout.returningPersonnel) +
                            " / " +
                            _vm._s(_vm.orderInfo.stockout.returnDate) +
                            " "
                        )
                      ]),
                      _c("div", { staticStyle: { width: "50%" } }, [
                        _vm._v(
                          " 复核人/时间： " +
                            _vm._s(_vm.orderInfo.stockout.nameReviewer) +
                            " / " +
                            _vm._s(_vm.orderInfo.stockout.auditTime) +
                            " "
                        )
                      ]),
                      _c("div", { staticStyle: { width: "50%" } })
                    ]
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }