var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售报价")]),
              _c("el-breadcrumb-item", [_vm._v("直销销售单")]),
              _c("el-breadcrumb-item", [_vm._v("直销销售单详情")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading"
              }
            ],
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "label-suffix": ":",
              "show-message": false,
              disabled: _vm.orderInfo.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", required: true } },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.orderInfo.warehouse.name,
                            callback: function($$v) {
                              _vm.$set(_vm.orderInfo.warehouse, "name", $$v)
                            },
                            expression: "orderInfo.warehouse.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "医院", required: true } },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.orderInfo.hospitalName,
                            callback: function($$v) {
                              _vm.$set(_vm.orderInfo, "hospitalName", $$v)
                            },
                            expression: "orderInfo.hospitalName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售单号", required: true } },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.orderInfo.salesOrderNo,
                            callback: function($$v) {
                              _vm.$set(_vm.orderInfo, "salesOrderNo", $$v)
                            },
                            expression: "orderInfo.salesOrderNo"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售部门", prop: "departmentName" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.form.departmentName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "departmentName", $$v)
                              },
                              expression: "form.departmentName"
                            }
                          },
                          _vm._l(_vm.departmentList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.departmentName,
                                value: item.departmentName
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售人员", required: true } },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.orderInfo.nameSalesperson,
                            callback: function($$v) {
                              _vm.$set(_vm.orderInfo, "nameSalesperson", $$v)
                            },
                            expression: "orderInfo.nameSalesperson"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "维护人员" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.orderInfo.nameMaintenancePersonnel,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.orderInfo,
                                "nameMaintenancePersonnel",
                                $$v
                              )
                            },
                            expression: "orderInfo.nameMaintenancePersonnel"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择归属公司",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.form.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "subsidiaryId", $$v)
                              },
                              expression: "form.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: {
                                label: item.companyName,
                                value: item.ID,
                                disabled: item.isValid === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "病人姓名", prop: "patientName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "病人姓名",
                            "suffix-icon": "el-icon-edit"
                          },
                          model: {
                            value: _vm.form.patientName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "patientName", $$v)
                            },
                            expression: "form.patientName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手术医生", prop: "surgeon" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入手术医生",
                            "suffix-icon": "el-icon-edit"
                          },
                          model: {
                            value: _vm.form.surgeon,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "surgeon", $$v)
                            },
                            expression: "form.surgeon"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "跟台人员", prop: "nameAttendant" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入跟台人员",
                            "suffix-icon": "el-icon-edit"
                          },
                          model: {
                            value: _vm.form.nameAttendant,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "nameAttendant", $$v)
                            },
                            expression: "form.nameAttendant"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手术时间", prop: "operationTime" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "160px" },
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "请选择手术时间"
                          },
                          model: {
                            value: _vm.form.operationTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "operationTime", $$v)
                            },
                            expression: "form.operationTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "手术类型", prop: "nameOperationType" }
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.surgeryListFormatter(
                                _vm.form.nameOperationType
                              ),
                              placement: "top"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "15px",
                                  "margin-right": "10px",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis",
                                  overflow: "hidden",
                                  width: "max-content",
                                  "max-width": "100%"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.surgeryListFormatter(
                                      _vm.form.nameOperationType
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.showAll === true
                  ? [
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "住院号", prop: "inpatientNo" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "住院号",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.inpatientNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "inpatientNo", $$v)
                                  },
                                  expression: "form.inpatientNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "使用科室",
                                prop: "usingDepartment"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "使用科室",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.usingDepartment,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "usingDepartment", $$v)
                                  },
                                  expression: "form.usingDepartment"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "跟台费用", prop: "followUpFee" }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  placeholder: "跟台费用",
                                  "controls-position": "right",
                                  precision: 2,
                                  min: 0
                                },
                                model: {
                                  value: _vm.form.followUpFee,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "followUpFee", $$v)
                                  },
                                  expression: "form.followUpFee"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货电话" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入收货电话",
                                  disabled: true
                                },
                                model: {
                                  value: _vm.orderInfo.stockout.mobileManu,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.orderInfo.stockout,
                                      "mobileManu",
                                      $$v
                                    )
                                  },
                                  expression: "orderInfo.stockout.mobileManu"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货地址" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入收货地址",
                                  disabled: true
                                },
                                model: {
                                  value:
                                    _vm.orderInfo.stockout.contactPersonManu,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.orderInfo.stockout,
                                      "contactPersonManu",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "orderInfo.stockout.contactPersonManu"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "配送方式",
                                prop: "shippingMethodName"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.form.shippingMethodName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "shippingMethodName",
                                        $$v
                                      )
                                    },
                                    expression: "form.shippingMethodName"
                                  }
                                },
                                _vm._l(_vm.shippingMethodList, function(item) {
                                  return _c("el-option", {
                                    key: item.ID,
                                    attrs: {
                                      label: item.name,
                                      value: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "快递单号",
                                prop: "courierNumber"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入快递单号",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.courierNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "courierNumber", $$v)
                                  },
                                  expression: "form.courierNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "运输费", prop: "freight" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  placeholder: "请输入运输费",
                                  "controls-position": "right",
                                  precision: 2,
                                  min: 0
                                },
                                model: {
                                  value: _vm.form.freight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "freight", $$v)
                                  },
                                  expression: "form.freight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "床位号", prop: "bedNo" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "床位号",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.bedNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "bedNo", $$v)
                                  },
                                  expression: "form.bedNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "病人性别",
                                prop: "patientGender"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.form.patientGender,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "patientGender", $$v)
                                    },
                                    expression: "form.patientGender"
                                  }
                                },
                                _vm._l(_vm.patientGenderList, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "病人年龄", prop: "patientAge" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "病人年龄",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.patientAge,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "patientAge", $$v)
                                  },
                                  expression: "form.patientAge"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "联系人手机", prop: "cellPhone" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "联系人手机",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.cellPhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "cellPhone", $$v)
                                  },
                                  expression: "form.cellPhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "家庭地址", prop: "homeAddress" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "家庭地址",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.homeAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "homeAddress", $$v)
                                  },
                                  expression: "form.homeAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否传染",
                                prop: "infectiousNot"
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.infectiousNot,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "infectiousNot", $$v)
                                    },
                                    expression: "form.infectiousNot"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是")
                                  ]),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("否")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "巡回护士",
                                prop: "itinerantNurse"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "巡回护士",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.itinerantNurse,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "itinerantNurse", $$v)
                                  },
                                  expression: "form.itinerantNurse"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "加班费", prop: "overtimePay" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  placeholder: "请输入加班费",
                                  "controls-position": "right",
                                  precision: 2,
                                  min: 0
                                },
                                model: {
                                  value: _vm.form.overtimePay,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "overtimePay", $$v)
                                  },
                                  expression: "form.overtimePay"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "其他费用",
                                prop: "otherExpenses"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  placeholder: "请输入其他费用",
                                  "controls-position": "right",
                                  precision: 2,
                                  min: 0
                                },
                                model: {
                                  value: _vm.form.otherExpenses,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "otherExpenses", $$v)
                                  },
                                  expression: "form.otherExpenses"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "台数", prop: "numberUnit" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  placeholder: "台数",
                                  "controls-position": "right",
                                  min: 0
                                },
                                model: {
                                  value: _vm.form.numberUnit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "numberUnit", $$v)
                                  },
                                  expression: "form.numberUnit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { sm: 24, lg: 24, xl: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入备注",
                                  "suffix-icon": "el-icon-edit"
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c("div", { staticClass: "form-more" }, [
              _c("span", { on: { click: _vm.showAllClick } }, [
                _vm._v(" " + _vm._s(_vm.showAll ? "合并" : "展开") + " "),
                _c("i", {
                  class: _vm.showAll
                    ? "el-icon-arrow-up el-icon--right"
                    : "el-icon-arrow-down el-icon--right"
                })
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
              [
                _vm.returnList.length > 0
                  ? _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(1)
                          }
                        }
                      },
                      [_vm._v("销售详情")]
                    )
                  : _vm._e(),
                _vm.returnList.length > 0
                  ? _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 2 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(2)
                          }
                        }
                      },
                      [_vm._v("退货详情")]
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticStyle: { "font-size": "14px", "line-height": "30px" } },
              [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.activeTabId === 1 ? "合计：" : "退货数量：")
                  ),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.totalQuantity()))
                  ])
                ]),
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.activeTabId === 1 ? "终端销售额：" : "退货金额："
                      )
                  ),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.terSalesAmount()))
                  ])
                ])
              ]
            )
          ]
        ),
        _vm.activeTabId === 1
          ? _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "detailFormRef",
                    attrs: { model: _vm.detailForm, rules: _vm.detailFormRules }
                  },
                  [
                    _c(
                      "YTable",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.detailLoading,
                            expression: "detailLoading"
                          }
                        ],
                        ref: "dragTableRef",
                        attrs: {
                          hasIndex: true,
                          hasPagination: true,
                          pagination: _vm.detailForm,
                          "route-name": _vm.$route.name + "detail-table",
                          columns: _vm.columns,
                          rowClassName: _vm.rowClassName,
                          data: _vm.detailForm.data.slice(
                            (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
                            _vm.detailForm.page * _vm.detailForm.pageSize
                          )
                        },
                        on: {
                          "row-click": _vm.detailRowClick,
                          pageChange: _vm.detailFormPageChange,
                          sizeChange: _vm.detailFormSizeChange
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "isConsign",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.isConsign === 1
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "success",
                                            effect: "dark",
                                            size: "mini"
                                          }
                                        },
                                        [_vm._v("是")]
                                      )
                                    : _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "info",
                                            effect: "dark",
                                            size: "mini"
                                          }
                                        },
                                        [_vm._v("否")]
                                      )
                                ]
                              }
                            },
                            {
                              key: "cenCollection",
                              fn: function(ref) {
                                var row = ref.row
                                var index = ref.index
                                return [
                                  _vm.orderInfo.status === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "form-item-cenCollection" + index,
                                          attrs: {
                                            prop:
                                              "data." +
                                              index +
                                              ".cenCollection",
                                            rules:
                                              _vm.detailFormRules.cenCollection
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                size: "mini",
                                                clearable: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.detailSaveOne(row)
                                                }
                                              },
                                              model: {
                                                value: row.cenCollection,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "cenCollection",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.cenCollection"
                                              }
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: 2,
                                                  label: "集采"
                                                }
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  value: 3,
                                                  label: "非集采"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          row.cenCollection === 2
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    effect: "dark",
                                                    type: "success"
                                                  }
                                                },
                                                [_vm._v("集采")]
                                              )
                                            : row.cenCollection === 3
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    effect: "dark",
                                                    type: "info"
                                                  }
                                                },
                                                [_vm._v("非集采")]
                                              )
                                            : _c("span", [_vm._v("-")])
                                        ],
                                        1
                                      )
                                ]
                              }
                            },
                            {
                              key: "unitSellingPrice",
                              fn: function(ref) {
                                var row = ref.row
                                var index = ref.index
                                return [
                                  _vm.orderInfo.status === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "form-item-unitSellingPrice" +
                                            index,
                                          attrs: {
                                            prop:
                                              "data." +
                                              index +
                                              ".unitSellingPrice",
                                            rules:
                                              _vm.detailFormRules
                                                .unitSellingPrice
                                          }
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              size: "mini",
                                              min: 0,
                                              precision: 2,
                                              controls: false
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            model: {
                                              value: row.unitSellingPrice,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "unitSellingPrice",
                                                  $$v
                                                )
                                              },
                                              expression: "row.unitSellingPrice"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.unitSellingPrice))
                                      ])
                                ]
                              }
                            },
                            {
                              key: "discountRate",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm.orderInfo.status === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { position: "relative" }
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              min: 0,
                                              precision: 2,
                                              size: "mini",
                                              controls: false
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            model: {
                                              value: row.discountRate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "discountRate",
                                                  $$v
                                                )
                                              },
                                              expression: "row.discountRate"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                top: "5px",
                                                right: "5px"
                                              }
                                            },
                                            [_vm._v("%")]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(row.discountRate.toFixed(2)) +
                                            "%"
                                        )
                                      ])
                                ]
                              }
                            },
                            {
                              key: "terUnitPrice",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm.orderInfo.status === 1
                                    ? _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: 0,
                                          precision: 2,
                                          size: "mini",
                                          controls: false
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.detailSaveOne(row)
                                          }
                                        },
                                        model: {
                                          value: row.terUnitPrice,
                                          callback: function($$v) {
                                            _vm.$set(row, "terUnitPrice", $$v)
                                          },
                                          expression: "row.terUnitPrice"
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(row.terUnitPrice.toFixed(2))
                                        )
                                      ])
                                ]
                              }
                            },
                            {
                              key: "remarks",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm.orderInfo.status === 1
                                    ? _c("el-input", {
                                        attrs: { size: "mini" },
                                        on: {
                                          change: function($event) {
                                            return _vm.detailSaveOne(row)
                                          }
                                        },
                                        model: {
                                          value: row.remarks,
                                          callback: function($$v) {
                                            _vm.$set(row, "remarks", $$v)
                                          },
                                          expression: "row.remarks"
                                        }
                                      })
                                    : _c("span", [_vm._v(_vm._s(row.remarks))])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2786758480
                        )
                      },
                      [
                        _c("template", { slot: "discountRateHeader" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "5px" } },
                                [_vm._v("折扣（%）")]
                              ),
                              _vm.orderInfo.status === 1
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editRate()
                                        }
                                      }
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.activeTabId === 2
          ? _c(
              "div",
              [
                _c("YTable", {
                  ref: "returnListTableRef",
                  attrs: {
                    hasIndex: true,
                    hasPagination: true,
                    pagination: _vm.returnForm,
                    "route-name": _vm.$route.name + "return-table",
                    columns: _vm.returnColumns,
                    data: _vm.returnList.slice(
                      (_vm.returnForm.page - 1) * _vm.returnForm.pageSize,
                      _vm.returnForm.page * _vm.returnForm.pageSize
                    )
                  },
                  on: {
                    pageChange: _vm.returnPageChange,
                    sizeChange: _vm.returnSizeChange
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "isConsign",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            row.isConsign === 1
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      type: "success",
                                      effect: "dark",
                                      size: "mini"
                                    }
                                  },
                                  [_vm._v("是")]
                                )
                              : _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      type: "info",
                                      effect: "dark",
                                      size: "mini"
                                    }
                                  },
                                  [_vm._v("否")]
                                )
                          ]
                        }
                      },
                      {
                        key: "cenCollection",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          return [
                            _vm.orderInfo.status === 1
                              ? _c(
                                  "el-form-item",
                                  {
                                    key: "form-item-cenCollection" + index,
                                    attrs: {
                                      prop: "data." + index + ".cenCollection",
                                      rules: _vm.detailFormRules.cenCollection
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { size: "mini", clearable: "" },
                                        on: {
                                          change: function($event) {
                                            return _vm.detailSaveOne(row)
                                          }
                                        },
                                        model: {
                                          value: row.cenCollection,
                                          callback: function($$v) {
                                            _vm.$set(row, "cenCollection", $$v)
                                          },
                                          expression: "row.cenCollection"
                                        }
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: 2, label: "集采" }
                                        }),
                                        _c("el-option", {
                                          attrs: { value: 3, label: "非集采" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    row.cenCollection === 2
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              size: "mini",
                                              effect: "dark",
                                              type: "success"
                                            }
                                          },
                                          [_vm._v("集采")]
                                        )
                                      : row.cenCollection === 3
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              size: "mini",
                                              effect: "dark",
                                              type: "info"
                                            }
                                          },
                                          [_vm._v("非集采")]
                                        )
                                      : _c("span", [_vm._v("-")])
                                  ],
                                  1
                                )
                          ]
                        }
                      },
                      {
                        key: "salesUnitPrice",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          return [
                            _vm.orderInfo.status === 1
                              ? _c(
                                  "el-form-item",
                                  {
                                    key: "form-item-salesUnitPrice" + index,
                                    attrs: {
                                      prop: "data." + index + ".salesUnitPrice",
                                      rules: _vm.detailFormRules.salesUnitPrice
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        size: "mini",
                                        min: 0,
                                        precision: 2,
                                        controls: false
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      model: {
                                        value: row.salesUnitPrice,
                                        callback: function($$v) {
                                          _vm.$set(row, "salesUnitPrice", $$v)
                                        },
                                        expression: "row.salesUnitPrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v(_vm._s(row.salesUnitPrice))])
                          ]
                        }
                      },
                      {
                        key: "terUnitPrice",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm.orderInfo.status === 1
                              ? _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    min: 0,
                                    precision: 2,
                                    size: "mini",
                                    controls: false
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    }
                                  },
                                  model: {
                                    value: row.terUnitPrice,
                                    callback: function($$v) {
                                      _vm.$set(row, "terUnitPrice", $$v)
                                    },
                                    expression: "row.terUnitPrice"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(row.terUnitPrice.toFixed(2)))
                                ])
                          ]
                        }
                      },
                      {
                        key: "remarks",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm.orderInfo.status === 1
                              ? _c("el-input", {
                                  attrs: { size: "mini" },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    }
                                  },
                                  model: {
                                    value: row.remarks,
                                    callback: function($$v) {
                                      _vm.$set(row, "remarks", $$v)
                                    },
                                    expression: "row.remarks"
                                  }
                                })
                              : _c("span", [_vm._v(_vm._s(row.remarks))])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2202937586
                  )
                })
              ],
              1
            )
          : _vm._e()
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-row",
              {
                staticStyle: {
                  width: "100%",
                  "margin-bottom": "10px",
                  "font-size": "12px"
                }
              },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _vm._v("审核：" + _vm._s(_vm.orderInfo.nameReviewer))
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v("审核时间：" + _vm._s(_vm.orderInfo.auditTime))
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _vm._v("报价：" + _vm._s(_vm.orderInfo.quotationPersonnel))
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v("报价时间：" + _vm._s(_vm.orderInfo.quotationDate))
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _vm._v("回库：" + _vm._s(_vm.orderInfo.returningPersonnel))
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v("回库时间：" + _vm._s(_vm.orderInfo.returnDate))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.orderInfo.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formStorage)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v(" 暂存 ")
                          ]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.offerSubmit)
                              }
                            }
                          },
                          [_vm._v("报价")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.orderPrint }
                      },
                      [
                        _c("i", { staticClass: "fa fa-print" }),
                        _vm._v(" 打印 ")
                      ]
                    ),
                    _vm.orderInfo.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelOrder }
                          },
                          [_vm._v("驳回")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelPrice }
                          },
                          [_vm._v("撤销报价")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("DetailAddComponents", {
        ref: "DetailAddComponents",
        on: { confirm: _vm.detailAddConfirm }
      }),
      _c("DetailAdjustComponents", {
        ref: "DetailAdjustComponents",
        on: { confirm: _vm.detailAdjustConfirm }
      }),
      _c("RateComponent", {
        ref: "RateComponentRef",
        on: { update: _vm.updateRate }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }