var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { sm: 24, lg: 12, xl: 12 } },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "list-card",
                    staticStyle: { height: "140px" },
                    attrs: { shadow: "never" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "space-between"
                        }
                      },
                      [
                        _c("div", { staticClass: "profile" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.profile.name))
                          ]),
                          _c("div", { staticClass: "department" }, [
                            _vm._v(
                              _vm._s(_vm.companyInfo.short_name) +
                                "-" +
                                _vm._s(
                                  _vm.identity.toString().replace(/,/g, "、")
                                )
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "organization" }, [
                          _vm._v(
                            " 单位名称：" +
                              _vm._s(_vm.profile.organization_name) +
                              " "
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "el-card",
                  {
                    staticClass: "list-card",
                    staticStyle: {
                      "margin-top": "20px",
                      "margin-bottom": "20px"
                    },
                    attrs: { shadow: "never" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "justify-content": "space-between",
                              "align-items": "center"
                            }
                          },
                          [_c("span", [_vm._v("公告栏")])]
                        )
                      ]
                    ),
                    _c(
                      "el-table",
                      {
                        ref: "noticeTable",
                        attrs: { data: _vm.noticeList, "show-header": false },
                        on: { "row-click": _vm.noticeRowClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            width: "80",
                            label: "通知类型",
                            align: "center",
                            "show-overflow-tooltip": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.noticeTypeFormatter(row)
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "title",
                            label: "通知标题",
                            align: "left",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "CreatedAt",
                            width: "180",
                            label: "通知时间",
                            align: "center"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { sm: 24, lg: 12, xl: 12 } },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewSupplierWarning }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-consign stat-icon",
                            staticStyle: { background: "#2FC6C9" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(_vm.warningRow.countSupplierWarning)
                              )
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("供货商预警")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewRetailerWarning }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-consign stat-icon",
                            staticStyle: { background: "#FFBA82" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(_vm.warningRow.countDistributorWarning)
                              )
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("经销商预警")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewStockWarning }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-valid-warning stat-icon",
                            staticStyle: { background: "#59B1EF" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(_vm.warningRow.countStockMainWarning)
                              )
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("库存预警")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewCertWarning }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-stock-warning stat-icon",
                            staticStyle: { background: "#DC536B" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(_vm._s(_vm.warningRow.countCertWarning))
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("注册证预警")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewValidateWarning }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-stock-warning stat-icon",
                            staticStyle: { background: "#2FC6C9" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(_vm.warningRow.countStockQuantityWarning)
                              )
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("效期预警")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewNotInWarehouseWarning }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-stock-warning stat-icon",
                            staticStyle: { background: "#FFBA82" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(_vm.warningRow.countIsNotReturnedWarning)
                              )
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("未回库预警")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _vm.profile.group_id.includes(7)
                      ? _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                          _c(
                            "div",
                            {
                              staticClass: "stat-card",
                              on: { click: _vm.viewAppWarning }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-stock-warning stat-icon",
                                staticStyle: { background: "#FFBA82" }
                              }),
                              _c("div", { staticClass: "text" }, [
                                _c("div", { staticClass: "number" }, [
                                  _vm._v(
                                    _vm._s(_vm.warningRow.countAppPayWarning)
                                  )
                                ]),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("应付款预警")
                                ])
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.profile.group_id.includes(7)
                      ? _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                          _c("div", { staticClass: "stat-card" }, [
                            _c("i", {
                              staticClass: "fa fa-stock-warning stat-icon",
                              staticStyle: { background: "#DC536B" }
                            }),
                            _c("div", { staticClass: "text" }, [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(_vm._s(0))
                              ]),
                              _c("div", { staticClass: "title" }, [
                                _vm._v("应收款预警")
                              ])
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewNonmoving }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-stock-warning stat-icon",
                            staticStyle: { background: "#59B1EF" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(_vm._s(_vm.warningRow.countNotMoving))
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("不动消产品预警")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewInvoiceWarning }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-stock-warning stat-icon",
                            staticStyle: { background: "#FFBA82" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(_vm._s(_vm.warningRow.invoiceCountWarning))
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("超期未回款预警")
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("el-col", { attrs: { sm: 24, lg: 12, xl: 8 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "stat-card",
                          on: { click: _vm.viewNotReturnWarning }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-stock-warning stat-icon",
                            staticStyle: { background: "#FFBA82" }
                          }),
                          _c("div", { staticClass: "text" }, [
                            _c("div", { staticClass: "number" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.warningRow
                                    .stockoutCountIsNotReturnedWarning
                                )
                              )
                            ]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("临调超期未回库预警")
                            ])
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _vm.agencyGroup.length > 0
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "list-card",
                                attrs: { shadow: "never" }
                              },
                              [
                                _c(
                                  "el-tabs",
                                  {
                                    on: { "tab-click": _vm.agencyChange },
                                    model: {
                                      value: _vm.agencyActive,
                                      callback: function($$v) {
                                        _vm.agencyActive = $$v
                                      },
                                      expression: "agencyActive"
                                    }
                                  },
                                  _vm._l(_vm.agencyGroup, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "el-tab-pane",
                                      {
                                        key: "agency-tab-item-" + index,
                                        attrs: { name: item.name }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label"
                                          },
                                          [
                                            _vm._v(_vm._s(item.label) + " "),
                                            _c("span", [
                                              _vm._v("("),
                                              _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(item.total))]
                                              ),
                                              _vm._v(")")
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "el-table",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.agencyLoading,
                                                expression: "agencyLoading"
                                              }
                                            ],
                                            key: "table" + index,
                                            attrs: {
                                              data: item.data,
                                              "show-header": false
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "content",
                                                label: "待办事项",
                                                align: "left",
                                                "show-overflow-tooltip": ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _c(
                                                          "el-link",
                                                          {
                                                            attrs: {
                                                              type: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.toAgency(
                                                                  row
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.content
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "sponsorName",
                                                width: "120",
                                                label: "销售人员",
                                                align: "right",
                                                "show-overflow-tooltip": ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(scope) {
                                                      return [
                                                        _vm.agencyActive === "4"
                                                          ? _c("span", [
                                                              _vm._v("销售")
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .sponsorName
                                                          ) + " "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                prop: "CreatedAt",
                                                width: "160",
                                                label: "通知时间",
                                                align: "center",
                                                formatter: function(
                                                  row,
                                                  column
                                                ) {
                                                  return _vm.timeFormatter(
                                                    row.CreatedAt
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "20px"
                                            }
                                          },
                                          [
                                            _c("el-pagination", {
                                              attrs: {
                                                "hide-on-single-page": true,
                                                background: "",
                                                layout:
                                                  "prev, pager, next, total",
                                                align: "center",
                                                total: item.total,
                                                "current-page": item.page,
                                                "page-size": item.pageSize
                                              },
                                              on: {
                                                "current-change": function(
                                                  page
                                                ) {
                                                  return _vm.agencyGroupPageChange(
                                                    item.name,
                                                    page
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.noticeCurrentRow.title,
              visible: _vm.noticeDialogVisible,
              width: "500px",
              "custom-class": "dialog-class",
              "modal-append-to-body": false,
              "destroy-on-close": true
            },
            on: {
              "update:visible": function($event) {
                _vm.noticeDialogVisible = $event
              }
            }
          },
          [
            _c("div", { staticClass: "notice-content" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.noticeCurrentRow.content) }
              })
            ]),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function($event) {
                        _vm.noticeDialogVisible = false
                      }
                    }
                  },
                  [_vm._v("关 闭")]
                )
              ],
              1
            )
          ]
        ),
        _c("ViewSupplierComponents", { ref: "ViewSupplierComponents" }),
        _c("ViewRetailerComponents", { ref: "ViewRetailerComponents" }),
        _c("ViewStockComponents", { ref: "ViewStockComponents" }),
        _c("ViewCertComponents", { ref: "ViewCertComponents" }),
        _c("ViewValidateComponents", {
          ref: "ViewValidateComponents",
          attrs: { threeDayLastRemindProp: _vm.threeDayLastRemind },
          on: {
            "update:threeDayLastRemindProp": function($event) {
              _vm.threeDayLastRemind = $event
            },
            "update:three-day-last-remind-prop": function($event) {
              _vm.threeDayLastRemind = $event
            }
          }
        }),
        _c("ViewNotInWarehouseWarning", { ref: "ViewNotInWarehouseWarning" }),
        _c("ViewAppWarningComponent", { ref: "ViewAppWarningComponentRef" }),
        _c("PaymentComponent", {
          ref: "PaymentComponentRef",
          on: {
            reload: function($event) {
              return _vm.agencyChange()
            }
          }
        }),
        _c("ViewNonmovingComponent", {
          ref: "ViewNonmovingComponentRef",
          on: {
            reload: function($event) {
              return _vm.init()
            }
          }
        }),
        _c("ViewInvoiceWarningComponent", {
          ref: "ViewInvoiceWarningComponentRef"
        }),
        _c("ViewNotReturnWarningComponent", {
          ref: "ViewNotReturnWarningComponentRef"
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }