import { render, staticRenderFns } from "./cost.vue?vue&type=template&id=d62bf6c4&"
import script from "./cost.vue?vue&type=script&lang=js&"
export * from "./cost.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\ERP\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d62bf6c4')) {
      api.createRecord('d62bf6c4', component.options)
    } else {
      api.reload('d62bf6c4', component.options)
    }
    module.hot.accept("./cost.vue?vue&type=template&id=d62bf6c4&", function () {
      api.rerender('d62bf6c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/statistical/sales/cost.vue"
export default component.exports