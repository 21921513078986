var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
        "justify-content": "flex-start",
        height: "100%",
        overflow: "hidden"
      }
    },
    [
      _c("div", { staticStyle: { height: "40px", width: "100%" } }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c("el-input", {
              attrs: {
                placeholder: "输入关键字进行过滤",
                size: "small",
                "suffix-icon": "el-icon-search"
              },
              model: {
                value: _vm.filterText,
                callback: function($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText"
              }
            }),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showRelateButton,
                    expression: "showRelateButton"
                  }
                ],
                staticStyle: { "margin-left": "5px" },
                attrs: { type: "primary", size: "mini" },
                on: {
                  click: function($event) {
                    return _vm.toRelate()
                  }
                }
              },
              [_vm._v("关联产品")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticStyle: { flex: "1", "overflow-y": "auto", width: "100%" } },
        [
          _c("el-tree", {
            ref: "categoryTreeRef",
            attrs: {
              data: _vm.dataList,
              props: _vm.defaultProps,
              "default-expand-all": true,
              "highlight-current": true,
              "filter-node-method": _vm.filterNode,
              "expand-on-click-node": false
            },
            on: { "node-click": _vm.treeClick },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var node = ref.node
                  var data = ref.data
                  return _c("span", { staticClass: "custom-tree-node" }, [
                    _c(
                      "span",
                      { staticClass: "title", attrs: { title: node.label } },
                      [
                        data.type == "PRODUCT_LINE"
                          ? _c("i", {
                              staticClass: "el-icon-folder-opened pre-icon"
                            })
                          : _vm._e(),
                        data.type == "CLASS"
                          ? _c("i", {
                              staticClass: "el-icon-document-copy pre-icon"
                            })
                          : _vm._e(),
                        data.type == "SUB_CLASS"
                          ? _c("i", { staticClass: "el-icon-tickets pre-icon" })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(node.label))
                      ]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }