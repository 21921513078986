var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { display: "flex", "align-items": "flex-start" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "250px",
                border: "1px solid #ebeef5",
                overflow: "auto",
                height: "430px"
              }
            },
            [
              _c("el-tree", {
                ref: "treeRef",
                attrs: {
                  data: _vm.treeData,
                  props: _vm.defaultProps,
                  "default-expand-all": true,
                  "show-checkbox": "",
                  "check-strictly": false,
                  "highlight-current": true,
                  "expand-on-click-node": false
                },
                on: { check: _vm.treeCheckChange }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "280px", margin: "0 10px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.productLoading,
                      expression: "productLoading"
                    }
                  ],
                  ref: "productTableRef",
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    size: "mini",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    },
                    "highlight-current-row": "",
                    stripe: "",
                    data: _vm.productList,
                    height: "400px"
                  },
                  on: { "selection-change": _vm.productSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "#",
                      width: "60",
                      type: "index",
                      align: "center",
                      index: function(index) {
                        return _vm.indexMethod(index, _vm.productForm)
                      }
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      "min-width": "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "5px" },
                  attrs: { align: "center" }
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      small: "",
                      background: "",
                      layout: "prev, pager, next",
                      total: _vm.productForm.total,
                      "pager-count": 5,
                      "page-size": _vm.productForm.pageSize,
                      "current-page": _vm.productForm.page
                    },
                    on: { "current-change": _vm.productPageChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { flex: "1" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.specsLoading,
                      expression: "specsLoading"
                    }
                  ],
                  ref: "specsTableRef",
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    size: "mini",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    },
                    "highlight-current-row": "",
                    stripe: "",
                    data: _vm.specsList,
                    height: "400px"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "#",
                      width: "60",
                      type: "index",
                      align: "center",
                      index: function(index) {
                        return _vm.indexMethod(index, _vm.specsForm)
                      }
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      "min-width": "150",
                      prop: "productNumber",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格型号",
                      "min-width": "150",
                      prop: "specificationModel",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "5px" },
                  attrs: { align: "center" }
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      small: "",
                      background: "",
                      layout: "sizes, prev, pager, next",
                      total: _vm.specsForm.total,
                      "pager-count": 5,
                      "page-size": _vm.specsForm.pageSize,
                      "current-page": _vm.specsForm.page
                    },
                    on: {
                      "current-change": _vm.specsPageChange,
                      "size-change": _vm.specsSizeChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("添 加")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }