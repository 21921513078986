var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", [
        _c(
          "div",
          [
            _c(
              "el-upload",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: {
                  drag: "",
                  "show-file-list": false,
                  "auto-upload": true,
                  "element-loading-text": "数据导入中，请稍候......",
                  headers: _vm.uploadToken,
                  accept: _vm.uploadFileType.toString(),
                  "http-request": _vm.uploadRequest
                }
              },
              [
                _c("i", { staticClass: "el-icon-upload" }),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v("将文件拖到此处，或"),
                  _c("em", [_vm._v("点击上传")])
                ]),
                _c(
                  "div",
                  {
                    staticClass: "el-upload__tip",
                    attrs: { slot: "tip" },
                    slot: "tip"
                  },
                  [_vm._v("只能上传 EXCEL 文件，且不超过30MB")]
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "100%" },
                attrs: { type: "primary", size: "mini", disabled: _vm.loading },
                on: { click: _vm.templateDownload }
              },
              [_vm._v("EXCEL模板下载")]
            )
          ],
          1
        ),
        _vm.errorMsg
          ? _c("div", { staticStyle: { margin: "10px 0", color: "#f00" } }, [
              _vm._v(_vm._s(_vm.errorMsg))
            ])
          : _vm._e(),
        _vm.successMsg
          ? _c(
              "div",
              { staticStyle: { margin: "10px 0", color: "rgb(103, 194, 58)" } },
              [_vm._v(_vm._s(_vm.successMsg))]
            )
          : _vm._e(),
        _c("p", [_vm._v("注意事项：")]),
        _c("ol", [
          _c("li", [_vm._v("导入功能仅针对原有产品，覆盖其集采设置")]),
          _c("li", [_vm._v("“*”为必填项，不可为空 ")])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }