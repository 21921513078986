var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("财务设定")]),
              _c("el-breadcrumb-item", [_vm._v("产品集采设置")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              height: "100%",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "flex-start"
            }
          },
          [
            _c(
              "div",
              { staticStyle: { height: "40px", "margin-bottom": "10px" } },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo",
                    attrs: {
                      "default-active": _vm.activeIndex,
                      mode: "horizontal"
                    },
                    on: { select: _vm.activeIndexChange }
                  },
                  [
                    _c("el-menu-item", { attrs: { index: "1" } }, [
                      _vm._v("基础产品集采设置")
                    ]),
                    _c("el-menu-item", { attrs: { index: "2" } }, [
                      _vm._v("医院产品集采设置")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticStyle: { flex: "1", "overflow-y": "auto" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _vm.activeIndex === "2"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            width: "230px",
                            height: "100%",
                            overflow: "hidden",
                            "margin-right": "10px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                border: "1px solid #ebeef5",
                                height: "100%"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "5px",
                                    height: "100%",
                                    overflow: "hidden"
                                  }
                                },
                                [
                                  _c("HospitalTreeComponent", {
                                    ref: "HospitalTreeComponentRef",
                                    on: { "tree-click": _vm.hospitalTreeClick }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "250px",
                        "margin-right": "10px",
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "flex-start"
                      }
                    },
                    [
                      _vm.activeIndex === "2"
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                height: "38px",
                                display: "flex",
                                "flex-direction": "row",
                                "align-items": "center",
                                padding: "5px",
                                background: "#f9f9f9",
                                "margin-bottom": "10px"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "70px",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v("默认设置")]
                              ),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled: !_vm.searchForm.hospitalId
                                  },
                                  on: { change: _vm.hospitalCentralizedChange },
                                  model: {
                                    value: _vm.hospitalForm.centralized,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.hospitalForm,
                                        "centralized",
                                        $$v
                                      )
                                    },
                                    expression: "hospitalForm.centralized"
                                  }
                                },
                                _vm._l(_vm.centralizedTypeList, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: "centralizedType" + index,
                                    attrs: { label: item.name, value: item.ID }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            border: "1px solid #ebeef5",
                            overflow: "hidden"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "5px",
                                height: "100%",
                                overflow: "hidden"
                              }
                            },
                            [
                              _c("CategoryTreeComponent", {
                                ref: "CategoryTreeComponentRef",
                                attrs: {
                                  "hospital-id": _vm.searchForm.hospitalId,
                                  "show-relate-button":
                                    _vm.activeIndex === "1" ? false : true,
                                  "show-delete-button":
                                    _vm.activeIndex === "1" ? false : true
                                },
                                on: {
                                  "to-relate": _vm.toRelate,
                                  "tree-click": _vm.categoryTreeClick,
                                  reload: function($event) {
                                    return _vm.loadData()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "form-card",
                          attrs: { shadow: "never" }
                        },
                        [
                          _c(
                            "el-form",
                            {
                              attrs: {
                                inline: true,
                                model: _vm.searchForm,
                                size: "small",
                                "label-suffix": ":"
                              },
                              nativeOn: {
                                submit: function($event) {
                                  $event.preventDefault()
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchFormSubmit.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品名称" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "产品名称",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.searchForm.productName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品编号" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "产品编号",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.searchForm.productNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productNumber",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.searchFormExpand,
                                      expression: "searchFormExpand"
                                    }
                                  ],
                                  attrs: { label: "规格型号" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "规格型号",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.searchForm.specificationModel,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "specificationModel",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.specificationModel"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.searchFormExpand,
                                      expression: "searchFormExpand"
                                    }
                                  ],
                                  attrs: { label: "产品主码" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "产品主码",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.searchForm.barCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.searchForm, "barCode", $$v)
                                      },
                                      expression: "searchForm.barCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-search"
                                      },
                                      on: { click: _vm.searchFormSubmit }
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-download"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toExport()
                                        }
                                      }
                                    },
                                    [_vm._v("导出")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-upload2"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toBatchImport()
                                        }
                                      }
                                    },
                                    [_vm._v("批量导入")]
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.searchFormExpand = !_vm.searchFormExpand
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.searchFormExpand ? "合并" : "展开"
                                        )
                                      ),
                                      _c("i", {
                                        class:
                                          "el-icon-arrow-" +
                                          (_vm.searchFormExpand
                                            ? "up"
                                            : "down") +
                                          " el-icon--right"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            display: "flex",
                            "align-items": "center",
                            "font-size": "14px",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c("span", { staticStyle: { color: "#f00" } }, [
                            _vm.activeIndex === "2"
                              ? _c("span", [
                                  _vm._v(
                                    "提醒：关联产品的集采设置优先级高于默认设置"
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "div",
                            [
                              _vm.activeIndex === "2"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.productDelete()
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setCentralized(2)
                                    }
                                  }
                                },
                                [_vm._v("设置为集采")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setCentralized(1)
                                    }
                                  }
                                },
                                [_vm._v("设置为非集采")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("YTable", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.dataLoading,
                            expression: "dataLoading"
                          }
                        ],
                        key: "dataTable" + _vm.timestamp,
                        ref: "dataTableRef",
                        attrs: {
                          hasIndex: true,
                          hasPagination: true,
                          hasSelection: true,
                          pagination: _vm.searchForm,
                          columns: _vm.columns,
                          data: _vm.dataList,
                          routeName: _vm.dataTableCacheName
                        },
                        on: {
                          sizeChange: _vm.sizeChange,
                          pageChange: _vm.pageChange
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "operations",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: {
                                      change: function($event) {
                                        return _vm.centralizedChange(row)
                                      }
                                    },
                                    model: {
                                      value: row.centralized,
                                      callback: function($$v) {
                                        _vm.$set(row, "centralized", $$v)
                                      },
                                      expression: "row.centralized"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("集采")
                                    ]),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("非集采")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]
              )
            ]),
            _vm.activeIndex === "2"
              ? _c("RelateComponent", {
                  ref: "RelateComponentRef",
                  on: { add: _vm.relateProduct, reload: _vm.loadData }
                })
              : _vm._e(),
            _c("ImportComponent", {
              ref: "ImportComponentRef",
              on: { reload: _vm.loadData }
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }