var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购验收")]),
              _vm._l(_vm.breadcrumbList, function(item, index) {
                return _c("el-breadcrumb-item", { key: "breadcrumb" + index }, [
                  _vm._v(_vm._s(item))
                ])
              }),
              _vm.$route.name === "purchase-examine-approve"
                ? _c(
                    "el-breadcrumb-item",
                    [
                      _vm.orderInfo.examine_status === 1
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                size: "mini",
                                effect: "dark",
                                type: "primary"
                              }
                            },
                            [_vm._v("待审批")]
                          )
                        : _vm._e(),
                      _vm.orderInfo.examine_status === 2
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                size: "mini",
                                effect: "dark",
                                type: "danger"
                              }
                            },
                            [_vm._v("审批不通过")]
                          )
                        : _vm._e(),
                      _vm.orderInfo.examine_status === 3
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                size: "mini",
                                effect: "dark",
                                type: "success"
                              }
                            },
                            [_vm._v("审批通过")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _vm.form.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [_vm._v(" 订单编号：" + _vm._s(_vm.form.orderNumber) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "show-message": false,
              "label-suffix": ":",
              disabled: _vm.form.state > 1,
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.ID,
                                attrs: {
                                  label: item.name,
                                  value: item.ID,
                                  disabled:
                                    item.status === 0 || item.isActive === 2
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.status === 0 ? true : false,
                                            expression:
                                              "item.status === 0 ? true : false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(无效)")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.isActive === 2
                                                ? true
                                                : false,
                                            expression:
                                              "item.isActive === 2 ? true : false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(未激活)")]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "订货日期", prop: "purchaseDate" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.form.purchaseDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "purchaseDate", $$v)
                            },
                            expression: "form.purchaseDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "订货人员", prop: "buyerUid" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "buyerUid",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.state > 1
                            },
                            model: {
                              value: _vm.form.buyerUid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "buyerUid", $$v)
                              },
                              expression: "form.buyerUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "buyerUid-" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "subsidiaryIdRef",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.state > 1
                            },
                            on: { change: _vm.reSetTaxRate },
                            model: {
                              value: _vm.form.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "subsidiaryId", $$v)
                              },
                              expression: "form.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: {
                                label: item.companyName,
                                value: item.ID,
                                disabled: item.isValid === 1 ? false : true
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商", prop: "manufacturerId" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "manufacturerIdRef",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.state > 1
                            },
                            on: { change: _vm.loadSupplier },
                            model: {
                              value: _vm.form.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturerId", $$v)
                              },
                              expression: "form.manufacturerId"
                            }
                          },
                          _vm._l(_vm.manufacturerList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "预计到货日期",
                          prop: "expectedDeliveryDate"
                        }
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.form.expectedDeliveryDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "expectedDeliveryDate", $$v)
                            },
                            expression: "form.expectedDeliveryDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "合同编号", prop: "contractNumber" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "合同编号", maxlength: "30" },
                            model: {
                              value: _vm.form.contractNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "contractNumber", $$v)
                              },
                              expression: "form.contractNumber"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "供货商订单号",
                          prop: "supplierOrderNumber"
                        }
                      },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "供货商订单号",
                              maxlength: "30"
                            },
                            model: {
                              value: _vm.form.supplierOrderNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "supplierOrderNumber", $$v)
                              },
                              expression: "form.supplierOrderNumber"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "发货地址", prop: "sendAddress" } },
                      [
                        _c("div", { staticStyle: { margin: "0 10px 0 0" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  "border-right": "1px solid #ebeef5"
                                },
                                attrs: {
                                  placeholder: "请输入发货地址",
                                  disabled: true,
                                  maxlength: "250"
                                },
                                model: {
                                  value: _vm.form.sendAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "sendAddress", $$v)
                                  },
                                  expression: "form.sendAddress"
                                }
                              }),
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    width: "80px",
                                    "text-align": "center",
                                    "margin-left": "10px"
                                  },
                                  attrs: {
                                    underline: false,
                                    type: "primary",
                                    disabled: _vm.form.state > 1
                                  },
                                  on: { click: _vm.sendAddressChange }
                                },
                                [_vm._v("修改")]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货地址", prop: "receiveAddress" } },
                      [
                        _c("div", { staticStyle: { margin: "0 10px 0 0" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  "border-right": "1px solid #ebeef5"
                                },
                                attrs: {
                                  placeholder: "请输入收货地址",
                                  disabled: true,
                                  maxlength: "250"
                                },
                                model: {
                                  value: _vm.form.receiveAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "receiveAddress", $$v)
                                  },
                                  expression: "form.receiveAddress"
                                }
                              }),
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    width: "80px",
                                    "text-align": "center",
                                    "margin-left": "10px"
                                  },
                                  attrs: {
                                    underline: false,
                                    type: "primary",
                                    disabled: _vm.form.state > 1
                                  },
                                  on: { click: _vm.receiveAddressChange }
                                },
                                [_vm._v("修改")]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 16, xl: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remarks" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "请输入备注",
                              maxlength: "250"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0 0 0"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tool-buttons", staticStyle: { display: "flex" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab-list-grey",
                    staticStyle: { margin: "0" }
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(1)
                          }
                        }
                      },
                      [_vm._v("寄售明细")]
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 2 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(2)
                          }
                        }
                      },
                      [_vm._v("入库明细")]
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 3 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(3)
                          }
                        }
                      },
                      [_vm._v("退货明细")]
                    )
                  ]
                ),
                _vm.activeTabId === 1 && _vm.form.state === 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-plus",
                              disabled: _vm.form.state !== 1
                            },
                            on: { click: _vm.selectSpecs }
                          },
                          [_vm._v("增加产品")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              disabled: _vm.form.state !== 1
                            },
                            on: { click: _vm.scanInput }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-barcode",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(" 扫码录入 ")
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-upload2",
                              size: "mini",
                              disabled: _vm.form.state !== 1
                            },
                            on: { click: _vm.importExcel }
                          },
                          [_vm._v("EXCEL导入")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm.activeTabId === 1
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "row",
                        "align-items": "center",
                        "justify-content": "flex-end",
                        "font-size": "14px",
                        color: "#606266"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v("寄售总数："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f00",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.totalQuantity()))]
                        )
                      ]),
                      _c("div", { staticStyle: { "margin-left": "30px" } }, [
                        _vm._v("总金额（不含税）："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f00",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.totalAmountExclusive()))]
                        )
                      ]),
                      _c("div", { staticStyle: { "margin-left": "30px" } }, [
                        _vm._v("总金额（含税）："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f00",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.totalAmount()))]
                        )
                      ]),
                      _c("div", { staticStyle: { "margin-left": "30px" } }, [
                        _vm._v("税额合计"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f00",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.totalAmountTax()))]
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 1,
                expression: "activeTabId===1"
              }
            ]
          },
          [
            _c(
              "el-form",
              {
                ref: "detailListForm",
                attrs: {
                  model: _vm.detailListForm,
                  rules: _vm.detailListFormRules,
                  size: "small",
                  "show-message": false
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.detailLoading,
                        expression: "detailLoading"
                      }
                    ],
                    ref: "detailListTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "detailListTable",
                      data: _vm.detailListForm.data,
                      border: "",
                      size: "mini",
                      "highlight-current-row": "",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      },
                      "row-key": _vm.detailRowKey,
                      "row-class-name": _vm.rowClassName,
                      "max-height": "420"
                    },
                    on: { "row-click": _vm.detailRowClick }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        width: "60",
                        align: "center",
                        index: function(index) {
                          return _vm.indexMethod(index, _vm.detailListForm)
                        }
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productNumber",
                        label: "产品编号",
                        "min-width": "200",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品名称",
                        "min-width": "200",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "genericName",
                        label: "通用名",
                        "min-width": "200",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specificationModel",
                        label: "规格型号",
                        "min-width": "100",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "registrationNumber",
                        label: "注册证号",
                        "min-width": "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "unit",
                        width: "60",
                        label: "单位",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inWarehouseSum",
                        width: "100",
                        label: "入库数量",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "purchaseQuantity",
                        label: "寄售数量",
                        width: "100",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return [
                              _vm.form.state === 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      key: "purchaseQuantity" + $index,
                                      attrs: {
                                        prop:
                                          "data." +
                                          $index +
                                          ".purchaseQuantity",
                                        rules:
                                          _vm.detailListFormRules
                                            .purchaseQuantity
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        ref: "purchaseQuantity-" + $index,
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: "寄售数量",
                                          precision: 0,
                                          controls: false
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.detailSaveOne(row)
                                          },
                                          input: function($event) {
                                            return _vm.purchaseQuantityInput(
                                              row
                                            )
                                          }
                                        },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return function(event) {
                                              return _vm.nextFocus(
                                                "manufacturerDate-" + $index,
                                                event
                                              )
                                            }.apply(null, arguments)
                                          }
                                        },
                                        model: {
                                          value: row.purchaseQuantity,
                                          callback: function($$v) {
                                            _vm.$set(
                                              row,
                                              "purchaseQuantity",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "row.purchaseQuantity"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(row.purchaseQuantity))
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "purchaseUnitPrice",
                        label: "寄售单价(含税)",
                        width: "160",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return [
                              _vm.form.state === 1
                                ? _c("el-input-number", {
                                    ref: "purchaseUnitPrice-" + $index,
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      precision: 2,
                                      controls: false
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return function(event) {
                                          return _vm.nextFocus(
                                            "sterilizationBatchNumber-" +
                                              $index,
                                            event
                                          )
                                        }.apply(null, arguments)
                                      }
                                    },
                                    model: {
                                      value: row.purchaseUnitPrice,
                                      callback: function($$v) {
                                        _vm.$set(row, "purchaseUnitPrice", $$v)
                                      },
                                      expression: "row.purchaseUnitPrice"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(_vm._s(row.purchaseUnitPrice))
                                  ])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "taxRate",
                        label: "税率",
                        width: "160",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "data." + $index + ".taxRate",
                                    rules: _vm.detailListFormRules.taxRate
                                  }
                                },
                                [
                                  _vm.form.state === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            height: "100%",
                                            flex: "1",
                                            position: "relative"
                                          }
                                        },
                                        [
                                          _c("el-input-number", {
                                            ref: "taxRate-" + $index,
                                            attrs: {
                                              size: "mini",
                                              precision: 2,
                                              controls: false,
                                              min: 0,
                                              max: 100
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            model: {
                                              value: row.taxRate,
                                              callback: function($$v) {
                                                _vm.$set(row, "taxRate", $$v)
                                              },
                                              expression: "row.taxRate"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                padding: "0 5px",
                                                position: "absolute",
                                                right: "10px",
                                                top: "6px"
                                              }
                                            },
                                            [_vm._v("%")]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row.taxRate == undefined ||
                                              row.taxRate == null
                                              ? 0
                                              : row.taxRate
                                          ) + "%"
                                        )
                                      ])
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "税额", width: "100", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.calculateTaxAmount(scope.row)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "寄售单价(不含税)",
                        width: "120",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.calculateTaxPurchaseUnitPrice(scope.row)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "寄售金额(含税)",
                        width: "120",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.sumRowAmount(scope.row)) + " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "寄售金额(不含税)",
                        width: "120",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.sumRowAmountTaxExclusive(scope.row)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "remarks",
                        label: "备注",
                        "min-width": "200",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.form.state === 1
                                ? _c("el-input", {
                                    attrs: { size: "mini" },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.remarks,
                                      callback: function($$v) {
                                        _vm.$set(row, "remarks", $$v)
                                      },
                                      expression: "row.remarks"
                                    }
                                  })
                                : _c("span", [_vm._v(_vm._s(row.remarks))])
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "更多", width: "60", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    width: "600",
                                    trigger: "click"
                                  }
                                },
                                [
                                  _c("DetailExtendComponents", {
                                    key: $index,
                                    ref: "DetailExtendComponents",
                                    attrs: { row: row }
                                  }),
                                  _c("el-link", {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      padding: "5px"
                                    },
                                    attrs: {
                                      slot: "reference",
                                      icon: "el-icon-arrow-down",
                                      underline: false
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showSpecs(row.specsId)
                                      }
                                    },
                                    slot: "reference"
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 2,
                expression: "activeTabId === 2"
              }
            ]
          },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.inWarehouseList.slice(
                    (_vm.detailListForm.page - 1) * _vm.detailListForm.pageSize,
                    _vm.detailListForm.page * _vm.detailListForm.pageSize
                  ),
                  border: "",
                  "max-height": "420",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailListForm)
                    }
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编号",
                    prop: "specs.productNumber",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    prop: "main.productName",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "规格型号",
                    prop: "specs.specificationModel",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "注册证号",
                    prop: "main.registrationNumber",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "单位",
                    prop: "specs.unit",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "品牌",
                    prop: "main.brandName",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "入库数量",
                    prop: "inWarehouseQuantity",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "订单状态",
                    prop: "inWarehouse.inWarehouseStatus",
                    width: "100",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.inWarehouseStatusFormatter(
                                  row.inWarehouse.inWarehouseStatus
                                )
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "批号",
                    prop: "batchNumber",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "序列号",
                    prop: "trackingCode",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "备注",
                    prop: "remark",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm.activeTabId === 3
          ? _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    key: "returnListTable" + _vm.timestamp,
                    ref: "returnListTableRef",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.returnList.slice(
                        (_vm.detailListForm.page - 1) *
                          _vm.detailListForm.pageSize,
                        _vm.detailListForm.page * _vm.detailListForm.pageSize
                      ),
                      border: "",
                      size: "small",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      },
                      "highlight-current-row": ""
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { width: "80", align: "center", label: "序号" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.returnList.length -
                                        (_vm.detailListForm.page - 1) *
                                          _vm.detailListForm.pageSize -
                                        scope.$index
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2678836490
                      )
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productNumber",
                        label: "产品编号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.productName",
                        label: "产品名称",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.specificationModel",
                        label: "规格型号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.manufacturer",
                        label: "生产厂家",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.registrationNumber",
                        label: "注册证号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.batch_number",
                        label: "批号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.trackingCode",
                        label: "序列号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.unit",
                        label: "单位",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "returnWarehouseQuantity",
                        label: "退货数量",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "returnPrice",
                        label: "单价",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "退货金额",
                        width: "150",
                        align: "center"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        row.returnPrice *
                                        row.returnWarehouseQuantity
                                      ).toFixed(2)
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1489612115
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.detailListForm.total,
                "current-page": _vm.detailListForm.page,
                "page-size": _vm.detailListForm.pageSize,
                "page-sizes": [10, 20, 50, 100]
              },
              on: {
                "size-change": _vm.detailListFormSizeChange,
                "current-change": _vm.detailListFormPageChange
              }
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.$route.name === "purchase-examine-approve"
                      ? _c(
                          "span",
                          [
                            _vm.orderInfo.examine_status === 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.toExamine()
                                      }
                                    }
                                  },
                                  [_vm._v("审核")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _vm.form.state === 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      disabled: !(
                                        _vm.form.state == 1 &&
                                        _vm.detailRowIndex >= 0
                                      )
                                    },
                                    on: { click: _vm.detailRowRemove }
                                  },
                                  [_vm._v("删除行")]
                                )
                              : _vm._e(),
                            _vm.form.state === 1
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "prevent-re-click",
                                        rawName: "v-prevent-re-click"
                                      }
                                    ],
                                    attrs: { type: "primary" },
                                    on: { click: _vm.toFormStorage }
                                  },
                                  [_vm._v("暂存")]
                                )
                              : _vm._e(),
                            _vm.form.state === 1
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "prevent-re-click",
                                        rawName: "v-prevent-re-click"
                                      }
                                    ],
                                    attrs: { type: "primary" },
                                    on: { click: _vm.toFormSubmit }
                                  },
                                  [_vm._v("提交")]
                                )
                              : _vm._e(),
                            _vm.form.state === 2
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "prevent-re-click",
                                        rawName: "v-prevent-re-click"
                                      }
                                    ],
                                    attrs: { type: "danger" },
                                    on: { click: _vm.formUnSubmit }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa el-icon-refresh-left"
                                    }),
                                    _vm._v(" 撤销提交")
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.state === 1 && _vm.form.id
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "prevent-re-click",
                                        rawName: "v-prevent-re-click"
                                      }
                                    ],
                                    attrs: { type: "danger" },
                                    on: { click: _vm.orderInvalid }
                                  },
                                  [_vm._v(" 作废")]
                                )
                              : _vm._e(),
                            (_vm.form.state === 1 || _vm.form.state === 5) &&
                            _vm.form.id
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "prevent-re-click",
                                        rawName: "v-prevent-re-click"
                                      }
                                    ],
                                    attrs: { type: "danger" },
                                    on: { click: _vm.orderDelete }
                                  },
                                  [_vm._v(" 删除订单")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: !_vm.form.id
                                },
                                on: { click: _vm.orderPrint }
                              },
                              [_vm._v("打印")]
                            )
                          ],
                          1
                        )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("SpecsSearchComponents", {
        ref: "SpecsSearchComponents",
        on: { confirm: _vm.specsAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { add: _vm.specsAddToList, update: _vm.specsUpdate }
      }),
      _c("ImportExcelComponents", {
        ref: "ImportExcelComponents",
        on: { confirm: _vm.specsAddToList }
      }),
      _c("ExamineComponent", {
        ref: "ExamineComponentRef",
        on: { confirm: _vm.examineConfirm }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }