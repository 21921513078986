import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listOutWarehouseStep } from '@/api/dict';
import dayjs from 'dayjs';
export default {
  props: {
    orderRow: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    expandStepsStatus: {
      type: Boolean,
      default: true
    },
    orderType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      stepActive: -1,
      outWarehouseStepList: []
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.outWarehouseStepList = [];
      that.orderRow.CreatedAt = that.orderRow.CreatedAt ? dayjs(that.orderRow.CreatedAt).format('YYYY-MM-DD HH:mm') : null;
      that.orderRow.auditTime = that.orderRow.auditTime ? dayjs(that.orderRow.auditTime).format('YYYY-MM-DD HH:mm') : null;
      that.orderRow.deliveryTime = that.orderRow.deliveryTime ? dayjs(that.orderRow.deliveryTime).format('YYYY-MM-DD HH:mm') : null;
      that.orderRow.returnDate = that.orderRow.returnDate ? dayjs(that.orderRow.returnDate).format('YYYY-MM-DD HH:mm') : null;
      that.orderRow.sales.auditTime = that.orderRow.sales.auditTime ? dayjs(that.orderRow.sales.auditTime).format('YYYY-MM-DD HH:mm') : null;
      that.orderRow.sales.quotationDate = that.orderRow.sales.quotationDate ? dayjs(that.orderRow.sales.quotationDate).format('YYYY-MM-DD HH:mm') : null;
      that.orderRow.sales.postingDate = that.orderRow.sales.postingDate ? dayjs(that.orderRow.sales.postingDate).format('YYYY-MM-DD HH:mm') : null;
      that.stepActive = -1;
      listOutWarehouseStep().map(function (item) {
        that.outWarehouseStepList.push({
          name: item,
          tooltip: null
        });
      });

      if (['作废', '已退回', '部分退货', '已提交（被驳回）'].includes(that.orderRow.allStatusName)) {
        if (['部分退货', '已退回'].includes(that.orderRow.allStatusName)) {
          that.stepActive = 5;
        }

        if (['已提交（被驳回）'].includes(that.orderRow.allStatusName)) {
          that.stepActive = 2;
        }
      } else {
        that.outWarehouseStepList.forEach(function (item, index) {
          if (item.name === that.orderRow.allStatusName) {
            that.stepActive = index + 1;
          }
        });
      }

      if (that.stepActive >= 1) {
        var tooltip = "".concat(that.orderRow.issuePersonnel, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.CreatedAt);
        var findRow = that.outWarehouseStepList.find(function (item) {
          return item.name === '暂存';
        });
        findRow.tooltip = tooltip;
      }

      if (that.stepActive >= 2) {
        var _tooltip = "".concat(that.orderRow.issuePersonnel, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.CreatedAt);

        var _findRow = that.outWarehouseStepList.find(function (item) {
          return item.name === '已提交';
        });

        _findRow.tooltip = _tooltip;
      }

      if (that.stepActive >= 3) {
        var _tooltip2 = "".concat(that.orderRow.nameReviewer, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.auditTime);

        var _findRow2 = that.outWarehouseStepList.find(function (item) {
          return item.name === '已复核';
        });

        _findRow2.tooltip = _tooltip2;
      }

      if (that.stepActive >= 4 && that.orderRow.deliveryTime) {
        var _tooltip3 = "".concat(that.orderRow.nameWarehousePersonnel, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.deliveryTime);

        var _findRow3 = that.outWarehouseStepList.find(function (item) {
          return item.name === '发货出库';
        });

        _findRow3.tooltip = _tooltip3;
      }

      if (that.stepActive >= 5) {
        var _tooltip4 = "".concat(that.orderRow.returningPersonnel, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.returnDate);

        var _findRow4 = that.outWarehouseStepList.find(function (item) {
          return item.name === '已回库';
        });

        _findRow4.tooltip = _tooltip4;
        var findRow2 = that.outWarehouseStepList.find(function (item) {
          return item.name === '已报价';
        });

        if (that.stepActive === 5) {
          findRow2.tooltip = '在"销售管理/销售报价/' + that.orderType + '销售单"操作(需相关权限)';
        } else {
          findRow2.tooltip = "".concat(that.orderRow.sales.quotationPersonnel, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.sales.quotationDate);
        }
      }

      if (that.stepActive >= 6) {
        var _tooltip5 = "".concat(that.orderRow.sales.quotationPersonnel, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.sales.quotationDate);

        var _findRow5 = that.outWarehouseStepList.find(function (item) {
          return item.name === '已报价';
        });

        _findRow5.tooltip = _tooltip5;

        var _findRow6 = that.outWarehouseStepList.find(function (item) {
          return item.name === '已审核';
        });

        if (that.stepActive === 6) {
          _findRow6.tooltip = '在"财务管理/销售审核/销售单审核"操作(需相关权限)';
        } else {
          _findRow6.tooltip = "".concat(that.orderRow.sales.nameReviewer, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.sales.auditTime);
        }
      }

      if (that.stepActive >= 7) {
        var _tooltip6 = "".concat(that.orderRow.sales.nameReviewer, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.sales.auditTime);

        var _findRow7 = that.outWarehouseStepList.find(function (item) {
          return item.name === '已审核';
        });

        _findRow7.tooltip = _tooltip6;

        var _findRow8 = that.outWarehouseStepList.find(function (item) {
          return item.name === '已记账';
        });

        if (that.stepActive === 7) {
          _findRow8.tooltip = '在"财务管理/销售审核/销售单审核"操作(需相关权限)';
        } else {
          _findRow8.tooltip = "".concat(that.orderRow.sales.bookkeeper, " \u63D0\u4EA4\u4E8E ").concat(that.orderRow.sales.postingDate);
        }
      }
    }
  }
};