import { request } from '@/api/_service.js';
export function listProduct(params) {
  return request({
    url: '/product/getMainList',
    method: 'get',
    params: params
  });
}
export function findProduct(params) {
  return request({
    url: '/product/findMain',
    method: 'get',
    params: params
  });
}
export function updateProduct(data) {
  return request({
    url: '/product/updateMain',
    method: 'put',
    data: data
  });
}
export function createProduct(data) {
  return request({
    url: '/product/createMain',
    method: 'post',
    data: data
  });
}
export function deleteProduct(data) {
  return request({
    url: '/product/deleteMain',
    method: 'delete',
    data: data
  });
}
export function deleteProductByIds(data) {
  return request({
    url: '/product/deleteMainByIds',
    method: 'delete',
    data: data
  });
}
export function downloadProductTemplate(params) {
  return request({
    url: '/inside/downloadTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 物资及重量模板下载

export function downloadProductTemplate2(params) {
  return request({
    url: '/product/getSpecsTemplate2',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function downloadProductTemplate3(params) {
  return request({
    url: '/product/getTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function importProduct(data) {
  return request({
    url: '/inside/uploadExcel',
    method: 'post',
    data: data
  });
}
export function exportProduct(params) {
  return request({
    url: '/product/exportMainList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 条形码解析

export function barcodeParse(params) {
  return request({
    url: '/product/dipi',
    method: 'get',
    params: params
  });
}
export function listProductCate(params) {
  return request({
    url: '/product_cate/getCateList',
    method: 'get',
    params: params
  });
}
export function createProductCate(data) {
  return request({
    url: '/product_cate/createCate',
    method: 'post',
    data: data
  });
}
export function listStorage() {
  return ['干燥', '阴凉', '严封', '不能冷冻', '避光', '遮光', '通风', '常温', '密封', '密闭', '防潮', '避热', '冷冻', '详见说明书或标签', '湿度<80%', '室温存储', '冷藏', '避免长期暴露在高温下', '-20±5℃', '0-25℃', '0-37℃', '0-40℃', '2-8℃', '2-10℃', '2-30℃', '2-25℃', '4-15℃', '4-30℃', '4-35℃', '10-30℃', '15-25℃'];
}
export function postSpecsList(data) {
  return request({
    url: '/product/postSpecsList',
    method: 'post',
    data: data
  });
}
export function listStrategy(params) {
  return request({
    url: '/strategyErp/getStrategyList',
    method: 'get',
    params: params
  });
}
export function findStrategy(params) {
  return request({
    url: '/strategyErp/findStrategy',
    method: 'get',
    params: params
  });
}
export function createStrategy(data) {
  return request({
    url: '/strategyErp/createStrategy',
    method: 'post',
    data: data
  });
}
export function updateStrategy(data) {
  return request({
    url: '/strategyErp/updateStrategy',
    method: 'put',
    data: data
  });
}
export function deleteStrategy(data) {
  return request({
    url: '/strategyErp/deleteStrategy',
    method: 'delete',
    data: data
  });
}
export function getStrategyPriceBySaleInfo(params) {
  return request({
    url: '/strategyErp/getStrategyPriceBySaleInfo',
    method: 'get',
    params: params
  });
} // 经销商策略详情

export function listStrategyDetail(params) {
  return request({
    url: '/strategyDetailErp/getStrategyDetailList',
    method: 'get',
    params: params
  });
}
export function findStrategyDetail(params) {
  return request({
    url: '/strategyDetailErp/findStrategyDetail',
    method: 'get',
    params: params
  });
}
export function createStrategyDetail(data) {
  return request({
    url: '/strategyDetailErp/createStrategyDetail',
    method: 'post',
    data: data
  });
}
export function updateStrategyDetail(data) {
  return request({
    url: '/strategyDetailErp/updateStrategyDetail',
    method: 'put',
    data: data
  });
}
export function deleteStrategyDetail(data) {
  return request({
    url: '/strategyDetailErp/deleteStrategyDetail',
    method: 'delete',
    data: data
  });
}
export function downloadPolicyDetailTemplate(params) {
  return request({
    url: '/strategyDetailErp/getTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}