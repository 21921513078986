var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menuList" }, [
    _c(
      "div",
      { staticStyle: { height: "32px", "margin-bottom": "10px" } },
      [
        _c("el-input", {
          attrs: {
            placeholder: "输入关键字进行过滤",
            size: "small",
            "suffix-icon": "el-icon-search"
          },
          model: {
            value: _vm.filterText,
            callback: function($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tree-list" },
      [
        _c("el-tree", {
          ref: "hospitalTreeRef",
          attrs: {
            data: _vm.dataList,
            "default-expand-all": true,
            "node-key": "id",
            "highlight-current": true,
            "filter-node-method": _vm.filterNode,
            "expand-on-click-node": false
          },
          on: { "node-click": _vm.treeClick }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }