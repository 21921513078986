var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: _vm.showUpload ? "450px" : "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.showUpload
        ? _c("div", [
            _c(
              "div",
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "drag-upload",
                    attrs: {
                      drag: "",
                      "show-file-list": false,
                      action: "#",
                      "auto-upload": true,
                      accept: _vm.uploadFileType.toString(),
                      "http-request": _vm.toUpload,
                      loading: _vm.uploadLoading,
                      disabled: _vm.uploadLoading
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      !_vm.uploadLoading
                        ? _c("span", [
                            _vm._v("将文件拖到此处，或"),
                            _c("em", [_vm._v("点击上传")])
                          ])
                        : _c("span", [
                            _c("i", { staticClass: "el-icon-loading" }),
                            _vm._v("上传中，请稍候...")
                          ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip"
                      },
                      [
                        _c("span", [
                          _vm._v("注：只能上传EXCEL文件，且不超过10MB")
                        ]),
                        _c(
                          "span",
                          [
                            _c(
                              "el-link",
                              {
                                staticStyle: { margin: "0 10px" },
                                attrs: { type: "primary" },
                                on: { click: _vm.toTemplateDownload }
                              },
                              [_vm._v("点击下载导入模板")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c("p", [_vm._v("注意事项：")]),
            _c("ol", [
              _c("li", [_vm._v("导入功能仅针对新增信息，不覆盖已存在信息")]),
              _c("li", [
                _vm._v("库房、供应商、注册证、产品信息，单位必需提前维护")
              ]),
              _c("li", [_vm._v("“*”为必填项，不可为空 ")])
            ])
          ])
        : _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "detailListTableRef",
                  attrs: {
                    data: _vm.detailList,
                    border: "",
                    "highlight-current-row": "",
                    size: "mini",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    },
                    "row-class-name": _vm.rowClassName
                  },
                  on: { "row-click": _vm.toRowClick }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "50",
                      align: "center",
                      selectable: _vm.selectable,
                      fixed: ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      index: function(index) {
                        return _vm.indexMethod(index, _vm.detailForm)
                      },
                      label: "序号",
                      width: "80",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productNumber",
                      label: "产品编号",
                      width: "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "batchNumber",
                      label: "产品批号",
                      width: "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "trackingCode",
                      label: "序列号",
                      width: "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brandName",
                      label: "品牌",
                      width: "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "物资名称",
                      width: "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "returnWarehouseQuantity",
                      label: "导入数量",
                      width: "100",
                      align: "center",
                      fixed: "right"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "normalQuantity",
                      label: "库存可用数量",
                      width: "100",
                      align: "center",
                      fixed: "right"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specificationModel",
                      label: "规格型号",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unit",
                      label: "单位",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "registrationNumber",
                      label: "注册证号",
                      width: "200",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "afterTaxPrice",
                      label: "成本价格",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "manufacturerDate",
                      label: "生产日期",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "validityDate",
                      label: "有效期",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      align: "center",
                      "page-sizes": [5, 10, 15, 20, 30, 50, 100],
                      total: _vm.detailForm.total,
                      "current-page": _vm.detailForm.page,
                      "page-size": _vm.detailForm.pageSize
                    },
                    on: {
                      "size-change": _vm.toSizeChange,
                      "current-change": _vm.toPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toReturnUpload()
                        }
                      }
                    },
                    [_vm._v("返回上传")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.toConfirmImport()
                        }
                      }
                    },
                    [_vm._v("确认导入")]
                  )
                ],
                1
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }