var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("系统设置")]),
              _c("el-breadcrumb-item", [_vm._v("授权管理")]),
              _c("el-breadcrumb-item", [_vm._v("授权信息")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [
        _c(
          "div",
          { attrs: { align: "center" } },
          [
            _c("el-image", {
              attrs: {
                src:
                  _vm.$baseUrl +
                  "image/theme/" +
                  _vm.themeActiveSetting.name +
                  "/logo/all.png"
              }
            })
          ],
          1
        ),
        _c(
          "el-form",
          {
            staticClass: "form-table",
            attrs: { "label-suffix": ":", "label-width": "150px" }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "组织" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.userInfo.organization_name))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "版本" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.authorization.version))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "并发用户数" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.authorization.concurrentUsers +
                              _vm.authorization.concurrentUsersExt
                          )
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "活跃仓库数" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.authorization.activeRepositories +
                              _vm.authorization.activeRepositoriesExt
                          )
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "归属公司数" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.authorization.companyCount +
                              _vm.authorization.companyCountExt
                          )
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "PAD模块" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _c("i", {
                          class:
                            _vm.authorization.padModule === "1"
                              ? "el-icon-check text-success"
                              : "el-icon-close text-danger"
                        })
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "平台寄售管理模块" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _c("i", {
                            class:
                              _vm.authorization
                                .platformWholesaleManagementModule === "1"
                                ? "el-icon-check text-success"
                                : "el-icon-close text-danger"
                          })
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "授权服务时间" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.authorization.authorizationStartDate) +
                            " 至 " +
                            _vm._s(_vm.authorization.authorizationEndDate)
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }