import { request } from '@/api/_service.js';
/**
 * 获取医院集采设置产品列表
 *
 * @param {Object} params - 请求参数
 * @returns {Promise<any>} - 返回Promise对象，包含请求结果
 */

export function listHospitalCentralized(params) {
  return request({
    url: '/erpHospitalCentralizedErp/getErpHospitalCentralizedList',
    method: 'get',
    params: params
  });
}
export function listHospitalCentralizedExport(params) {
  return request({
    url: '/erpHospitalCentralizedErp/getErpHospitalCentralizedList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function deleteDealerUserAddress(data) {
  return request({
    url: '/dealerUserAddressErp/deleteDealerUserAddressByIds',
    method: 'delete',
    data: data
  });
}
export function createErpHospitalCentralized(data) {
  return request({
    url: '/erpHospitalCentralizedErp/createErpHospitalCentralized',
    method: 'post',
    data: data
  });
}
export function updateErpHospitalCentralized(data) {
  return request({
    url: '/erpHospitalCentralizedErp/updateErpHospitalCentralized',
    method: 'put',
    data: data
  });
}
export function deleteErpHospitalCentralizedByIds(data) {
  return request({
    url: '/erpHospitalCentralizedErp/deleteErpHospitalCentralizedByIds',
    method: 'delete',
    data: data
  });
}
export function updateErpHospitalCentralized2(data) {
  return request({
    url: '/erpHospitalCentralizedErp/updateErpHospitalCentralized2',
    method: 'put',
    data: data
  });
}
export function getErpHospitalCentralizedTemplate(params) {
  return request({
    url: '/erpHospitalCentralizedErp/getErpHospitalCentralizedTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function getCentralizedTemplate4(params) {
  return request({
    url: '/product/getCentralizedTemplate4',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function uploadExcel(data) {
  return request({
    url: '/erpHospitalCentralizedErp/uploadExcel',
    method: 'post',
    data: data
  });
}
export function uploadExcel4(data) {
  return request({
    url: '/product/uploadExcel4',
    method: 'post',
    data: data
  });
}
export function deleteErpHospitalCentralizedBy2(data) {
  return request({
    url: '/erpHospitalCentralizedErp/deleteErpHospitalCentralizedBy2',
    method: 'delete',
    data: data
  });
}