import _objectSpread from "D:/wwwroot/ERP/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/system',
  name: 'system',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'settings/index',
    name: 'system-settings-index',
    component: _import('system/settings/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '系统设置'
    }),
    props: true
  }, {
    path: 'dictionary/index',
    name: 'system-dictionary-index',
    component: _import('system/dictionary/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '字典管理'
    }),
    props: true
  }, {
    path: 'print/index',
    name: 'system-print-index',
    component: _import('system/print/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '打印设置'
    }),
    props: true
  }, {
    path: 'authorize',
    name: 'system-authorize-index',
    component: _import('system/authorize/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '授权信息'
    }),
    props: true
  }, {
    path: 'logs/userlog/index',
    name: 'system-logs-userlog-index',
    component: _import('system/logs/userlog/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '用户日志'
    }),
    props: true
  }, {
    path: 'process/examine',
    name: 'system-process-examine',
    component: _import('system/process/examine/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '审核流程设置'
    }),
    props: true
  }, {
    path: 'process/procure',
    name: 'system-process-procure',
    component: _import('system/process/procure/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购流程设置'
    }),
    props: true
  }, {
    path: 'process/sales',
    name: 'system-process-sales',
    component: _import('system/process/sales/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售流程设置'
    }),
    props: true
  }, {
    path: 'process/payment',
    name: 'system-process-payment',
    component: _import('system/process/payment/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购审批设置'
    }),
    props: true
  }, {
    path: 'financial/warning',
    name: 'system-financial-warning-index',
    component: _import('system/financial/warning/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '应付预警'
    }),
    props: true
  }, {
    path: 'pagesetup/directout',
    name: 'system-pagesetup-directout',
    component: _import('system/pagesetup/directout/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '直销出库单设置'
    }),
    props: true
  }, {
    path: 'logs/online',
    name: 'system-logs-online-index',
    component: _import('system/logs/online/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '在线用户管理'
    }),
    props: true
  }]
};