import _objectSpread from "D:/wwwroot/ERP/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { mapState, mapMutations } from 'vuex';
import hotkeys from 'hotkeys-js';
export default {
  components: {
    'd2-panel-search': function d2PanelSearch() {
      return import('../components/panel-search');
    }
  },
  mounted: function mounted() {// // 绑定搜索功能快捷键 [ 打开 ]
    // hotkeys(this.searchHotkey.open, event => {
    //   event.preventDefault()
    //   this.searchPanelOpen()
    // })
    // // 绑定搜索功能快捷键 [ 关闭 ]
    // hotkeys(this.searchHotkey.close, event => {
    //   event.preventDefault()
    //   this.searchPanelClose()
    // })
  },
  beforeDestroy: function beforeDestroy() {// hotkeys.unbind(this.searchHotkey.open)
    // hotkeys.unbind(this.searchHotkey.close)
  },
  computed: _objectSpread({}, mapState('d2admin', {
    searchActive: function searchActive(state) {
      return state.search.active;
    },
    searchHotkey: function searchHotkey(state) {
      return state.search.hotkey;
    }
  })),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    searchToggle: 'd2admin/search/toggle',
    searchSet: 'd2admin/search/set'
  })), {}, {
    /**
     * 接收点击搜索按钮
     */
    handleSearchClick: function handleSearchClick() {
      var _this = this;

      this.searchToggle();

      if (this.searchActive) {
        setTimeout(function () {
          if (_this.$refs.panelSearch) {
            _this.$refs.panelSearch.focus();
          }
        }, 500);
      }
    },
    searchPanelOpen: function searchPanelOpen() {
      var _this2 = this;

      if (!this.searchActive) {
        this.searchSet(true);
        setTimeout(function () {
          if (_this2.$refs.panelSearch) {
            _this2.$refs.panelSearch.focus();
          }
        }, 500);
      }
    },
    // 关闭搜索面板
    searchPanelClose: function searchPanelClose() {
      if (this.searchActive) {
        this.searchSet(false);
      }
    }
  })
};