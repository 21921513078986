var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", [
        _c(
          "div",
          {
            staticStyle: { display: "flex", "justify-content": "space-between" }
          },
          [
            _c(
              "div",
              [
                _vm._v(" 是否启用: "),
                _c("el-switch", {
                  attrs: {
                    "active-value": 1,
                    "inactive-value": 2,
                    "active-color": "#409EFF",
                    "inactive-color": "#999"
                  },
                  model: {
                    value: _vm.process.status,
                    callback: function($$v) {
                      _vm.$set(_vm.process, "status", $$v)
                    },
                    expression: "process.status"
                  }
                })
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addStep }
              },
              [_vm._v("添加审核步骤")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "cumtom-table" }, [
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { width: "100" } }, [_vm._v("审核内容")]),
                _c("th", [_vm._v(_vm._s(_vm.process.flow_type_name))])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.process.examine_user_list, function(row, idx) {
                return _c("tr", { key: "gu-" + idx }, [
                  _c("td", [_vm._v("审核人" + _vm._s(idx + 1) + "：")]),
                  _c("td", [
                    _c("div", { staticClass: "u-container" }, [
                      _c(
                        "div",
                        { staticClass: "u-list-container" },
                        _vm._l(_vm.process.examine_user_list[idx], function(
                          row1,
                          idx1
                        ) {
                          return _c(
                            "el-tag",
                            {
                              key: "examine_user_list" + idx1,
                              attrs: { closable: "" },
                              on: {
                                close: function($event) {
                                  return _vm.examineUserClose(idx, idx1)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row1.name) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "u-button-container" },
                        [
                          idx === 0
                            ? [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "left-start",
                                      width: "300",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c("SelectUserComponents", {
                                      ref: "SelectExamineUser1",
                                      refInFor: true,
                                      attrs: { idx: idx },
                                      on: { add: _vm.examineUserAdd }
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "primary",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("添加审核人")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "left-start",
                                      width: "300",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c("SelectUserComponents", {
                                      ref: "SelectExamineUser2",
                                      refInFor: true,
                                      attrs: { idx: idx },
                                      on: { add: _vm.examineUserAdd }
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "primary",
                                          size: "mini"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("添加")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeStep(idx)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                        ],
                        2
                      )
                    ])
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          attrs: { align: "center" }
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("提 交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }