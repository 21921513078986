import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
import vueEsign from 'vue-esign';
import util from '@/libs/util.js';
import axios from 'axios';
export default {
  components: {
    vueEsign: vueEsign
  },
  data: function data() {
    return {
      dialogTitle: '签名',
      dialogVisible: false,
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '#eee',
      resultImg: '',
      isCrop: false,
      uploadURL: '',
      uploadToken: ''
    };
  },
  methods: {
    init: function init(imgURL) {
      var that = this;
      that.uploadURL = process.env.VUE_APP_API + '/inside/upload';
      that.uploadToken = {
        headers: {
          Authorization: util.cookies.get('token'),
          Os: 'web',
          PLATFORM: 'Web'
        }
      };
      that.imgURL = imgURL;
      that.resultImg = null;
      that.dialogVisible = true;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    handleReset: function handleReset() {
      var that = this;
      that.$refs.esign.reset(); // 清空画布
    },
    handleGenerate: function handleGenerate() {
      var that = this;
      that.$refs.esign.generate().then(function (res) {
        that.resultImg = res; // 得到了签字生成的base64图片

        var blob = that.dataURLtoBlob(res);
        var tofile = that.blobToFile(blob, '签名.png');
        var formData = new FormData();
        formData.append('file', tofile, tofile.name);
        formData.append('fileType', 9);
        console.log('that.uploadURL', that.uploadURL);
        axios.post(that.uploadURL, formData, that.uploadToken).then(function (res) {
          if (res.data.code !== 0) {
            that.$message.error(res.data.msg);
            that.handleReset();
          } else {
            that.$emit('confirm', res.data.data.file.url);
            that.dialogClose();
          }
        }).catch(function (error) {
          if (error.response) {
            that.$message.error('文件上传失败,' + error.response.data);
          } else if (error.request) {
            that.$message.error('文件上传失败，服务器端无响应');
          } else {
            that.$message.error('文件上传失败，请求封装失败');
          }
        });
      }).catch(function (e) {
        that.$emit('confirm', null);
        that.dialogClose();
      });
    },
    // 将base64转换为blob
    dataURLtoBlob: function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8arr], {
        type: mime
      });
    },
    // 将blob转换为file
    blobToFile: function blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    }
  }
};