import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
export function listClientType() {
  return [{
    ID: 1,
    name: '企业'
  }, {
    ID: 2,
    name: '医院'
  }];
}
export function listConsignState() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 2,
    name: '待发货',
    type: 'primary'
  }, {
    ID: 3,
    name: '部分发货',
    type: 'warning'
  }, {
    ID: 4,
    name: '全部发货',
    type: 'success'
  }, {
    ID: 5,
    name: '作废',
    type: 'danger'
  }, {
    ID: 6,
    name: '结单',
    type: 'success'
  }, {
    ID: 7,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 8,
    name: '暂存',
    type: 'info'
  }, {
    ID: 9,
    name: '驳回',
    type: 'danger'
  }];
}
export function listReportStatus() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '暂存',
    type: 'info'
  }, {
    ID: 2,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 3,
    name: '已报价',
    type: 'success'
  }, {
    ID: 4,
    name: '已记账',
    type: 'success'
  }];
}
export function listGender() {
  return [{
    ID: 0,
    name: '未知'
  }, {
    ID: 1,
    name: '男'
  }, {
    ID: 2,
    name: '女'
  }];
}
export function listPayType() {
  return [{
    ID: 1,
    name: '银行转账'
  }, {
    ID: 2,
    name: '支票'
  }, {
    ID: 3,
    name: '现金'
  }];
}
export function listOptionType() {
  return [{
    ID: 1,
    name: '增加押金申请'
  }, {
    ID: 2,
    name: '退款申请'
  }];
}
export function listCashPledgeStatus() {
  return [{
    ID: 1,
    name: '暂存',
    type: 'info'
  }, {
    ID: 2,
    name: '审核中',
    type: 'warning'
  }, {
    ID: 3,
    name: '驳回',
    type: 'danger'
  }, {
    ID: 4,
    name: '通过',
    type: 'success'
  }];
}
export function listOutWarehouseType() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '直销出库'
  }, {
    ID: 2,
    name: '临调出库'
  }, {
    ID: 3,
    name: '销售出库'
  }, {
    ID: 4,
    name: '有台出库'
  }, {
    ID: 5,
    name: '寄售出库'
  }, {
    ID: 6,
    name: '寄售出库'
  }];
}
export function listOutWarehouseStatus() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '暂存',
    type: 'info'
  }, {
    ID: 2,
    name: '已提交',
    type: 'primary'
  }, {
    ID: 3,
    name: '发货出库',
    type: 'danger'
  }, {
    ID: 4,
    name: '已复核',
    type: 'warning'
  }, {
    ID: 5,
    name: '作废',
    type: 'danger'
  }, {
    ID: 6,
    name: '已出库',
    type: 'warning'
  }, {
    ID: 7,
    name: '已报价',
    type: 'success'
  }, {
    ID: 8,
    name: '已审核',
    type: 'success'
  }, {
    ID: 9,
    name: '已记账',
    type: 'success'
  }, {
    ID: 10,
    name: '已回库',
    type: 'success'
  }, {
    ID: 11,
    name: '已提交（被驳回）',
    type: 'danger'
  }];
}
export function listNotMovingRange() {
  return [{
    ID: 1,
    name: '1个月'
  }, {
    ID: 2,
    name: '2个月'
  }, {
    ID: 3,
    name: '3个月'
  }, {
    ID: 4,
    name: '4个月'
  }, {
    ID: 5,
    name: '5个月'
  }, {
    ID: 6,
    name: '6个月'
  }, {
    ID: 7,
    name: '7个月'
  }, {
    ID: 8,
    name: '8个月'
  }, {
    ID: 9,
    name: '9个月'
  }, {
    ID: 10,
    name: '10个月'
  }, {
    ID: 11,
    name: '11个月'
  }, {
    ID: 12,
    name: '12个月及以上'
  }];
}
export function listAgentReturnStatus() {
  return [{
    ID: null,
    name: '全部',
    type: 'default'
  }, {
    ID: -1,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 1,
    name: '暂存',
    type: 'default'
  }, {
    ID: 2,
    name: '已提交',
    type: 'primary'
  }, {
    ID: 3,
    name: '已审核',
    type: 'warning'
  }, {
    ID: 4,
    name: '已记账',
    type: 'success'
  }, {
    ID: -2,
    name: '驳回',
    type: 'danger'
  }];
}
export function listAgentReturnSourceType() {
  return [{
    ID: null,
    name: '全部',
    type: 'default'
  }, {
    ID: 1,
    name: 'ERP',
    type: 'primary'
  }, {
    ID: 2,
    name: '寄售平台',
    type: 'warning'
  }];
} // 回款方式

export function listRepaymentType() {
  return [{
    ID: 1,
    name: '银行转账'
  }, {
    ID: 2,
    name: '现金'
  }, {
    ID: 3,
    name: '支票'
  }, {
    ID: 4,
    name: '货款押金抵扣'
  }, {
    ID: 5,
    name: '工具押金抵扣'
  }];
} // 回款状态

export function listPaymentReturnStatus() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '未审核',
    type: 'primary'
  }, {
    ID: 2,
    name: '已审核',
    type: 'success'
  }, {
    ID: 3,
    name: '审核未通过',
    type: 'danger'
  }];
} // 审核状态

export function listPaymentCheckStatus() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 2,
    name: '已审核',
    type: 'success'
  }, {
    ID: 3,
    name: '驳回',
    type: 'danger'
  }];
} // 发票类型

export function listInvoiceType() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '普票',
    type: 'info'
  }, {
    ID: 2,
    name: '专票',
    type: 'info'
  }, {
    ID: 3,
    name: '电子普票',
    type: 'primary'
  }, {
    ID: 4,
    name: '电子专票',
    type: 'primary'
  }];
} // 销项发票 > 发票状态

export function listInvoiceStatus() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '正常',
    type: 'primary'
  }, {
    ID: 2,
    name: '全部红冲',
    type: 'danger'
  }, {
    ID: 3,
    name: '部分冲红',
    type: 'danger'
  }, {
    ID: 4,
    name: '红字发票',
    type: 'danger'
  }, {
    ID: 5,
    name: '作废',
    type: 'danger'
  }];
} // 回款状态-销项发票管理

export function listRepaymentStatus() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '未回款',
    type: 'info'
  }, {
    ID: 2,
    name: '部分回款',
    type: 'primary'
  }, {
    ID: 3,
    name: '全部回款',
    type: 'success'
  }, {
    ID: 4,
    name: '已红冲',
    type: 'danger'
  }, {
    ID: 5,
    name: '红字发票',
    type: 'danger'
  }, {
    ID: 6,
    name: '作废',
    type: 'danger'
  }];
} // 回款超期状态

export function listRepaymentOverdueStatus() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '正常',
    type: 'primary'
  }, {
    ID: 2,
    name: '超期',
    type: 'warning'
  }];
} // 销售核销日期查询类型

export function listSalesClearanceType() {
  return [{
    ID: 1,
    name: '销售日期',
    type: 'primary'
  }, {
    ID: 2,
    name: '手术日期',
    type: 'primary'
  }, {
    ID: 3,
    name: '核销日期',
    type: 'warning'
  }];
} // 核销列表时间类型

export function listSalesTimeType() {
  return [{
    ID: 2,
    name: '销售日期',
    type: 'info'
  }, {
    ID: 1,
    name: '手术日期',
    type: ''
  }, {
    ID: 6,
    name: '核销日期',
    type: 'primary'
  }];
} // 开票情况

export function listInvoiceSituation() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 0,
    name: '未开票',
    type: 'danger'
  }, {
    ID: 1,
    name: '未开票',
    type: 'danger'
  }, {
    ID: 2,
    name: '部分开票',
    type: 'warning'
  }, {
    ID: 3,
    name: '全部开票',
    type: 'success'
  }];
} // 回款情况

export function listReceiveStatus() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '未回款',
    type: 'info'
  }, {
    ID: 2,
    name: '部分回款',
    type: 'warning'
  }, {
    ID: 3,
    name: '全部回款',
    type: 'success'
  }];
} // 核销情况

export function listVerificationStatus() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '未核销',
    type: 'danger'
  }, {
    ID: 2,
    name: '已核销',
    type: 'success'
  }];
} // 销售单类型

export function listSalesType() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '直销销售单',
    type: 'info'
  }, {
    ID: 2,
    name: '临调销售单',
    type: 'primary'
  }, {
    ID: 3,
    name: '销售出库销售单',
    type: 'primary'
  }, {
    ID: 4,
    name: '有台销售单',
    type: 'primary'
  }, {
    ID: 6,
    name: '寄售销售单',
    type: 'primary'
  }];
} // 开票申请记录/状态

export function listInvoiceRecordStatus() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '已申请',
    type: 'primary'
  }, {
    ID: 2,
    name: '部分开票',
    type: 'warning'
  }, {
    ID: 3,
    name: '已开票',
    type: 'success'
  }];
} // 回款管理/关联状态

export function listSsociatedInvoice() {
  return [{
    ID: null,
    name: '全部',
    type: ''
  }, {
    ID: 1,
    name: '未关联',
    type: 'info'
  }, {
    ID: 2,
    name: '部分关联',
    type: 'warning'
  }, {
    ID: 3,
    name: '已关联',
    type: 'success'
  }];
}
export function listOutWarehouseStep() {
  return ['暂存', '已提交', '已复核', '发货出库', '已回库', '已报价', '已审核', '已记账'];
}
export function listDocumentStatus() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '暂存',
    type: 'info'
  }, {
    ID: 2,
    name: '已提交',
    type: 'primary'
  }, {
    ID: 3,
    name: '已审核',
    type: 'warning'
  }, {
    ID: 4,
    name: '已记账',
    type: 'success'
  }, {
    ID: 6,
    name: '已作废',
    type: 'danger'
  }];
}
export function listDocumentStatusCheck() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 2,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 3,
    name: '已审核',
    type: 'warning'
  }, {
    ID: 4,
    name: '已记账',
    type: 'success'
  }, {
    ID: 6,
    name: '已作废',
    type: 'danger'
  }];
}
export function listInventoryType() {
  return [{
    ID: 1,
    name: '按规格'
  }, {
    ID: 2,
    name: '按批号'
  }, {
    ID: 3,
    name: '按序列号'
  }];
}
export function listWeatherTimeType() {
  return [{
    ID: 1,
    name: '上午'
  }, {
    ID: 2,
    name: '下午'
  }];
}
export function listSettleType() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 0,
    name: '待结算'
  }, {
    ID: 1,
    name: '已结算'
  }];
}
export function listSettleTypeGroup() {
  return [{
    ID: 3,
    name: '销售提成'
  }, {
    ID: 4,
    name: '跟台提成'
  }, {
    ID: 5,
    name: '配货出库提成'
  }, {
    ID: 6,
    name: '配送提成'
  }, {
    ID: 7,
    name: '回库核对提成'
  }, {
    ID: 8,
    name: '财务提成'
  }, {
    ID: 9,
    name: '出库核对提成'
  }, {
    ID: 10,
    name: '验货提成'
  }, {
    ID: 11,
    name: '包包消毒提成'
  }, {
    ID: 12,
    name: '术后清洗提成'
  }, {
    ID: 13,
    name: '回库上架提成'
  }, {
    ID: 14,
    name: '术前清洗提成'
  }];
}
export function listSettleTaxTypeList() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '按个人所得税'
  }, {
    ID: 2,
    name: '不计税'
  }];
} // 有台提成列表中使用的角色列表

export function listSettleGroup() {
  return [{
    ID: 4,
    name: '跟台员'
  }, {
    ID: 5,
    name: '库管员'
  }, {
    ID: 6,
    name: '配送员'
  }];
}
export function listYoutaiUserGroup() {
  return [{
    ID: 0,
    name: '普通用户'
  }, {
    ID: 1,
    name: '超级管理员'
  }, {
    ID: 2,
    name: '管理员'
  }, {
    ID: 3,
    name: '销售'
  }, {
    ID: 4,
    name: '跟台员'
  }, {
    ID: 5,
    name: '库管员'
  }, {
    ID: 6,
    name: '配送员'
  }, {
    ID: 7,
    name: '财务'
  }, {
    ID: 8,
    name: '公司负责人'
  }, {
    ID: 9,
    name: '数据员'
  }, {
    ID: 10,
    name: '报台员'
  }, {
    ID: 11,
    name: '审核员'
  }, {
    ID: 12,
    name: '复核员'
  }];
}
export function listLendType() {
  return [{
    ID: 1,
    name: '购买'
  }, {
    ID: 2,
    name: '出售'
  }];
}
export function listInventoryModifyStatus() {
  return [{
    ID: 1,
    name: '暂存',
    type: 'info'
  }, {
    ID: 2,
    name: '已提交',
    type: 'primary'
  }, {
    ID: 3,
    name: '已审核',
    type: 'warning'
  }, {
    ID: 4,
    name: '已记账',
    type: 'success'
  }, {
    ID: 5,
    name: '被驳回',
    type: 'danger'
  }, {
    ID: 6,
    name: '已作废',
    type: 'danger'
  }];
}
export function listPercentageType() {
  return [{
    ID: 1,
    name: '固定金额'
  }, {
    ID: 2,
    name: '固定百分比'
  }, {
    ID: 3,
    name: '自定义金额'
  }, {
    ID: 4,
    name: '按销售的每个产品去匹配并合计奖励'
  }, {
    ID: 5,
    name: '按标签提成'
  }, {
    ID: 6,
    name: '按配送重量规则'
  }];
} // 是否集采类耗材1=全部非集采  2=全部集采  3=按产品主数据

export function listCentralizedType() {
  return [{
    ID: 3,
    name: '按产品主数据'
  }, {
    ID: 2,
    name: '全部集采'
  }, {
    ID: 1,
    name: '全部非集采'
  }];
}
export function listOrgInWarehouseType() {
  return [{
    ID: 1,
    name: '采购入库'
  }, {
    ID: 2,
    name: '期初入库'
  }, {
    ID: 5,
    name: '盘存入库'
  }, {
    ID: 6,
    name: '成品入库'
  }, {
    ID: 7,
    name: '寄售入库'
  }];
}
export function listPurchaseOrderType() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '采购订单',
    type: 'success'
  }, {
    ID: 2,
    name: '寄售订购订单',
    type: 'primary'
  }];
}
export function listExamineStatus() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '待审批',
    type: 'primary'
  }, {
    ID: 2,
    name: '审批未通过',
    type: 'danger'
  }, {
    ID: 3,
    name: '已审批',
    type: 'success'
  }];
}
export function listReturnStatus() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '无退货',
    type: 'success'
  }, {
    ID: 2,
    name: '部分退货',
    type: 'warning'
  }, {
    ID: 3,
    name: '全部退货',
    type: 'danger'
  }];
}
export function dictFormatter(val, valList) {
  var findRow = valList.find(function (item) {
    return item.ID === val || item.name === val;
  });

  if (findRow) {
    return findRow;
  } else {
    return {
      type: '',
      name: '',
      ID: val
    };
  }
}
export function dictFormatterTag(val, valList) {
  var findRow = valList.find(function (item) {
    return item.ID === val || item.name === val;
  });

  if (findRow) {
    return '<a class="el-link el-link--' + findRow.type + '"><span class="el-link--inner">' + findRow.name + '</span></a>';
  }
}