import { render, staticRenderFns } from "./from.vue?vue&type=template&id=14cbf1f6&scoped=true&"
import script from "./from.vue?vue&type=script&lang=js&"
export * from "./from.vue?vue&type=script&lang=js&"
import style0 from "./from.vue?vue&type=style&index=0&id=14cbf1f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14cbf1f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\ERP\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14cbf1f6')) {
      api.createRecord('14cbf1f6', component.options)
    } else {
      api.reload('14cbf1f6', component.options)
    }
    module.hot.accept("./from.vue?vue&type=template&id=14cbf1f6&scoped=true&", function () {
      api.rerender('14cbf1f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/stock/detail/from.vue"
export default component.exports