var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("有台管理")]),
              _c("el-breadcrumb-item", [_vm._v("提成明细")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "订单创建时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.searchForm.createDate,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "createDate", $$v)
                        },
                        expression: "searchForm.createDate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "手术时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.searchForm.surgeryDate,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "surgeryDate", $$v)
                        },
                        expression: "searchForm.surgeryDate"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "订单编号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入订单编号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.orderNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "orderNumber", $$v)
                            },
                            expression: "searchForm.orderNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "结算状态" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "150px" },
                        model: {
                          value: _vm.searchForm.settleStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "settleStatus", $$v)
                          },
                          expression: "searchForm.settleStatus"
                        }
                      },
                      _vm._l(_vm.settleTypeList, function(item, index) {
                        return _c("el-option", {
                          key: "settleStatus" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "提成计算规则" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        model: {
                          value: _vm.searchForm.settleTaxType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "settleTaxType", $$v)
                          },
                          expression: "searchForm.settleTaxType"
                        }
                      },
                      _vm._l(_vm.settleTaxTypeList, function(item, index) {
                        return _c("el-option", {
                          key: "settleTaxType" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "支付通道" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "支付通道"
                        },
                        model: {
                          value: _vm.searchForm["payChannelType[]"],
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "payChannelType[]", $$v)
                          },
                          expression: "searchForm['payChannelType[]']"
                        }
                      },
                      _vm._l(_vm.payChannelTypeList, function(item, index) {
                        return _c("el-option", {
                          key: "payChannelType" + index,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "仓库" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "仓库"
                        },
                        model: {
                          value: _vm.searchForm["warehouseId[]"],
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseId[]", $$v)
                          },
                          expression: "searchForm['warehouseId[]']"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item, index) {
                        return _c("el-option", {
                          key: "warehouseId" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "医院" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "医院"
                        },
                        model: {
                          value: _vm.searchForm["hospitalId[]"],
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "hospitalId[]", $$v)
                          },
                          expression: "searchForm['hospitalId[]']"
                        }
                      },
                      _vm._l(_vm.hospitalList, function(item, index) {
                        return _c("el-option", {
                          key: "hospitalId" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "归属公司" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "归属公司"
                        },
                        model: {
                          value: _vm.searchForm["orgOrganizationId[]"],
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "orgOrganizationId[]", $$v)
                          },
                          expression: "searchForm['orgOrganizationId[]']"
                        }
                      },
                      _vm._l(_vm.organizationList, function(item, index) {
                        return _c("el-option", {
                          key: "orgOrganizationId" + index,
                          attrs: { label: item.companyName, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "提成类型" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "提成类型"
                        },
                        model: {
                          value: _vm.searchForm["groupId[]"],
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "groupId[]", $$v)
                          },
                          expression: "searchForm['groupId[]']"
                        }
                      },
                      _vm._l(_vm.groupList, function(item, index) {
                        return _c("el-option", {
                          key: "groupId" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "用户" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "用户"
                        },
                        model: {
                          value: _vm.searchForm["uids[]"],
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "uids[]", $$v)
                          },
                          expression: "searchForm['uids[]']"
                        }
                      },
                      _vm._l(_vm.userList, function(item, index) {
                        return _c("el-option", {
                          key: "uids" + index,
                          attrs: { label: item.name, value: item.uid }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "订单过滤" }
                  },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 1, "false-label": 0 },
                        model: {
                          value: _vm.searchForm.reverseStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "reverseStatus", $$v)
                          },
                          expression: "searchForm.reverseStatus"
                        }
                      },
                      [_vm._v("过滤已取消、反向订单")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticStyle: { "margin-right": "10px" },
                        on: { change: _vm.searchFormSubmit },
                        model: {
                          value: _vm.searchForm.settleType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "settleType", $$v)
                          },
                          expression: "searchForm.settleType"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("明细")
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("汇总")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport2 } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: { "font-size": "14px", "margin-bottom": "10px" },
            attrs: { align: "right" }
          },
          [
            _c("span", [
              _vm._v("订单数："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(_vm._s(_vm.orderTatal))
              ])
            ]),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("提成笔数："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(_vm._s(_vm.listTotal))
              ])
            ]),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("提成人数："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(_vm._s(_vm.userTatal))
              ])
            ]),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("订单合计："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(_vm._s(_vm.saleAmountTotal))
              ])
            ]),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("提成合计："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(_vm._s(_vm.settleAmountTotal))
              ])
            ]),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("提成比例："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(_vm._s(_vm.settleTotalPercentage) + "%")
              ])
            ])
          ]
        ),
        _vm.searchForm.settleType !== 2
          ? _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.orderLoading,
                  expression: "orderLoading"
                }
              ],
              key: "listTable" + _vm.timestamp,
              ref: "listTableRef",
              attrs: {
                hasIndex: true,
                hasPagination: true,
                pagination: _vm.searchForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                data: _vm.dataList
              },
              on: {
                pageChange: _vm.pageChange,
                sizeChange: _vm.sizeChange,
                "row-dblclick": _vm.rowDblclick
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "uname",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.uname) +
                            " / " +
                            _vm._s(row.groupName) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "settleTaxType",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.settleTaxType == 1
                          ? _c(
                              "el-link",
                              { attrs: { type: "success", underline: false } },
                              [_vm._v("按个人所得税")]
                            )
                          : _vm._e(),
                        row.settleTaxType == 2
                          ? _c(
                              "el-link",
                              { attrs: { type: "info", underline: false } },
                              [_vm._v("不计税")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                181463836
              )
            })
          : _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.orderLoading,
                  expression: "orderLoading"
                }
              ],
              key: "listSummaryTable" + _vm.timestamp,
              ref: "listSummaryTableRef",
              attrs: {
                hasIndex: true,
                hasPagination: true,
                pagination: _vm.searchForm,
                "route-name": _vm.$route.name + "summary",
                columns: _vm.summaryColumns,
                data: _vm.dataList
              },
              on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange }
            }),
        _c("AmountComponent", {
          ref: "AmountComponentRef",
          on: { reload: _vm.loadData }
        })
      ],
      _c("ExportComponent", { ref: "ExportComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }