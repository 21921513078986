var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.importStatus,
              expression: "!importStatus"
            }
          ]
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { display: "flex" }
            },
            [
              _c(
                "el-upload",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "flex-start"
                  },
                  attrs: {
                    "show-file-list": false,
                    "auto-upload": true,
                    action: _vm.uploadURL,
                    headers: _vm.uploadToken,
                    accept: _vm.uploadFileType.toString(),
                    "http-request": _vm.myUpload,
                    loading: _vm.loading,
                    disabled: _vm.loading
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        loading: _vm.loading
                      }
                    },
                    [_vm._v("点击导入")]
                  )
                ],
                1
              ),
              _c(
                "el-link",
                {
                  staticStyle: { margin: "0 10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.templateDownload }
                },
                [_vm._v("模板下载")]
              )
            ],
            1
          ),
          _vm.errorMsg
            ? _c("div", { staticStyle: { margin: "10px 0", color: "#f00" } }, [
                _vm._v(_vm._s(_vm.errorMsg))
              ])
            : _vm._e(),
          _vm.successMsg
            ? _c(
                "div",
                {
                  staticStyle: { margin: "10px 0", color: "rgb(103, 194, 58)" }
                },
                [_vm._v(_vm._s(_vm.successMsg))]
              )
            : _vm._e(),
          _c("p", [_vm._v("注意事项：")]),
          _c("ol", [
            _c("li", [_vm._v("导入功能仅针对新增信息，不覆盖已存在信息")]),
            _c("li", [
              _vm._v("库房、供应商、注册证、产品信息，单位必需提前维护")
            ]),
            _c("li", [_vm._v("“*”为必填项，不可为空 ")])
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.importStatus,
              expression: "importStatus"
            }
          ]
        },
        [
          _c("div", [_vm._v("数据验证结果：")]),
          _c("div", [
            _c("ul", [
              _c("li", [
                _vm._v("EXCEL文件中共有"),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.totalExcelRows))
                ]),
                _vm._v("条记录，总共需要导入数量为"),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.totalImport))
                ])
              ]),
              _c("li", [
                _vm._v("其中有问题的记录为"),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.totalExcelRowsErr))
                ]),
                _vm._v("条，总共可导入数量为"),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.totalCanImport))
                ])
              ])
            ])
          ]),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.importData,
                size: "small",
                border: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                "row-class-name": _vm.rowClassName
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "barCode",
                  label: "主码",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  label: "物资名称",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specificationModel",
                  label: "规格型号",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "batchNumber",
                  label: "批号",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "trackingCode",
                  label: "序列号",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "importQuantity",
                  label: "导入数量",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "normalQuantity",
                  label: "库存可用数量",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "statusName",
                  label: "状态",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "statusText",
                  label: "是否可以导入",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              })
            ],
            1
          ),
          _vm.importData.filter(function(item) {
            return !item.status
          }).length > 0
            ? _c(
                "div",
                {
                  staticClass: "text-danger",
                  staticStyle: { margin: "10px 0" }
                },
                [
                  _vm._v(
                    " 注意：如果依然执行导入，则只能导入有效的记录数据，其他有问题的数据将不会导入到耗材包。 "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { align: "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.importConfirm }
                },
                [_vm._v("执行导入")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.toReturn()
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }