var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "align-items": "center" } },
    [
      _c("div", { staticStyle: { width: "450px" } }, [
        _c("input", {
          ref: "scanInwarehouseInputBoxRef",
          staticClass: "bar-input",
          attrs: { placeholder: "请扫描产品条码", clearable: "" },
          on: { change: _vm.barCodeSearch }
        })
      ]),
      _c(
        "div",
        { staticStyle: { color: "#f00", flex: "1", "margin-left": "20px" } },
        [_vm._v(_vm._s(_vm.tipMessage))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }