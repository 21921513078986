var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.popoverVisible
        ? _c(
            "el-popover",
            {
              ref: "popoverRef",
              staticStyle: { position: "absolute" },
              attrs: {
                placement: "bottom-start",
                reference: _vm.popoverReference,
                "append-to-body": false,
                trigger: "click"
              }
            },
            [
              _c(
                "div",
                { staticClass: "dragHeaderTable" },
                _vm._l(_vm.columns, function(item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !item.hidden,
                          expression: "!item.hidden"
                        }
                      ],
                      key: "header" + index + _vm.timeStamp,
                      staticClass: "item"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            width: "18px",
                            height: "100%",
                            "text-align": "left",
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c("d2-icon-svg", {
                            staticClass: "move",
                            staticStyle: {
                              width: "18px",
                              height: "18px",
                              fill: "#666"
                            },
                            attrs: { name: "ic_drag" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: item.disabled },
                          on: { change: _vm.saveTableSet },
                          model: {
                            value: item.checked,
                            callback: function($$v) {
                              _vm.$set(item, "checked", $$v)
                            },
                            expression: "item.checked"
                          }
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _c("VeTable", {
        attrs: {
          "scroll-width": 0,
          "border-y": "",
          "table-data": _vm.tableData,
          columns: _vm.columns,
          "cell-style-option": _vm.cellStyleOption,
          columnHiddenOption: _vm.columnHiddenOption
        }
      }),
      _vm.hasPagination
        ? _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  align: "center",
                  "page-sizes": [5, 10, 15, 20, 30, 50, 100],
                  total: _vm.pagination.total,
                  "current-page": _vm.pagination.page,
                  "page-size": _vm.pagination.pageSize
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.pageChange
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }