var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("退货审核")]),
              _c("el-breadcrumb-item", [_vm._v("退货单详情")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              size: "mini",
              "label-width": "120px",
              "label-suffix": ":",
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "仓库" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.warehouseName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "退货单位" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.manufacturerName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "业务部门" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.departmentName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "退回原因" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.returnReasonName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "快递单号" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.deliveryNumber))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "备注" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.remark))
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailListTableRef",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            hasSelection: false,
            hasIndex: true,
            hasPagination: true,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name + "detail-list",
            columns: _vm.detailColumns,
            data: _vm.detailList
          },
          on: {
            pageChange: _vm.detailFormPageChange,
            sizeChange: _vm.detailFormSizeChange
          }
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-breadcrumb",
            {
              staticStyle: {
                "font-size": "12px",
                "margin-bottom": "10px",
                width: "100%"
              },
              attrs: { separator: " " }
            },
            [
              _c("el-breadcrumb-item", [
                _vm._v("制单人：" + _vm._s(_vm.form.returnName))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("制单时间：" + _vm._s(_vm.form.CreatedAt))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("审核人：" + _vm._s(_vm.form.nameReviewer))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("审核时间：" + _vm._s(_vm.form.examineTime))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("记账人：" + _vm._s(_vm.form.accountName))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("记账时间：" + _vm._s(_vm.form.accountTime))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("退货总数量：" + _vm._s(_vm.totalQuantity))
              ])
            ],
            1
          ),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status == 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            disabled: !(_vm.form.id && _vm.form.status == 2)
                          },
                          on: {
                            click: function($event) {
                              return _vm.checkLoading(_vm.formCheck)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" 审核")
                        ]
                      )
                    : _vm._e(),
                  _vm.form.status == 3
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: {
                            type: "danger",
                            disabled: !(_vm.form.id && _vm.form.status == 3)
                          },
                          on: { click: _vm.formUnCheck }
                        },
                        [
                          _c("i", { staticClass: "fa el-icon-refresh-left" }),
                          _vm._v(" 撤消审核")
                        ]
                      )
                    : _vm._e(),
                  _vm.form.status == 3
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            disabled: !(_vm.form.id && _vm.form.status == 3)
                          },
                          on: { click: _vm.formAccount }
                        },
                        [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" 记账")
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.detailPrint }
                    },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("CheckComponent", {
        ref: "CheckComponentRef",
        on: { confirm: _vm.checkConfirm }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }