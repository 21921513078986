var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存信息变更审核")]),
              _c("el-breadcrumb-item", [_vm._v("变更单详情")])
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "font-size": "14px", color: "#606266" } },
            [
              _vm.orderInfo.orderNumber
                ? _c("span", [
                    _vm._v("变更单单号：" + _vm._s(_vm.orderInfo.orderNumber))
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading"
              }
            ],
            ref: "form",
            staticClass: "form-table",
            attrs: {
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              "label-suffix": ":"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "仓库" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.faWarehouse.name))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "原归属公司" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.faSubsidiary.companyName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "供应商" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.faManufacturer.name))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "修改原因" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.reasons))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "现归属公司" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.faSubsidiary2.companyName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "备注" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.remark))
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailTableRef",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            hasIndex: true,
            hasPagination: true,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name + "detail-list",
            columns: _vm.detailColumns,
            data: _vm.detailForm.data.slice(
              (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
              _vm.detailForm.page * _vm.detailForm.pageSize
            )
          },
          on: {
            "row-click": _vm.detailRowClick,
            pageChange: _vm.detailPageChange,
            sizeChange: _vm.detailSizeChange
          },
          scopedSlots: _vm._u([
            {
              key: "normalQuantity",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "el-input-numbers" }, [
                    _c("div", [
                      _vm._v("可修改数量上限:" + _vm._s(row.normalQuantity))
                    ]),
                    _c(
                      "div",
                      [
                        _vm._v("改:"),
                        _c("el-input-number", {
                          class:
                            row.normalQuantity !== row.returnWarehouseQuantity
                              ? "changed-bg"
                              : "",
                          attrs: {
                            controls: false,
                            size: "mini",
                            disabled: true
                          },
                          model: {
                            value: row.returnWarehouseQuantity,
                            callback: function($$v) {
                              _vm.$set(row, "returnWarehouseQuantity", $$v)
                            },
                            expression: "row.returnWarehouseQuantity"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              }
            },
            {
              key: "returnPrice",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "el-input-numbers" }, [
                    _c("div", [_vm._v("原值:" + _vm._s(row.afterTaxPrice))]),
                    _c(
                      "div",
                      [
                        _vm._v("改:"),
                        _c("el-input-number", {
                          class:
                            row.afterTaxPrice !== row.returnPrice
                              ? "changed-bg"
                              : "",
                          attrs: {
                            controls: false,
                            size: "mini",
                            disabled: true
                          },
                          model: {
                            value: row.returnPrice,
                            callback: function($$v) {
                              _vm.$set(row, "returnPrice", $$v)
                            },
                            expression: "row.returnPrice"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              }
            },
            {
              key: "taxRate",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "el-input-numbers" }, [
                    _c("div", [_vm._v("原值:" + _vm._s(row.taxRate2) + "%")]),
                    _c(
                      "div",
                      [
                        _vm._v("改:"),
                        _c("el-input-number", {
                          class:
                            row.taxRate2 !== row.taxRate ? "changed-bg" : "",
                          attrs: {
                            controls: false,
                            size: "mini",
                            disabled: true
                          },
                          model: {
                            value: row.taxRate,
                            callback: function($$v) {
                              _vm.$set(row, "taxRate", $$v)
                            },
                            expression: "row.taxRate"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              }
            },
            {
              key: "batchNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "el-input-numbers" }, [
                    _c("div", [_vm._v("原值:" + _vm._s(row.batchNumber2))]),
                    _c(
                      "div",
                      [
                        _vm._v("改:"),
                        _c("el-input", {
                          class:
                            row.batchNumber2 !== row.batchNumber
                              ? "changed-bg"
                              : "",
                          attrs: { size: "mini", disabled: true },
                          model: {
                            value: row.batchNumber,
                            callback: function($$v) {
                              _vm.$set(row, "batchNumber", $$v)
                            },
                            expression: "row.batchNumber"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              }
            },
            {
              key: "trackingCode",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "el-input-numbers" }, [
                    _c("div", [_vm._v("原值:" + _vm._s(row.trackingCode2))]),
                    _c(
                      "div",
                      [
                        _vm._v("改:"),
                        _c("el-input", {
                          class:
                            row.trackingCode2 !== row.trackingCode
                              ? "changed-bg"
                              : "",
                          attrs: { size: "mini", disabled: true },
                          model: {
                            value: row.trackingCode,
                            callback: function($$v) {
                              _vm.$set(row, "trackingCode", $$v)
                            },
                            expression: "row.trackingCode"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              }
            },
            {
              key: "manufacturerDate",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "el-input-numbers" }, [
                    _c("div", [
                      _vm._v("原值:" + _vm._s(row.manufacturerDate2))
                    ]),
                    _c(
                      "div",
                      [
                        _vm._v("改:"),
                        _c("el-date-picker", {
                          class:
                            row.manufacturerDate !== row.manufacturerDate2
                              ? "changed-bg"
                              : "",
                          attrs: {
                            size: "mini",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "现生产日期",
                            disabled: true
                          },
                          model: {
                            value: row.manufacturerDate,
                            callback: function($$v) {
                              _vm.$set(row, "manufacturerDate", $$v)
                            },
                            expression: "row.manufacturerDate"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              }
            },
            {
              key: "validityDate",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "el-input-numbers" }, [
                    _c("div", [_vm._v("原值:" + _vm._s(row.validityDate2))]),
                    _c(
                      "div",
                      [
                        _vm._v("改:"),
                        _c("el-date-picker", {
                          class:
                            row.validityDate !== row.validityDate2
                              ? "changed-bg"
                              : "",
                          attrs: {
                            size: "mini",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "现有效期",
                            disabled: true
                          },
                          model: {
                            value: row.validityDate,
                            callback: function($$v) {
                              _vm.$set(row, "validityDate", $$v)
                            },
                            expression: "row.validityDate"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              }
            },
            {
              key: "certId",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticClass: "el-input-numbers" }, [
                    _c("div", [
                      _vm._v("原值:" + _vm._s(row.registrationNumber2))
                    ]),
                    _c(
                      "div",
                      [
                        _vm._v("改:"),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              filterable: "",
                              clearable: "",
                              disabled: true
                            },
                            model: {
                              value: row.certId,
                              callback: function($$v) {
                                _vm.$set(row, "certId", $$v)
                              },
                              expression: "row.certId"
                            }
                          },
                          _vm._l(row.certs, function(item, index) {
                            return _c("el-option", {
                              key: "certId" + index,
                              attrs: {
                                label: item.cert.regNoCn,
                                value: item.certId
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              }
            }
          ])
        })
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.orderInfo.status === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "examine-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toExamine()
                              }
                            }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "un-examine-button",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.toUnExamine()
                              }
                            }
                          },
                          [_vm._v("撤销审核")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "account-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toAccount()
                              }
                            }
                          },
                          [_vm._v("记账")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        key: "print-button",
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.toOrderPrint()
                          }
                        }
                      },
                      [_vm._v("打印变更单")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("ExamineComponent", {
        ref: "ExamineComponentRef",
        on: { confirm: _vm.examineConfirm }
      }),
      _c("PrintComponent", { ref: "PrintComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }