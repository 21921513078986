var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("入库管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售退货")]),
              _c("el-breadcrumb-item", [_vm._v("寄售退货单详情")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "show-message": false,
              loading: _vm.formLoading,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.ID,
                                attrs: {
                                  label: item.name,
                                  value: item.ID,
                                  disabled:
                                    item.status === 0 || item.isActive === 2
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.status === 0 ? true : false,
                                            expression:
                                              "item.status===0?true:false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(无效)")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.isActive === 2
                                                ? true
                                                : false,
                                            expression:
                                              "item.isActive===2?true:false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(未激活)")]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货单位", prop: "manufacturerId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0
                            },
                            model: {
                              value: _vm.form.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturerId", $$v)
                              },
                              expression: "form.manufacturerId"
                            }
                          },
                          _vm._l(_vm.supplierList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务部门", prop: "departmentId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.departmentId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "departmentId", $$v)
                              },
                              expression: "form.departmentId"
                            }
                          },
                          _vm._l(_vm.departmentList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.departmentName,
                                value: item.ID
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退回原因", prop: "returnReason" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.form.returnReason,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "returnReason", $$v)
                              },
                              expression: "form.returnReason"
                            }
                          },
                          _vm._l(_vm.returnReasonList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.codeText, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "快递单号", prop: "deliveryNumber" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { maxlength: "50" },
                            model: {
                              value: _vm.form.deliveryNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deliveryNumber", $$v)
                              },
                              expression: "form.deliveryNumber"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { maxlength: "150", clearable: "" },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0",
              "font-size": "14px"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.selectProduct }
                  },
                  [_vm._v("增加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.importProduct }
                  },
                  [_vm._v("模板导入")]
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._v(" 退货数量合计：（"),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.countQuantity()))
                ]),
                _vm._v("） "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: !_vm.form.id
                    },
                    on: { click: _vm.orderPrint }
                  },
                  [
                    _c("i", { staticClass: "fa fa-print" }),
                    _vm._v(" 打印退货单")
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ],
            staticStyle: { margin: "10px 0" }
          },
          [
            _c("BarcodeSearchComponent", {
              ref: "BarcodeSearchComponentRef",
              attrs: {
                consign: 1,
                warehouseId: _vm.form.warehouseId || 0,
                manufacturerId: _vm.form.manufacturerId || 0,
                tipMessage: _vm.tipMessage,
                lastMainCode: _vm.lastMainCode
              },
              on: {
                addStock: _vm.stockAddToList,
                "update:tipMessage": function($event) {
                  _vm.tipMessage = $event
                },
                "update:tip-message": function($event) {
                  _vm.tipMessage = $event
                },
                "update:lastMainCode": function($event) {
                  _vm.lastMainCode = $event
                },
                "update:last-main-code": function($event) {
                  _vm.lastMainCode = $event
                }
              }
            })
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailListTableRef",
          attrs: {
            hasIndex: true,
            slotIndex: true,
            hasPagination: true,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            rowClassName: _vm.rowClassName,
            data: _vm.detailList.slice(
              (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
              _vm.detailForm.page * _vm.detailForm.pageSize
            )
          },
          on: {
            "row-click": _vm.detailRowClick,
            pageChange: _vm.detailFormPageChange,
            sizeChange: _vm.detailFormSizeChange
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var row = ref.row
                var index = ref.index
                return [
                  row.expireDays <= 90 && row.expireDays > 0
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "该产品还有" + row.expireDays + "天过期",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "align-items": "center",
                                "justify-content": "center",
                                position: "relative"
                              }
                            },
                            [
                              _c("d2-icon-svg", {
                                staticStyle: {
                                  width: "16px",
                                  height: "14px",
                                  position: "absolute",
                                  left: "0"
                                },
                                attrs: { name: "warning-yellow" }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.detailForm.total -
                                      index * _vm.detailForm.page
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  row.expireDays <= 0
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content:
                              "该产品已过期" + Math.abs(row.expireDays) + "天",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "align-items": "center",
                                "justify-content": "center",
                                position: "relative"
                              }
                            },
                            [
                              _c("d2-icon-svg", {
                                staticStyle: {
                                  width: "16px",
                                  height: "14px",
                                  position: "absolute",
                                  left: "0"
                                },
                                attrs: { name: "warning-red" }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.detailForm.total -
                                      index * _vm.detailForm.page
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  row.expireDays > 90
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.detailForm.total -
                              index -
                              _vm.detailForm.pageSize *
                                (_vm.detailForm.page - 1)
                          )
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "returnWarehouseQuantity",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          label: "退货数量",
                          size: "mini",
                          precision: 0,
                          min: 0,
                          controls: false
                        },
                        on: {
                          change: function($event) {
                            return _vm.detailSaveOne(row)
                          }
                        },
                        model: {
                          value: row.returnWarehouseQuantity,
                          callback: function($$v) {
                            _vm.$set(row, "returnWarehouseQuantity", $$v)
                          },
                          expression: "row.returnWarehouseQuantity"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.returnWarehouseQuantity))])
                ]
              }
            },
            {
              key: "returnPrice",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0,
                          precision: 2,
                          step: 0.1,
                          controls: false,
                          label: "退货价",
                          size: "mini"
                        },
                        on: {
                          change: function($event) {
                            return _vm.detailSaveOne(row)
                          }
                        },
                        model: {
                          value: row.returnPrice,
                          callback: function($$v) {
                            _vm.$set(row, "returnPrice", $$v)
                          },
                          expression: "row.returnPrice"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.returnPrice))])
                ]
              }
            }
          ])
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-breadcrumb",
            {
              staticStyle: {
                "font-size": "12px",
                "margin-bottom": "10px",
                width: "100%"
              },
              attrs: { separator: " " }
            },
            [
              _c("el-breadcrumb-item", [
                _vm._v("制单人：" + _vm._s(_vm.form.returnName))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("制单时间：" + _vm._s(_vm.form.CreatedAt))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("审核人：" + _vm._s(_vm.form.nameReviewer))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("审核时间：" + _vm._s(_vm.form.examineTime))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("记账人：" + _vm._s(_vm.form.accountName))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("记账时间：" + _vm._s(_vm.form.accountTime))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("退货总数量：" + _vm._s(_vm.countQuantity()))
              ])
            ],
            1
          ),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          key: "remove-button",
                          attrs: {
                            type: "danger",
                            disabled: !(
                              _vm.form.status == 1 && _vm.detailRowIndex >= 0
                            )
                          },
                          on: { click: _vm.detailRowRemove }
                        },
                        [_vm._v("删除行")]
                      )
                    : _vm._e(),
                  _vm.form.status == 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "save-button",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toFormStorage()
                            }
                          }
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm.form.status == 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "submit-button",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toFormSubmit()
                            }
                          }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.form.id && _vm.form.status == 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "unsubmit-button",
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.formUnSubmit()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-refresh-left" }),
                          _vm._v(" 撤消提交")
                        ]
                      )
                    : _vm._e(),
                  _vm.form.status == 1 && _vm.form.id
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "invalid-button",
                          attrs: { type: "danger", icon: "el-icon-delete" },
                          on: { click: _vm.orderInvalid }
                        },
                        [_vm._v("作废")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      key: "print-button",
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.orderPrint }
                    },
                    [_vm._v("打印")]
                  ),
                  _vm.form.status === 4
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "post-button",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.dataPost()
                            }
                          }
                        },
                        [_vm._v("退货数据发送至平台")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ProductAddComponents", {
        ref: "ProductAddComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ImportProductComponents", {
        ref: "ImportProductComponents",
        on: { confirm: _vm.importFromExcel }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }