import _objectSpread from "D:/wwwroot/ERP/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/base',
  name: 'base',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'hospital/index',
    name: 'base-hospital-index',
    component: _import('base/hospital/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '医院管理'
    })
  }, {
    path: 'hospital/auth',
    name: 'base-hospital-auth',
    component: _import('base/hospital/auth'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '医院访问控制（销售管理）'
    })
  }, {
    path: 'hospital/auth_warehouse',
    name: 'base-hospital-authwarehouse',
    component: _import('base/hospital/auth_warehouse'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '医院访问控制（库房管理）'
    })
  }, {
    path: 'supplier/index',
    name: 'base-supplier-index',
    component: _import('base/supplier/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '供应商管理'
    })
  }, {
    path: 'retailer/index',
    name: 'base-retailer-index',
    component: _import('base/retailer/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '经销商管理'
    })
  }, {
    path: 'retailer/auth',
    name: 'base-retailer-auth',
    component: _import('base/retailer/auth'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '经销商访问控制（销售管理）'
    })
  }, {
    path: 'manufacturer/index',
    name: 'base-manufacturer-index',
    component: _import('base/manufacturer/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '生产商管理'
    })
  }, {
    path: 'brand/index',
    name: 'base-brand-index',
    component: _import('base/brand/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '品牌维护'
    })
  }, {
    path: 'class/index',
    name: 'base-class-index',
    component: _import('base/class/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '产品线及分类'
    })
  }, {
    path: 'product/index',
    name: 'base-product-index',
    component: _import('base/product/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '物资字典管理'
    })
  }, {
    path: 'product/weight',
    name: 'base-product-weight',
    component: _import('base/product/weight'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '物资体积重量管理'
    })
  }, {
    path: 'registration/index',
    name: 'base-registration-index',
    component: _import('base/registration/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '注册证管理'
    })
  }, {
    path: 'company/index',
    name: 'base-company-index',
    component: _import('base/company/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '本公司信息'
    })
  }, {
    path: 'department/index',
    name: 'base-department-index',
    component: _import('base/department/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '部门管理'
    })
  }, {
    path: 'personnel/index',
    name: 'base-personnel-index',
    component: _import('base/personnel/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '员工管理'
    })
  }, {
    path: 'warehouse/index',
    name: 'base-warehouse-index',
    component: _import('base/warehouse/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '仓库管理'
    })
  }, {
    path: 'warehouse/auth',
    name: 'base-warehouse-auth',
    component: _import('base/warehouse/auth'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '仓库访问控制'
    })
  }, {
    path: 'roles/index',
    name: 'base-roles-index',
    component: _import('base/roles/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '角色管理'
    })
  }, {
    path: 'examine/company',
    name: 'base-examine-company-index',
    component: _import('base/examine/company'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '使用单位审核'
    })
  }, {
    path: 'examine/supplier',
    name: 'base-examine-supplier-index',
    component: _import('base/examine/supplier'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '供应商审核'
    })
  }, {
    path: 'examine/retailer',
    name: 'base-examine-retailer-index',
    component: _import('base/examine/retailer'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '经销商审核'
    })
  }, {
    path: 'examine/manufacturer',
    name: 'base-examine-manufacturer-index',
    component: _import('base/examine/manufacturer'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '生产商审核'
    })
  }, {
    path: 'examine/registration',
    name: 'base-examine-registration-index',
    component: _import('base/examine/registration'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '注册证审核'
    })
  }, {
    path: 'examine/hospital',
    name: 'base-examine-hospital-index',
    component: _import('base/examine/hospital'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '医院审核'
    })
  }, {
    path: 'examine/product',
    name: 'base-examine-product-index',
    component: _import('base/examine/product'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '产品审核'
    })
  }, {
    path: 'barcode/index',
    name: 'base-barcode-index',
    component: _import('base/barcode/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '条码规则'
    })
  }, {
    path: 'shelves/index',
    name: 'base-shelves-index',
    component: _import('base/shelves/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '货架管理'
    })
  }, {
    path: 'location/index',
    name: 'base-location-index',
    component: _import('base/location/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '货位管理'
    })
  }, {
    path: 'address',
    name: 'base-address-index',
    component: _import('base/address/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '客户地址管理'
    }),
    props: true
  }, {
    path: 'address2',
    name: 'base-address2-index',
    component: _import('base/address2/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '客户地址管理'
    }),
    props: true
  }]
};