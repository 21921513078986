var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload" },
    [
      _vm.mode !== "FILELIST"
        ? _c(
            "el-upload",
            {
              class: _vm.mode === "DRAG" ? "drag-upload" : "",
              attrs: {
                action: "#",
                drag: _vm.mode === "DRAG" ? true : false,
                "show-file-list": false,
                "auto-upload": true,
                accept: _vm.uploadFileAccept.toString(),
                "http-request": _vm.uploadRequest,
                loading: _vm.uploadLoading,
                disabled: _vm.uploadLoading
              }
            },
            [
              _vm.mode === "BUTTON"
                ? _c(
                    "el-button",
                    { attrs: { size: "mini", type: "primary" } },
                    [_vm._v("点击上传")]
                  )
                : _vm._e(),
              _vm.mode === "DRAG"
                ? _c("i", { staticClass: "el-icon-upload" })
                : _vm._e(),
              _vm.mode === "DRAG"
                ? _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")])
                  ])
                : _vm._e(),
              _vm.showTipMessage === true && _vm.mode === "BUTTON"
                ? _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [
                      _vm._v(
                        "可传图片、EXCEL文档、WORD文档、PDF文档。单个文件大小不能超过10MB"
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "file-list" },
        _vm._l(_vm.fileList, function(file, index) {
          return _c(
            "li",
            { key: "file" + index },
            [
              _c(
                "el-link",
                {
                  attrs: { type: "primary", underline: false },
                  on: {
                    click: function($event) {
                      return _vm.attachmentPreview(file)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "file-name" }, [
                    _vm._v(_vm._s(file.name))
                  ])
                ]
              ),
              _c(
                "el-link",
                {
                  attrs: { type: "danger", underline: false },
                  on: {
                    click: function($event) {
                      return _vm.attachmentRemove(file)
                    }
                  }
                },
                [_vm._v("删除")]
              )
            ],
            1
          )
        }),
        0
      ),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.uploadPreviewImageUrl,
              "z-index": 9999
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }