import _objectSpread from "D:/wwwroot/ERP/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  name: 'd2-theme-list',
  data: function data() {
    return {
      table: {
        showHeader: false,
        border: true
      }
    };
  },
  computed: _objectSpread({}, mapState('d2admin/theme', ['list', 'activeName'])),
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/theme', ['set'])), {}, {
    handleSelectTheme: function handleSelectTheme(name) {
      this.set(name);
    }
  })
};