import _objectSpread from "D:/wwwroot/ERP/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/sales',
  name: 'sales',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'agent/retailer',
    name: 'sales-agent-retailer-index',
    component: _import('sales/agent/retailer/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售经销商管理'
    })
  }, {
    path: 'agent/user',
    name: 'sales-agent-user-index',
    component: _import('sales/agent/user/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '经销商用户管理'
    }),
    props: true
  }, {
    path: 'agent/report',
    name: 'sales-agent-report-index',
    component: _import('sales/agent/report/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售报台单审核'
    }),
    props: true
  }, {
    path: 'agent/report/create',
    name: 'sales-agent-report-create',
    component: _import('sales/agent/report/create.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增寄售报台单'
    }),
    props: true
  }, {
    path: 'agent/report/view/:id',
    name: 'sales-agent-report-view',
    component: _import('sales/agent/report/view.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售报台单详情'
    }),
    props: true
  }, {
    path: 'agent/client',
    name: 'sales-agent-client-index',
    component: _import('sales/agent/client/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '经销商客户管理'
    }),
    props: true
  }, {
    path: 'agent/return/index',
    name: 'sales-agent-return-index',
    component: _import('sales/agent/return/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售出库退货'
    }),
    props: true
  }, {
    path: 'agent/return/create',
    name: 'sales-agent-return-create',
    component: _import('sales/agent/return/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增寄售出库退货单'
    }),
    props: true
  }, {
    path: 'agent/return/detail/:id',
    name: 'sales-agent-return-detail',
    component: _import('sales/agent/return/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售出库退货单详情'
    }),
    props: true
  }, {
    path: 'order/sale/index',
    name: 'sales-order-sale-index',
    component: _import('sales/order/sale/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售订货单'
    }),
    props: true
  }, {
    path: 'order/sale/create',
    name: 'sales-order-sale-create',
    component: _import('sales/order/sale/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增销售订货单',
      cache: true
    }),
    props: true
  }, {
    path: 'order/sale/view/:id',
    name: 'sales-order-sale-view',
    component: _import('sales/order/sale/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销售订货单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'order/sale/out_warehouse/:id',
    name: 'sales-order-sale-out_warehouse',
    component: _import('sales/order/sale/out_warehouse'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '发货出库',
      cache: true
    }),
    props: true
  }, {
    path: 'order/agent/index',
    name: 'sales-order-agent-index',
    component: _import('sales/order/agent/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售订货单'
    }),
    props: true
  }, {
    path: 'order/agent/create',
    name: 'sales-order-agent-create',
    component: _import('sales/order/agent/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增寄售订货单',
      cache: true
    }),
    props: true
  }, {
    path: 'order/agent/view/:id',
    name: 'sales-order-agent-view',
    component: _import('sales/order/agent/view'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '寄售订货单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'order/agent/out_warehouse/:id',
    name: 'sales-order-agent-out_warehouse',
    component: _import('sales/order/agent/out_warehouse'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '发货出库',
      cache: true
    }),
    props: true
  }, {
    path: 'business/direct/index',
    name: 'sales-business-direct-index',
    component: _import('sales/business/direct/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '直销销售单'
    }),
    props: true
  }, {
    path: 'business/direct/view/:id',
    name: 'sales-business-direct-view',
    component: _import('sales/business/direct/view'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '直销销售单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'business/consign/index',
    name: 'sales-business-consign-index',
    component: _import('sales/business/consign/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '临调销售单'
    }),
    props: true
  }, {
    path: 'business/consign/view/:id',
    name: 'sales-business-consign-view',
    component: _import('sales/business/consign/view'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '临调销售单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'business/youtai/index',
    name: 'sales-business-youtai-index',
    component: _import('sales/business/youtai/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '有台销售单'
    }),
    props: true
  }, {
    path: 'business/youtai/view/:id',
    name: 'sales-business-youtai-view',
    component: _import('sales/business/youtai/view'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '有台销售单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'business/stock/index',
    name: 'sales-business-stock-index',
    component: _import('sales/business/stock/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售出库销售单'
    }),
    props: true
  }, {
    path: 'business/stock/view/:id',
    name: 'sales-business-stock-view',
    component: _import('sales/business/stock/view'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销售出库销售单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'business/agent/index',
    name: 'sales-business-agent-index',
    component: _import('sales/business/agent/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售销售单'
    }),
    props: true
  }, {
    path: 'business/agent/view/:id',
    name: 'sales-business-agent-view',
    component: _import('sales/business/agent/view'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '寄售销售单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'report/service',
    name: 'sales-report-service-index',
    component: _import('sales/report/service/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售报台服务'
    }),
    props: true
  }, {
    path: 'report/record',
    name: 'sales-report-record-index',
    component: _import('sales/report/record/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售报台记录'
    }),
    props: true
  }, {
    path: 'report/bind',
    name: 'sales-report-bind-index',
    component: _import('sales/report/bind/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售平台绑定设置'
    }),
    props: true
  }, {
    path: 'surgery/order/sale',
    name: 'sales-surgery-order-sale',
    component: _import('sales/surgery/order/sale.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '订单管理（销售）'
    }),
    props: true
  }, {
    path: 'surgery/order/follow',
    name: 'sales-surgery-order-follow',
    component: _import('sales/surgery/order/follow.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '订单管理（跟台员）'
    }),
    props: true
  }, {
    path: 'surgery/order/data',
    name: 'sales-surgery-order-data-index',
    component: _import('sales/surgery/order/data/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '订单管理（数据员）'
    }),
    props: true
  }, {
    path: 'surgery/order/data/view/:id',
    name: 'sales-surgery-order-data-detail',
    component: _import('sales/surgery/order/data/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '订单详情'
    }),
    props: true
  }]
};