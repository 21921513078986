var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售报价")]),
              _c("el-breadcrumb-item", [_vm._v("寄售销售单")]),
              _c("el-breadcrumb-item", [_vm._v("寄售销售单详情")])
            ],
            1
          ),
          _c("div", { staticStyle: { "font-size": "12px" } }, [
            _vm._v("报台单号：" + _vm._s(_vm.orderInfo.salesOrderNo))
          ])
        ],
        1
      ),
      [
        _vm.orderInfo.ID
          ? _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                ref: "form",
                staticClass: "form-table",
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  model: _vm.form,
                  rules: _vm.formRules,
                  size: "mini",
                  "label-width": "100px",
                  "label-suffix": ":",
                  "show-message": false,
                  disabled: _vm.orderInfo.status > 1
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { type: "flex" }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "仓库" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.warehouse.name))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "经销商" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.manu.name))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "经销商客户" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.customerName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "报台日期" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.tradeDate))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "报台单号" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.orderInfo.stockout.deliveryOrderNo)
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "归属公司", prop: "subsidiaryId" }
                          },
                          [
                            _vm.orderInfo.status === 1
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "", filterable: "" },
                                    model: {
                                      value: _vm.form.subsidiaryId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "subsidiaryId", $$v)
                                      },
                                      expression: "form.subsidiaryId"
                                    }
                                  },
                                  _vm._l(_vm.subsidiaryList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: "subsidiaryId" + index,
                                      attrs: {
                                        label: item.companyName,
                                        value: item.ID
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(
                                    _vm._s(_vm.orderInfo.subsidiary.companyName)
                                  )
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.orderInfo.stockout.multi === 1
                      ? _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "病人姓名",
                                  prop: "patientName"
                                }
                              },
                              [
                                _c("span", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.orderInfo.patientName))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderInfo.stockout.multi === 1
                      ? _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "病人年龄", prop: "patientAge" }
                              },
                              [
                                _c("span", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.orderInfo.patientAge))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderInfo.stockout.multi === 1
                      ? _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "性别", prop: "patientGender" }
                              },
                              [
                                _c("span", { staticClass: "item-text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.patientGenderFormatter(
                                        _vm.orderInfo.patientGender
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderInfo.stockout.multi === 1
                      ? _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "住院号",
                                  prop: "admissionNumber"
                                }
                              },
                              [
                                _c("span", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.orderInfo.admissionNumber))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderInfo.stockout.multi === 1
                      ? _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "手术日期",
                                  prop: "operationTime"
                                }
                              },
                              [
                                _c("span", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.orderInfo.operationTime))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orderInfo.stockout.multi === 1
                      ? _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "医生姓名", prop: "surgeon" } },
                              [
                                _c("span", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.orderInfo.surgeon))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      {
                        attrs: {
                          sm: _vm.orderInfo.stockout.multi === 1 ? 24 : 24,
                          lg: _vm.orderInfo.stockout.multi === 1 ? 24 : 24,
                          xl: _vm.orderInfo.stockout.multi === 1 ? 24 : 12
                        }
                      },
                      [
                        _c("el-form-item", { attrs: { label: "备注" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.remarks))
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
              [
                _vm.returnList.length > 0
                  ? _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(1)
                          }
                        }
                      },
                      [_vm._v("销售详情")]
                    )
                  : _vm._e(),
                _vm.returnList.length > 0
                  ? _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 2 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(2)
                          }
                        }
                      },
                      [_vm._v("退货详情")]
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticStyle: { "font-size": "14px", "line-height": "30px" } },
              [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.activeTabId === 1 ? "销售总数：" : "退货数量："
                      )
                  ),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.totalQuantity()))
                  ])
                ]),
                _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.activeTabId === 1 ? "销售金额：" : "退货金额："
                      )
                  ),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.terSalesAmount()))
                  ])
                ])
              ]
            )
          ]
        ),
        _vm.activeTabId === 1
          ? _c(
              "div",
              [
                _c(
                  "YTable",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.detailLoading,
                        expression: "detailLoading"
                      }
                    ],
                    key: "detail-table" + _vm.timestamp,
                    ref: "detailListTable",
                    attrs: {
                      hasIndex: true,
                      hasPagination: true,
                      pagination: _vm.detailForm,
                      "route-name": _vm.$route.name + "detail-table",
                      columns: _vm.detailColumns,
                      rowClassName: _vm.rowClassName,
                      data: _vm.detailList.slice(
                        (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
                        _vm.detailForm.page * _vm.detailForm.pageSize
                      )
                    },
                    on: {
                      "row-click": _vm.detailRowClick,
                      pageChange: _vm.detailPageChange,
                      sizeChange: _vm.detailSizeChange
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "isConsign",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.isConsign === 1
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "success",
                                        effect: "dark",
                                        size: "mini"
                                      }
                                    },
                                    [_vm._v("是")]
                                  )
                                : _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "info",
                                        effect: "dark",
                                        size: "mini"
                                      }
                                    },
                                    [_vm._v("否")]
                                  )
                            ]
                          }
                        },
                        {
                          key: "unitSellingPrice",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      min: 0,
                                      precision: 2,
                                      controls: false
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.unitSellingPrice,
                                      callback: function($$v) {
                                        _vm.$set(row, "unitSellingPrice", $$v)
                                      },
                                      expression: "row.unitSellingPrice"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(_vm._s(row.unitSellingPrice))
                                  ])
                            ]
                          }
                        },
                        {
                          key: "discountRate",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c(
                                    "div",
                                    { staticStyle: { position: "relative" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: 0,
                                          precision: 2,
                                          size: "mini",
                                          controls: false
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.detailSaveOne(row)
                                          }
                                        },
                                        model: {
                                          value: row.discountRate,
                                          callback: function($$v) {
                                            _vm.$set(row, "discountRate", $$v)
                                          },
                                          expression: "row.discountRate"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px"
                                          }
                                        },
                                        [_vm._v("%")]
                                      )
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(row.discountRate.toFixed(2)) + "%"
                                    )
                                  ])
                            ]
                          }
                        },
                        {
                          key: "terUnitPrice",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      min: 0,
                                      precision: 2,
                                      size: "mini",
                                      controls: false
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.terUnitPrice,
                                      callback: function($$v) {
                                        _vm.$set(row, "terUnitPrice", $$v)
                                      },
                                      expression: "row.terUnitPrice"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(_vm._s(row.terUnitPrice.toFixed(2)))
                                  ])
                            ]
                          }
                        },
                        {
                          key: "remarks",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.orderInfo.status === 1
                                ? _c("el-input", {
                                    attrs: { size: "mini" },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.remarks,
                                      callback: function($$v) {
                                        _vm.$set(row, "remarks", $$v)
                                      },
                                      expression: "row.remarks"
                                    }
                                  })
                                : _c("span", [_vm._v(_vm._s(row.remarks))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1980104147
                    )
                  },
                  [
                    _c("template", { slot: "discountRateHeader" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "5px" } },
                            [_vm._v("折扣（%）")]
                          ),
                          _vm.orderInfo.status === 1
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editRate()
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          : _vm._e(),
        _vm.activeTabId === 2
          ? _c(
              "div",
              [
                _c("YTable", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.detailLoading,
                      expression: "detailLoading"
                    }
                  ],
                  key: "returnListTable" + _vm.timestamp,
                  ref: "returnListTableRef",
                  attrs: {
                    hasIndex: true,
                    hasPagination: true,
                    pagination: _vm.returnForm,
                    "route-name": _vm.$route.name + "return-table",
                    columns: _vm.returnColumns,
                    data: _vm.returnList.slice(
                      (_vm.returnForm.page - 1) * _vm.returnForm.pageSize,
                      _vm.returnForm.page * _vm.returnForm.pageSize
                    )
                  },
                  on: {
                    pageChange: _vm.returnPageChange,
                    sizeChange: _vm.returnSizeChange
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "出库人：" +
                      _vm._s(_vm.orderInfo.stockout.nameWarehousePersonnel)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "出库时间：" + _vm._s(_vm.orderInfo.stockout.deliveryTime)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "出库复核人：" + _vm._s(_vm.orderInfo.stockout.nameReviewer)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "出库复核时间：" + _vm._s(_vm.orderInfo.stockout.auditTime)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "回库人：" +
                      _vm._s(_vm.orderInfo.stockout.returningPersonnel)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v(
                    "回库时间：" + _vm._s(_vm.orderInfo.stockout.returnDate)
                  )
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("报价人：" + _vm._s(_vm.orderInfo.quotationPersonnel))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("报价时间：" + _vm._s(_vm.orderInfo.quotationDate))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.orderInfo.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.offerSubmit)
                              }
                            }
                          },
                          [_vm._v("报价")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.orderInfo.ID },
                        on: { click: _vm.orderPrint }
                      },
                      [
                        _c("i", { staticClass: "fa fa-print" }),
                        _vm._v(" 打印 ")
                      ]
                    ),
                    _vm.orderInfo.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelOrder }
                          },
                          [_vm._v("驳回")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelPrice }
                          },
                          [_vm._v("撤销报价")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("RateComponent", {
        ref: "RateComponentRef",
        on: { update: _vm.updateRate }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }