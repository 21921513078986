import { request } from '@/api/_service.js';
export function youtaiUploadFile(data, params) {
  return request({
    url: "".concat(process.env.VUE_APP_YOUTAI_API, "/api/upload"),
    method: 'post',
    params: params,
    data: data
  });
}
export function erpUploadFile(data, params) {
  return request({
    url: '/inside/upload',
    method: 'post',
    params: params,
    data: data
  });
}
export function erpUploadFile2(data, params) {
  return request({
    url: '/inside/upload2',
    method: 'post',
    params: params,
    data: data
  });
}
export function erpDeleteFile(data) {
  return request({
    url: '/inside/deleteFile',
    method: 'delete',
    data: data
  });
}