var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "el-popover",
        {
          ref: "popoverRef",
          staticStyle: { position: "absolute" },
          attrs: {
            placement: "bottom-start",
            reference: _vm.reference,
            "append-to-body": false,
            trigger: "click"
          }
        },
        [
          _c(
            "div",
            { staticClass: "dragHeaderTable" },
            _vm._l(_vm.columns, function(item, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !item.sortHide,
                      expression: "!item.sortHide"
                    }
                  ],
                  key: "header" + index + _vm.timeStamp,
                  staticClass: "item"
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        width: "18px",
                        height: "100%",
                        "text-align": "left",
                        display: "flex",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("d2-icon-svg", {
                        staticClass: "move",
                        staticStyle: {
                          width: "18px",
                          height: "18px",
                          fill: "#666"
                        },
                        attrs: { name: "ic_drag" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: item.disabled },
                      on: { change: _vm.saveTableSet },
                      model: {
                        value: item.checked,
                        callback: function($$v) {
                          _vm.$set(item, "checked", $$v)
                        },
                        expression: "item.checked"
                      }
                    },
                    [_vm._v(_vm._s(item.title))]
                  )
                ],
                1
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }