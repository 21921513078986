import { render, staticRenderFns } from "./index.vue?vue&type=template&id=39748eca&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39748eca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\ERP\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39748eca')) {
      api.createRecord('39748eca', component.options)
    } else {
      api.reload('39748eca', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=39748eca&scoped=true&", function () {
      api.rerender('39748eca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/stock/inwarehouse/salesreturn/components/drag-table/index.vue"
export default component.exports