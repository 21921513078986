var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "400px",
            "close-on-click-modal": false,
            "append-to-body": true,
            "before-close": _vm.dialogClose,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              staticClass: "form-table",
              attrs: {
                "label-width": "100px",
                size: "small",
                "label-suffix": ":",
                disabled: _vm.formLoading
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.formSubmit.apply(null, arguments)
                }
              },
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "修改折扣", prop: "discountRate" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                                "padding-right": "5px"
                              }
                            },
                            [
                              _c("el-input-number", {
                                ref: "discountRateRef",
                                attrs: {
                                  placeholder: "产品编号",
                                  precision: 2,
                                  placeholder: _vm.lastRate.toFixed(2),
                                  controls: false,
                                  min: 0
                                },
                                model: {
                                  value: _vm.form.discountRate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "discountRate", $$v)
                                  },
                                  expression: "form.discountRate"
                                }
                              }),
                              _c("span", { staticClass: "ml-10" }, [
                                _vm._v("%")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "text-danger",
              staticStyle: { "margin-top": "10px" },
              attrs: { align: "center" }
            },
            [_vm._v("折扣修改提交后会自动修改列表内所有产品的折扣")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "div",
                { attrs: { align: "center" } },
                [
                  _c(
                    "el-button",
                    { key: "cancel-button", on: { click: _vm.dialogClose } },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      key: "submit-button",
                      attrs: { type: "primary", loading: _vm.formLoading },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }