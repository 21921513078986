var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购验收")]),
              _c("el-breadcrumb-item", [_vm._v("寄售订购订单")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary"
                  },
                  on: { click: _vm.orderCreate }
                },
                [_vm._v("新增寄售订购订单")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-check",
                    type: "success",
                    disabled: _vm.selections().length === 0 ? true : false
                  },
                  on: { click: _vm.toStateUpdate }
                },
                [_vm._v("结单")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchFormShow,
                expression: "searchFormShow"
              }
            ],
            staticClass: "form-card",
            attrs: { shadow: "never" }
          },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          multiple: "",
                          "collapse-tags": "",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.warehouseIds,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseIds", $$v)
                          },
                          expression: "searchForm.warehouseIds"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: "warehouseIds-" + item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.manufacturerId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "manufacturerId", $$v)
                          },
                          expression: "searchForm.manufacturerId"
                        }
                      },
                      _vm._l(_vm.manufacturerList, function(item) {
                        return _c("el-option", {
                          key: "manu-" + item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "寄售单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "寄售单号", clearable: "" },
                      on: { change: _vm.orderNumberChange },
                      model: {
                        value: _vm.searchForm.orderNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "orderNumber", $$v)
                        },
                        expression: "searchForm.orderNumber"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "寄售员" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.searchForm.buyerUid,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "buyerUid", $$v)
                              },
                              expression: "searchForm.buyerUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "buyer-" + item.id,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "日期查询" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "picker-options": _vm.pickerOptions,
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.searchForm.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange", $$v)
                            },
                            expression: "searchForm.dateRange"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "收货状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.state,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "state", $$v)
                              },
                              expression: "searchForm.state"
                            }
                          },
                          _vm._l(_vm.stateList, function(item) {
                            return _c("el-option", {
                              key: "state-" + item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品名称" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "产品名称", clearable: "" },
                          model: {
                            value: _vm.searchForm.productName,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productName", $$v)
                            },
                            expression: "searchForm.productName"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "产品编号", clearable: "" },
                          model: {
                            value: _vm.searchForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productNumber", $$v)
                            },
                            expression: "searchForm.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "备注", clearable: "" },
                          model: {
                            value: _vm.searchForm.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "remark", $$v)
                            },
                            expression: "searchForm.remark"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "排序方式" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.searchForm.sortType,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "sortType", $$v)
                              },
                              expression: "searchForm.sortType"
                            }
                          },
                          _vm._l(_vm.sortTypeList, function(item) {
                            return _c("el-option", {
                              key: "sort-type-" + item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              "margin-bottom": "10px",
              "font-size": "14px"
            }
          },
          [_c("div"), _c("div")]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            selectable: _vm.selectable,
            hasSelection: true,
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList,
            selections: _vm.selections
          },
          on: {
            "row-click": _vm.orderRowClick,
            "row-dblclick": _vm.orderRowDbClick
          },
          scopedSlots: _vm._u([
            {
              key: "orderNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-popover",
                    {
                      ref: "barCodePopover" + row.ID,
                      attrs: { placement: "right", trigger: "click" }
                    },
                    [
                      _c("div", [
                        _c("img", { attrs: { id: "barCodeImage" + row.ID } })
                      ]),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            slot: "reference",
                            underline: false,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.makebarCode(row)
                            }
                          },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(row.orderNumber))]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "state",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.state === 2 && [1, 2].includes(row.examine_status)
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: row.approvalOpinions,
                            placement: "top",
                            disabled: row.examine_status !== 2
                          }
                        },
                        [
                          _c(
                            "el-link",
                            {
                              staticStyle: { "margin-right": "5px" },
                              attrs: {
                                type:
                                  _vm.examineStatusList[row.examine_status].type
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.examineStatusList[row.examine_status].name
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "el-link",
                        { attrs: { type: _vm.stateList[row.state].type } },
                        [_vm._v(_vm._s(_vm.stateList[row.state].name))]
                      )
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.toViewOrder(row)
                            }
                          }
                        },
                        [_vm._v("详情")]
                      ),
                      row.state === 3 && row.completed === 0
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "success" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toStateUpdate(row)
                                }
                              }
                            },
                            [_vm._v("结单")]
                          )
                        : _vm._e(),
                      row.state === 4 || row.completed === 1
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toViewReceive(row)
                                }
                              }
                            },
                            [_vm._v("查看收货情况")]
                          )
                        : _vm._e(),
                      row.showReceive === 1 && row.examine_status === 3
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toInWarehouse(row)
                                }
                              }
                            },
                            [_vm._v("一键入库")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [5, 10, 20, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize
              },
              on: {
                "current-change": _vm.orderPageChange,
                "size-change": _vm.orderSizeChange
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
          [
            _c(
              "div",
              {
                class: _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(1)
                  }
                }
              },
              [
                _vm._v("寄售明细("),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    _vm._s(_vm.currentRow ? _vm.currentRow.purchaseQuantity : 0)
                  )
                ]),
                _vm._v(")")
              ]
            ),
            _c(
              "div",
              {
                class: _vm.activeTabId === 2 ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(2)
                  }
                }
              },
              [
                _vm._v("收货明细("),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    _vm._s(
                      _vm.currentRow ? _vm.currentRow.receiveQuantitySum : 0
                    )
                  )
                ]),
                _vm._v(")")
              ]
            ),
            _c(
              "div",
              {
                class: _vm.activeTabId === 3 ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(3)
                  }
                }
              },
              [
                _vm._v("验收明细("),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    _vm._s(
                      _vm.currentRow ? _vm.currentRow.qualifiedQuantitySum : 0
                    )
                  )
                ]),
                _vm._v(")")
              ]
            ),
            _c(
              "div",
              {
                class: _vm.activeTabId === 4 ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(4)
                  }
                }
              },
              [
                _vm._v("入库明细("),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    _vm._s(_vm.currentRow ? _vm.currentRow.inWarehouseSum : 0)
                  )
                ]),
                _vm._v(")")
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 1,
                expression: "activeTabId === 1"
              }
            ]
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.detailList,
                  size: "small",
                  "highlight-current-row": "",
                  border: "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailForm)
                    },
                    label: "序号",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.productNumber",
                    label: "产品编号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.productName",
                    label: "产品名称",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.genericName",
                    label: "通用名",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.specificationModel",
                    label: "规格型号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.registrationNumber",
                    label: "注册证号",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.unit",
                    label: "单位",
                    align: "center",
                    width: "100",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchaseQuantity",
                    label: "寄售数量",
                    align: "center",
                    width: "100",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchaseUnitPrice",
                    label: "寄售单价",
                    align: "center",
                    width: "100",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "寄售金额",
                    align: "center",
                    width: "100",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.purchaseUnitPrice * row.purchaseQuantity
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "remarks",
                    label: "备注",
                    align: "center",
                    width: "200",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.brandName",
                    label: "品牌",
                    align: "center",
                    width: "150",
                    "show-overflow-tooltip": ""
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 2,
                expression: "activeTabId === 2"
              }
            ]
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.detailList,
                  size: "small",
                  "highlight-current-row": "",
                  border: "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  }
                },
                on: { "expand-change": _vm.receiptExpandChange }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                size: "small",
                                data: scope.row.receiptList
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "50",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品编号",
                                  prop: "specs.productNumber",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品名称",
                                  prop: "main.productName",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格型号",
                                  prop: "specs.specificationModel",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "注册证号",
                                  prop: "main.registrationNumber",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单位",
                                  prop: "specs.unit",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "品牌",
                                  prop: "main.brandName",
                                  width: "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "寄售数量",
                                  prop: "orderDetail.purchaseQuantity",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "收货数量",
                                  prop: "receiptQuantity",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "备注",
                                  prop: "remarks",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailForm)
                    },
                    label: "序号",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "warehouseName",
                    label: "仓库名称",
                    "min-width": "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturerName",
                    label: "供应商",
                    "min-width": "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "receivNumber",
                    label: "收货单号",
                    "min-width": "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.viewReceipt(row.ID)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.receivNumber))]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "receivName",
                    label: "收货人",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "acceptState",
                    label: "验收情况",
                    align: "center",
                    width: "100",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.acceptStateFormatter(row.acceptState)
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "receivingDate",
                    label: "收货日期",
                    align: "center",
                    width: "100",
                    "show-overflow-tooltip": ""
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 3,
                expression: "activeTabId === 3"
              }
            ]
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.detailList,
                  size: "small",
                  "highlight-current-row": "",
                  border: "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  }
                },
                on: { "expand-change": _vm.reportExpandChange }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                size: "small",
                                data: scope.row.reportList
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "50",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品编号",
                                  prop: "specs.productNumber",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品名称",
                                  prop: "main.productName",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格型号",
                                  prop: "specs.specificationModel",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "注册证号",
                                  prop: "main.registrationNumber",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单位",
                                  prop: "specs.unit",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "品牌",
                                  prop: "main.brandName",
                                  width: "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "合格数量",
                                  prop: "qualifiedQuantity",
                                  width: "150",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "不合格数量",
                                  prop: "unqualifiedQuantity",
                                  width: "150",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "收货数量",
                                  prop: "receivDetail.receiptQuantity",
                                  width: "150",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "备注",
                                  prop: "remarks",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailForm)
                    },
                    label: "序号",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "warehouseName",
                    label: "仓库名称",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "acceptNumber",
                    label: "验收单号",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.viewReport(row.ID)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.acceptNumber))]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "receivNumber",
                    label: "收货单号",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.viewReceipt(row.receivId)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.receivNumber))]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "orderNumber",
                    label: "寄售单号",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.viewPurchase(row.purchaseOrder.ID)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.orderNumber))]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturerName",
                    label: "供应商",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "state",
                    label: "状态",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.reportStateFormatter(row.state)
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "acceptName",
                    label: "验收人",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "acceptDate",
                    label: "验收日期",
                    align: "center",
                    width: "100",
                    "show-overflow-tooltip": ""
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 4,
                expression: "activeTabId === 4"
              }
            ]
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.detailList,
                  size: "small",
                  "highlight-current-row": "",
                  border: "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  }
                },
                on: { "expand-change": _vm.inWarehouseExpandChange }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                size: "small",
                                data: scope.row.inWarehouseList
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "50",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品编号",
                                  prop: "specs.productNumber",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品名称",
                                  prop: "main.productName",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格型号",
                                  prop: "specs.specificationModel",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "注册证号",
                                  prop: "main.registrationNumber",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生产许可证号",
                                  prop: "manuLicenseCode",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单位",
                                  prop: "specs.unit",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "品牌",
                                  prop: "main.brandName",
                                  width: "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "入库数量",
                                  prop: "inWarehouseQuantity",
                                  width: "100",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "批号",
                                  prop: "batchNumber",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "序列号",
                                  prop: "trackingCode",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "备注",
                                  prop: "remark",
                                  "min-width": "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailForm)
                    },
                    label: "序号",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "warehouseName",
                    label: "仓库名称",
                    "min-width": "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturerName",
                    label: "供应商",
                    "min-width": "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturerName",
                    label: "入库单类型",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.inWarehouseTypeFormatter(
                                  row.inWarehouseType
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inWarehouseStatus",
                    label: "状态",
                    align: "center",
                    width: "100",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.inWarehouseStatusFormatter(
                                  row.inWarehouseStatus
                                )
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "orderNumber",
                    label: "入库单号",
                    "min-width": "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.viewInWarehouseOrder(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.orderNumber))]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "quantitySum",
                    label: "入库数量",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inWarehouseName",
                    label: "入库人",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inWarehouseTime",
                    label: "入库时间",
                    align: "center",
                    width: "150",
                    "show-overflow-tooltip": ""
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.detailForm.total,
                "page-sizes": [5, 10, 20, 50, 100],
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: {
                "current-change": _vm.detailPageChange,
                "size-change": _vm.detailSizeChange
              }
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }