//
//
//
//
import FormComponent from './form.vue';
export default {
  name: 'stock-modify-create',
  components: {
    FormComponent: FormComponent
  },
  data: function data() {
    return {
      id: 0
    };
  }
};