var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "1"
        ? _c("FormComponent1", {
            ref: "FormComponentRef1",
            attrs: { type: _vm.type, id: _vm.id }
          })
        : _vm._e(),
      _vm.type === "2"
        ? _c("FormComponent2", {
            ref: "FormComponentRef2",
            attrs: { type: _vm.type, id: _vm.id }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }