import _objectSpread from "D:/wwwroot/ERP/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import util from '@/libs/util.js';
import { mapState, mapMutations } from 'vuex';
export default {
  methods: _objectSpread(_objectSpread({}, mapMutations({
    searchToggle: 'd2admin/search/toggle'
  })), {}, {
    handleMenuSelect: function handleMenuSelect(index, indexPath) {
      var _this = this;

      if (/^d2-menu-empty-\d+$/.test(index) || index === undefined) {
        this.$message.warning('临时菜单');
      } else if (/^https:\/\/|http:\/\//.test(index)) {
        util.open(index);
      } else {
        this.$router.push({
          path: index
        });
        this.$nextTick(function () {
          _this.$emit('click', {
            index: index,
            indexPath: indexPath
          });
        });
      }
    }
  })
};