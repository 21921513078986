import _objectSpread from "D:/wwwroot/ERP/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/quality',
  name: 'quality',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'complain',
    name: 'quality-complain',
    component: _import('quality/complain'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '投诉处理',
      cache: true
    }),
    props: true
  }, {
    path: 'complain/add',
    name: 'quality-complain-add',
    component: _import('quality/complain/add.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增投诉',
      cache: true
    }),
    props: true
  }, {
    path: 'complain/detail/:id',
    name: 'quality-complain-detail',
    component: _import('quality/complain/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '投诉详情',
      cache: true
    }),
    props: true
  }, {
    path: 'recall_service',
    name: 'quality-recall-service',
    component: _import('quality/recall_service'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '召回服务',
      cache: true
    }),
    props: true
  }, {
    path: 'recall_service/detail/:id',
    name: 'quality-recall-service-detail',
    component: _import('quality/recall_service/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '召回单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'recall_service/add',
    name: 'quality-recall-service-add',
    component: _import('quality/recall_service/add'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增召回单',
      cache: true
    }),
    props: true
  }, {
    path: 'sales_service',
    name: 'quality-sales-service',
    component: _import('quality/sales_service'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '售后服务',
      cache: true
    }),
    props: true
  }, {
    path: 'sales_service/add',
    name: 'quality-sales-service-add',
    component: _import('quality/sales_service/add'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增售后服务单',
      cache: true
    }),
    props: true
  }, {
    path: 'sales_service/detail/:id',
    name: 'quality-sales-service-detail',
    component: _import('quality/sales_service/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '售后服务单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'inspection',
    name: 'quality-inspection',
    component: _import('quality/inspection'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '巡检记录',
      cache: true
    }),
    props: true
  }, {
    path: 'inspection/detail/:id',
    name: 'quality-inspection-detail',
    component: _import('quality/inspection/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '巡检记录详情',
      cache: true
    }),
    props: true
  }, {
    path: 'inspection/add',
    name: 'quality-inspection-add',
    component: _import('quality/inspection/add'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增巡检记录',
      cache: true
    }),
    props: true
  }, {
    path: 'defective/add',
    name: 'quality-defective-add',
    component: _import('quality/defective/add'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增不合格品处理单',
      cache: true
    }),
    props: true
  }, {
    path: 'defective/detail/:id',
    name: 'quality-defective-detail',
    component: _import('quality/defective/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '不合格品处理单详情',
      cache: true
    }),
    props: true
  }, {
    path: 'defective',
    name: 'quality-defective',
    component: _import('quality/defective'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '不合格品处理',
      cache: true
    }),
    props: true
  }, {
    path: 'defective/return',
    name: 'quality-defective-return',
    component: _import('quality/defective/return/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '不合格品退货记录',
      cache: true
    }),
    props: true
  }, {
    path: 'defective/return/detail/:id',
    name: 'quality-defective-return-detail',
    component: _import('quality/defective/return/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '不合格品退货详情',
      cache: true
    }),
    props: true
  }, {
    path: 'destroy',
    name: 'quality-destroy',
    component: _import('quality/destroy'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销毁记录',
      cache: true
    }),
    props: true
  }, {
    path: 'destroy/detail/:id',
    name: 'quality-destroy-detail',
    component: _import('quality/destroy/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销毁记录详情',
      cache: true
    }),
    props: true
  }, {
    path: 'aes',
    name: 'quality-aes',
    component: _import('quality/aes'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '不良事件监测调查报告',
      cache: true
    }),
    props: true
  }, {
    path: 'aes/detail/:id',
    name: 'quality-aes-detail',
    component: _import('quality/aes/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '报告详情',
      cache: true
    }),
    props: true
  }, {
    path: 'aes/add',
    name: 'quality-aes-add',
    component: _import('quality/aes/add'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增报告',
      cache: true
    }),
    props: true
  }, {
    path: 'weather',
    name: 'quality-weather',
    component: _import('quality/weather'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '温湿度记录',
      cache: true
    }),
    props: true
  }, {
    path: 'weather/create',
    name: 'quality-weather-create',
    component: _import('quality/weather/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '新增温湿度记录',
      cache: true
    }),
    props: true
  }, {
    path: 'weather/detail/:id',
    name: 'quality-weather-detail',
    component: _import('quality/weather/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '温湿度记录详情',
      cache: true
    }),
    props: true
  }]
};