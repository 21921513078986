var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("内部信息管理")]),
              _c("el-breadcrumb-item", [_vm._v("仓库管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary"
                  },
                  on: { click: _vm.warehouseAdd }
                },
                [_vm._v("新增仓库")]
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center"
                }
              },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: true,
                      model: _vm.pageForm,
                      size: "small",
                      "label-suffix": ":"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入仓库名称",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "name", $$v)
                            },
                            expression: "pageForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库编码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入仓库编码",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.idkey,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "idkey", $$v)
                            },
                            expression: "pageForm.idkey"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.searchSubmit }
                          },
                          [_vm._v("查询")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { color: "#f00", "font-size": "12px" } },
                  [
                    _vm._v(
                      "最大活跃仓库数：" +
                        _vm._s(
                          _vm.authorization.activeRepositories +
                            _vm.authorization.activeRepositoriesExt
                        )
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.tableData,
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              border: "",
              "highlight-current-row": "",
              stripeDELETE: "",
              size: "mini"
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.pageForm)
                },
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "仓库名称",
                "min-width": "200",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "warehouseKeepersName",
                label: "负责人",
                "min-width": "200",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm._v(
                        " " + _vm._s(row.warehouseKeepersName.toString()) + " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "idkey",
                label: "仓库编码",
                width: "200",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isActive",
                label: "是否活跃仓库",
                width: "100",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#409EFF",
                          "inactive-color": "#999",
                          "active-value": 1,
                          "inactive-value": 2
                        },
                        on: {
                          change: function($event) {
                            return _vm.isActiveSwitch(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.isActive,
                          callback: function($$v) {
                            _vm.$set(scope.row, "isActive", $$v)
                          },
                          expression: "scope.row.isActive"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "status",
                label: "状态",
                width: "100",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#409EFF",
                          "inactive-color": "#999",
                          "active-value": 1,
                          "inactive-value": 0
                        },
                        on: {
                          change: function($event) {
                            return _vm.warehouseStatusSwitch(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                width: "100",
                align: "center",
                fixed: "right"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "control-column" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.warehouseEdit(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.warehouseDelete(scope.row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next, total",
              align: "center",
              total: _vm.pageForm.total,
              "current-page": _vm.pageForm.page,
              "page-size": _vm.pageForm.pageSize
            },
            on: { "current-change": _vm.pageChange }
          })
        ],
        1
      ),
      _c("WarehouseEditorFormComponent", {
        ref: "WarehouseEditorFormComponent",
        on: { reload: _vm.loadData }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }