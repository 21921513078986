import _objectSpread from "D:/wwwroot/ERP/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/tools',
  name: 'tools',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'transaction',
    name: 'tools-transaction-index',
    component: _import('tools/transaction/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '工具交易'
    }),
    props: true
  }, {
    path: 'transfer',
    name: 'tools-transfer-index',
    component: _import('tools/transfer/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '工具借调'
    }),
    props: true
  }, {
    path: 'package',
    name: 'tools-package-index',
    component: _import('tools/package/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '手术工具包'
    }),
    props: true
  }]
};