var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.profile
    ? _c(
        "div",
        [
          _c(
            "el-dropdown",
            { staticClass: "d2-mr" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "align-items": "flex-end",
                    "margin-left": "20px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        "text-align": "right",
                        "font-size": "12px"
                      }
                    },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.profile.nickName))]),
                      _c("div", [_vm._v(_vm._s(_vm.profile.organizationName))])
                    ]
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
                ]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _vm.profile.organizationList &&
                  _vm.profile.organizationList.length > 1
                    ? _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function($event) {
                              return _vm.switchOrganization.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _c("d2-icon", {
                            staticClass: "d2-mr-5",
                            attrs: { name: "exchange" }
                          }),
                          _vm._v(" 切换组织 ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function($event) {
                          return _vm.changePassword.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "key" }
                      }),
                      _vm._v(" 修改密码 ")
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function($event) {
                          return _vm.settings.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "gear" }
                      }),
                      _vm._v(" 系统设置 ")
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function($event) {
                          return _vm.logOff.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "power-off" }
                      }),
                      _vm._v(" 退出登录 ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.dialogVisible
                ? _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "切换组织",
                        visible: _vm.dialogVisible,
                        width: "400px",
                        "before-close": _vm.dialogClose,
                        "modal-append-to-body": false
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.dialogVisible = $event
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "organizations" }, [
                        _c(
                          "div",
                          { staticClass: "list" },
                          _vm._l(_vm.organizationList, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "item",
                                on: {
                                  click: function($event) {
                                    return _vm.organizationClick(item)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "intro" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.name))
                                  ]),
                                  _c("div", { staticClass: "user" }, [
                                    _vm._v("姓名：" + _vm._s(item.user_name))
                                  ])
                                ]),
                                _c("div", { staticClass: "button" }, [
                                  _c("div", [_vm._v("进入组织")])
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.passwordDialogVisible
                ? _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "修改密码",
                        visible: _vm.passwordDialogVisible,
                        width: "400px",
                        "before-close": _vm.passwordDialogClose,
                        "modal-append-to-body": false
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.passwordDialogVisible = $event
                        }
                      }
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "passwordForm",
                          attrs: {
                            model: _vm.passwordForm,
                            rules: _vm.passwordFormRules,
                            "label-width": "100px",
                            size: "small"
                          },
                          nativeOn: {
                            submit: function($event) {
                              $event.preventDefault()
                            },
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.passwordFormSubmit.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _c("el-form-item", { attrs: { label: "手机号码" } }, [
                            _vm._v(" " + _vm._s(_vm.profile.mobile) + " ")
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "短信码", prop: "code" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入短信码",
                                    maxlength: "6"
                                  },
                                  model: {
                                    value: _vm.passwordForm.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.passwordForm, "code", $$v)
                                    },
                                    expression: "passwordForm.code"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { slot: "append" },
                                      on: { click: _vm.sendCode },
                                      slot: "append"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.codeTimer > 0
                                            ? _vm.codeTimer + "秒"
                                            : "发送"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "新密码", prop: "newPassword" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "show-password": "",
                                  "auto-complete": "new-password"
                                },
                                model: {
                                  value: _vm.passwordForm.newPassword,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.passwordForm,
                                      "newPassword",
                                      $$v
                                    )
                                  },
                                  expression: "passwordForm.newPassword"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "确认新密码",
                                prop: "newPassword2"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "show-password": "",
                                  "auto-complete": "new-password"
                                },
                                model: {
                                  value: _vm.passwordForm.newPassword2,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.passwordForm,
                                      "newPassword2",
                                      $$v
                                    )
                                  },
                                  expression: "passwordForm.newPassword2"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "prevent-re-click",
                                      rawName: "v-prevent-re-click"
                                    }
                                  ],
                                  attrs: { type: "primary" },
                                  on: { click: _vm.passwordFormSubmit }
                                },
                                [_vm._v("确认修改")]
                              ),
                              _c(
                                "el-button",
                                { on: { click: _vm.passwordDialogClose } },
                                [_vm._v("取消")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }