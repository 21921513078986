import { request } from '@/api/_service.js';
export function listHospital(params) {
  return request({
    url: '/erpFaOrgHospital/getFaOrgHospitalList',
    method: 'get',
    params: params
  });
}
export function findHospital(params) {
  return request({
    url: '/erpFaOrgHospital/findFaOrgHospital',
    method: 'get',
    params: params
  });
}
export function findHospitalYoutai(id) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/organizations/hospitals/' + id,
    method: 'get'
  });
}
export function createHospital(data) {
  return request({
    url: '/erpFaOrgHospital/createFaOrgHospital',
    method: 'post',
    data: data
  });
}
export function updateHospital(data) {
  return request({
    url: '/erpFaOrgHospital/updateFaOrgHospital',
    method: 'put',
    data: data
  });
}
export function updateFaOrgHospital2(data) {
  return request({
    url: '/erpFaOrgHospital/updateFaOrgHospital2',
    method: 'put',
    data: data
  });
}
export function deleteHospital(data) {
  return request({
    url: '/erpFaOrgHospital/deleteFaOrgHospital',
    method: 'delete',
    data: data
  });
}
export function deleteHospitalByIds(data) {
  return request({
    url: '/erpFaOrgHospital/deleteFaOrgHospitalByIds',
    method: 'delete',
    data: data
  });
}
export function findUserHosp(params) {
  return request({
    url: '/userHospErp/findUserHosp',
    method: 'get',
    params: params
  });
}
export function setUserHosp(data) {
  return request({
    url: '/userHospErp/setUserHosp',
    method: 'put',
    data: data
  });
} // 【erp】医院访问控制（库房管理）

export function findWareHosp(params) {
  return request({
    url: '/wareHospErp/findWareHosp',
    method: 'get',
    params: params
  });
}
export function setWareHosp(data) {
  return request({
    url: '/wareHospErp/setWareHosp',
    method: 'put',
    data: data
  });
}
export function createErpHospitalProduct(data) {
  return request({
    url: '/erpHospitalProductErp/createErpHospitalProduct',
    method: 'post',
    data: data
  });
}
export function updateErpHospitalProduct(data) {
  return request({
    url: '/erpHospitalProductErp/updateErpHospitalProduct',
    method: 'put',
    data: data
  });
}
export function deleteErpHospitalProduct(data) {
  return request({
    url: '/erpHospitalProductErp/deleteErpHospitalProduct',
    method: 'delete',
    data: data
  });
}
export function deleteErpHospitalProductByIds(data) {
  return request({
    url: '/erpHospitalProductErp/deleteErpHospitalProductByIds',
    method: 'delete',
    data: data
  });
}
export function findErpHospitalProduct(params) {
  return request({
    url: '/erpHospitalProductErp/findErpHospitalProduct',
    method: 'get',
    params: params
  });
}
export function listHospitalProduct(params) {
  return request({
    url: '/erpHospitalProductErp/getErpHospitalProductList',
    method: 'get',
    params: params
  });
}
export function listHospitalProductExport(params) {
  return request({
    url: '/erpHospitalProductErp/getErpHospitalProductList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function downloadHospitalProductTemplate(params) {
  return request({
    url: '/erpHospitalProductErp/hospitalProductDownloadTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 查询医院关联了哪些分类

export function findErpHospitalProductCate(params) {
  return request({
    url: '/erpHospitalProductErp/findErpHospitalProductCate',
    method: 'get',
    params: params
  });
} // 查询基础数据中的医院列表

export function getBaseHospitalList(params) {
  return request({
    url: '/erpFaOrgHospital/getBaseHospitalList',
    method: 'get',
    params: params
  });
} // 医院自动导入

export function createFaOrgHospitalByAdmin(data) {
  return request({
    url: '/erpFaOrgHospital/createFaOrgHospitalByAdmin',
    method: 'post',
    data: data
  });
}