var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "align-items": "center" } },
    [
      _c(
        "div",
        { staticStyle: { width: "450px" } },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-start",
                width: "1000",
                trigger: "click"
              },
              on: { show: _vm.popoverOnShow },
              model: {
                value: _vm.popoverVisible,
                callback: function($$v) {
                  _vm.popoverVisible = $$v
                },
                expression: "popoverVisible"
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.popoverAddToList }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.popoverLoading,
                          expression: "popoverLoading"
                        }
                      ],
                      ref: "popoverTableRef",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.popoverDataList,
                        border: "",
                        size: "mini"
                      },
                      on: { "row-click": _vm.popoverRowClick }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "40",
                          selectable: _vm.popoverSelectable,
                          fixed: ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          index: function(index) {
                            return _vm.indexMethod(index, _vm.popoverForm)
                          },
                          label: "序号",
                          width: "80",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productNumber",
                          label: "产品编号",
                          width: "150",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "batchNumber",
                          label: "产品批号",
                          width: "150",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "trackingCode",
                          label: "序列号",
                          width: "150",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "150",
                          prop: "",
                          label: "产地/品牌",
                          align: "center",
                          "show-overflow-tooltip": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  _vm._s(scope.row.productPlace) +
                                    "/" +
                                    _vm._s(scope.row.brandName)
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productName",
                          label: "物资名称",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          prop: "normalQuantity",
                          label: "库存可用数量",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          prop: "specificationModel",
                          label: "规格型号",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          prop: "unit",
                          label: "单位",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "200",
                          prop: "registrationNumber",
                          label: "注册证号",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          prop: "afterTaxPrice",
                          label: "成本价格",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          prop: "manufacturerDate",
                          label: "生产日期",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          prop: "validityDate",
                          label: "有效期",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "10px 0" } },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                          align: "center",
                          total: _vm.popoverForm.total,
                          "page-sizes": [5, 10, 15, 20, 30, 50, 100],
                          "page-size": _vm.popoverForm.pageSize,
                          "current-page": _vm.popoverForm.page
                        },
                        on: {
                          "size-change": _vm.popoverFormSizeChange,
                          "current-change": _vm.popoverFormPageChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("input", {
                ref: "scanInputBoxRef",
                staticClass: "bar-input",
                attrs: {
                  slot: "reference",
                  placeholder: "请扫描产品条码",
                  clearable: ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.barCodeSearch.apply(null, arguments)
                  }
                },
                slot: "reference"
              })
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "text-danger" }, [
        _vm._v(_vm._s(_vm.tipMessage))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }