var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "auto",
            size: "small",
            rules: _vm.formRules,
            "label-suffix": ":"
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "货架", prop: "shelfId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", filterable: "" },
                  model: {
                    value: _vm.form.shelfId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "shelfId", $$v)
                    },
                    expression: "form.shelfId"
                  }
                },
                _vm._l(_vm.shelvesList, function(item) {
                  return _c("el-option", {
                    key: item.ID,
                    attrs: { label: item.shelfName, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标签信息", prop: "labelInformation" } },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: "请输入IP地址，如：127.0.0.1",
                    placement: "top-start"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "标签信息" },
                    model: {
                      value: _vm.form.labelInformation,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "labelInformation", $$v)
                      },
                      expression: "form.labelInformation"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行", prop: "row" } },
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.form.row,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "row", $$v)
                  },
                  expression: "form.row"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "列", prop: "col" } },
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.form.col,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "col", $$v)
                  },
                  expression: "form.col"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "容量", prop: "capacity" } },
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.form.capacity,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "capacity", $$v)
                  },
                  expression: "form.capacity"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }