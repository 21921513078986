import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getBaseHospitalList, createFaOrgHospitalByAdmin } from '@/api/hospital.js';
export default {
  data: function data() {
    return {
      filedHospitalIds: [],
      hospitalList: [],
      selectHospitalList: [],
      selectedHospitalList: [],
      pageForm: {
        name: '',
        page: 1,
        pageSize: 10,
        total: 0,
        all_examine_status: true,
        hospitalName: '',
        abbreviation: '',
        ids: ''
      },
      tableLoading: false,
      centerDialogVisible: false,
      loading: false
    };
  },
  methods: {
    init: function init() {
      this.centerDialogVisible = true;
    },
    searchSubmit: function searchSubmit() {
      var that = this;

      if (that.tableLoading) {
        return;
      }

      var selectedIds = that.selectedHospitalList.map(function (item) {
        return item.id;
      });
      that.tableLoading = true;

      if (that.pageForm.name === undefined || that.pageForm.name === null || that.pageForm.name === '') {
        that.$message.error('请输入医院名称进行搜索');
        that.tableLoading = false;
        return;
      }

      if (that.pageForm.name.length < 2) {
        that.$message.error('医院名称长度至少为两个字符搜索');
        that.tableLoading = false;
        return;
      }

      if (that.pageForm.name.indexOf('医院') !== -1 && that.pageForm.name.length < 4) {
        that.$message.error('医院名称包含为【医院】二字时，请使用最少四个字符搜索');
        that.tableLoading = false;
        return;
      }

      if ((that.pageForm.name.indexOf('医') !== -1 || that.pageForm.name.indexOf('院') !== -1) && that.pageForm.name.length < 3) {
        that.$message.error('医院名称包含为【医】或【院】二字时，请使用最少三个字符搜索');
        that.tableLoading = false;
        return;
      }

      that.hospitalList = [];
      that.selectHospitalList = [];
      that.pageForm.ids = selectedIds.join(',');
      getBaseHospitalList(that.pageForm).then(function (res) {
        that.hospitalList = res.list;
        that.pageForm.total = res.total;
      }).finally(function () {
        that.tableLoading = false;
      });
    },
    selectedHospitalChange: function selectedHospitalChange(val) {
      var that = this;
      that.selectHospitalList = val;
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.pageForm.page = page;
      that.searchSubmit();
    },
    pageSizeChange: function pageSizeChange(pageSize) {
      var that = this;
      that.pageForm.pageSize = pageSize;
      that.searchSubmit();
    },
    importSelectedHospitalOneKey: function importSelectedHospitalOneKey() {
      var that = this;
      var selectedIds = that.selectedHospitalList.map(function (item) {
        return item.id;
      });

      if (selectedIds.length === 0) {
        that.$message.error('请先选择医院进行导入');
        return;
      }

      that.loading = true;
      createFaOrgHospitalByAdmin({
        ids: selectedIds
      }).then(function (res) {
        that.searchSubmit();
        that.filedHospitalIds = res.failedIds !== null && res.failedIds.length > 0 ? res.failedIds : [];

        if (that.filedHospitalIds.length === 0) {
          that.selectedHospitalList = [];
        } else {
          that.selectedHospitalList = that.selectedHospitalList.filter(function (item) {
            return that.filedHospitalIds.indexOf(item.id) !== -1;
          });
        }

        that.$notify({
          message: "\u6210\u529F\u5BFC\u5165\uFF1A".concat(res.successCount, "\u6761\uFF0C").concat(res.failCount, "\u6761\u5BFC\u5165\u5931\u8D25\uFF08\u533B\u9662\u5DF2\u5B58\u5728\uFF0C\u7EA2\u6846\u6807\u8BB0\uFF09"),
          type: 'info'
        });
      }).finally(function () {
        that.loading = false;
        that.$emit('reload');
      });
    },
    openDialog: function openDialog() {
      this.centerDialogVisible = true;
    },
    closeDialog: function closeDialog() {
      this.centerDialogVisible = false;
      this.tableLoading = false;
      this.loading = false;
      this.selectedHospitalList = [];
      this.hospitalList = [];
      this.filedHospitalIds = [];
      this.pageForm = {
        name: '',
        page: 1,
        pageSize: 10,
        total: 0,
        all_examine_status: true,
        hospitalName: '',
        abbreviation: '',
        ids: []
      };
    },
    selectedHospital: function selectedHospital(row) {
      if (this.selectedHospitalList.length >= 10) {
        this.$message.error('最多只能选择10个医院进行导入');
        return;
      }

      this.selectedHospitalList.push(row);
      this.selectedHospitalList = this.uniqueItems(this.selectedHospitalList);
      this.searchSubmit();
    },
    selectedHospitalMutily: function selectedHospitalMutily() {
      if (this.selectedHospitalList.length >= 10) {
        this.$message.error('最多只能选择10个医院进行导入');
        return;
      }

      if (this.selectHospitalList.length > 10) {
        this.$message.error('最多只能选择10个医院进行导入');
        return;
      }

      if (this.selectedHospitalList.length + this.selectHospitalList.length > 10) {
        this.$message.error('最多只能选择10个医院进行导入');
        return;
      }

      if (this.selectHospitalList != null && this.selectHospitalList.length > 0) {
        this.selectedHospitalList = this.selectedHospitalList.concat(this.selectHospitalList);
        this.selectedHospitalList = this.uniqueItems(this.selectedHospitalList);
      }

      this.searchSubmit();
    },
    disSelectHospital: function disSelectHospital(row) {
      this.selectedHospitalList = this.uniqueItems(this.selectedHospitalList);
      this.selectedHospitalList = this.selectedHospitalList.filter(function (item) {
        return item.id !== row.id;
      });
      this.filedHospitalIds = this.filedHospitalIds.filter(function (item) {
        return item !== row.id;
      });
      this.searchSubmit();
    },
    uniqueItems: function uniqueItems(list) {
      return list.filter(function (item, index, self) {
        return index === self.findIndex(function (i) {
          return i.id === item.id // 基于id去重
          ;
        });
      });
    }
  }
};