import { request } from '@/api/_service.js';
import qs from 'qs'; // 有台配送单创建

export function expressSend(data) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/logistics/express_send',
    method: 'post',
    data: data
  });
}
export function expressSupplySend(data) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/logistics/express_supply_send',
    method: 'post',
    data: data
  });
} // 获取有台用户列表

export function listUsers(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/organizations/all_user',
    method: 'get',
    params: params
  });
}
export function listDeliveryUsers(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/organizations/delivery_users',
    method: 'get',
    params: params
  });
} // 获取有台跟台用户列表

export function listFollowUsers(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/organizations/follow_table_users',
    method: 'get',
    params: params
  });
} // 获取有台医院列表

export function listHospital(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/organizations/hospital_list_all',
    method: 'get',
    params: params
  });
} // 获取有台科室列表

export function listDepartment(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/organizations/departments_list',
    method: 'get',
    params: params
  });
} // 获取有台科室列表

export function listDoctor(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/organizations/doctors_list',
    method: 'get',
    params: params
  });
} // 获取有台仓库列表

export function listWarehouse(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/warehouses/warehouses_list_all',
    method: 'get',
    params: params
  });
}
export function listWarehouseUser(params, warehouseId) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/warehouses/' + warehouseId,
    method: 'get',
    params: params
  });
} // 获取有台三方物流列表

export function listExpress(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/express/index',
    method: 'get',
    params: params
  });
} // 获取有台仓库详情

export function findWarehouse(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/warehouses/warehouses_detail',
    method: 'get',
    params: params
  });
} // 获取有台医院详情

export function findHospital(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/organizations/hospitals_detail',
    method: 'get',
    params: params
  });
} // 获取有台订单详情

export function findOrderByNumber(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/order_detail/find_order_by_number',
    method: 'get',
    params: params
  });
} // 获取有台订单收益预估数据

export function predictFee(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/logistics/predict_fee',
    method: 'get',
    params: params
  });
}
export function predictFeeRestock(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/logistics/predict_fee_supply',
    method: 'get',
    params: params
  });
} // 获取绑定子公司列表

export function listSubsidiary(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/product_brands/org_company_list',
    method: 'get',
    params: params
  });
}
export function salesUpdate(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/order_sms/erp_sales_update',
    method: 'get',
    params: params
  });
} // 同步撤消请求到有台手术

export function undoOut(data) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/order_sms/undo_out',
    method: 'post',
    data: data
  });
} // 取消配送

export function cancelDelivery(data) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/logistics/cancel_delivery',
    method: 'delete',
    data: data
  });
}
export function orderProcess(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/orders/process_detailv210',
    method: 'get',
    params: params
  });
} // 手术流程

export function surgeryProcess(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/orders_relax/order_status_erp',
    method: 'get',
    params: params
  });
} // 手术分类

export function listSurgeryCategory(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/organizations/surgeries_category_all',
    method: 'get',
    params: params
  });
} // 手术列表

export function listSurgery(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/organizations/surgeries',
    method: 'get',
    params: params
  });
} // 获取有台提醒设置

export function remindConfigUser(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/organizations/remind_config_user',
    method: 'get',
    params: params
  });
} // 获取有台销售明细

export function listSaleDetailYoutai(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/order_detail',
    method: 'get',
    params: params
  });
}
export function listAudioAttach(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API2 + '/order/get_attach',
    method: 'get',
    params: params
  });
}
export function listSettleHistory(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/settle/settle_history',
    method: 'get',
    params: params
  });
}
export function listSettleHistoryExport(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/settle/settle_history',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listPayChannelType(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/api/payment_channel/payment_channel_list',
    method: 'get',
    params: params
  });
}
export function listOrganization(params) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/product_brands/org_company_list',
    method: 'get',
    params: params
  });
} // 驳回有台归档请求

export function rejectSaleDetail(data) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/orders/reject_sale_detail',
    method: 'PATCH',
    data: data
  });
} // 通过有台归档请求

export function saleDetailSubmit(data) {
  return request({
    url: process.env.VUE_APP_YOUTAI_API + '/orders/' + data.id + '/sale_detail_submit',
    method: 'PATCH',
    data: data
  });
}
/**
 * 修改提成明细的金额
 *
 * @param {Object} data 需要更新的销售结算信息对象 { id: 销售结算明细ID, settleAmount: 需要更新的金额 }
 * @returns {Promise} 返回一个Promise对象，包含请求的结果
 */

export function salesUpdateSettle(data) {
  return request({
    url: 'sale/salesUpdateSettle',
    method: 'PUT',
    data: data
  });
}
export function salesSettlePercentage(data) {
  return request({
    url: '/sale/salesSettlePercentage',
    method: 'put',
    data: data
  });
}