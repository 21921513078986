var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1280px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            {
              staticStyle: { "border-right": "solid 1px #e6e6e6" },
              attrs: { width: "200px" }
            },
            [
              _c(
                "el-menu",
                {
                  staticStyle: { "border-right": "none" },
                  attrs: { "default-active": _vm.itemFormStep.toString() },
                  on: { select: _vm.menuSelect }
                },
                [
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v("生产商信息")
                    ])
                  ]),
                  _c("el-menu-item", { attrs: { index: "2" } }, [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v("营业执照信息")
                    ])
                  ]),
                  _c("el-menu-item", { attrs: { index: "3" } }, [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v("经营许可证信息")
                    ])
                  ]),
                  _c("el-menu-item", { attrs: { index: "4" } }, [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v("生产许可证信息")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-main",
            { staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-steps",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: {
                    active: _vm.itemFormStep,
                    simple: "",
                    "process-status": "wait"
                  }
                },
                [
                  _c("el-step", { attrs: { title: "第一步" } }),
                  _c("el-step", { attrs: { title: "第二步" } }),
                  _c("el-step", { attrs: { title: "第三步" } }),
                  _c("el-step", { attrs: { title: "第四步" } })
                ],
                1
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  ref: "baseForm",
                  style: _vm.itemFormStep !== 1 ? "display: none" : "",
                  attrs: {
                    model: _vm.baseForm,
                    rules: _vm.baseFormRules,
                    "label-width": "130px",
                    size: "mini",
                    disabled: _vm.readonly,
                    "label-suffix": ":"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.baseFormSubmit.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_business_license",
                              attrs: {
                                label: "营业执照注册号",
                                prop: "business_license"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入营业执照注册号" },
                                model: {
                                  value: _vm.baseForm.business_license,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.baseForm,
                                      "business_license",
                                      $$v
                                    )
                                  },
                                  expression: "baseForm.business_license"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_name",
                              attrs: { label: "生产商名称", prop: "name" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入生产商名称",
                                  maxlength: "50"
                                },
                                on: { change: _vm.toPinyinCode },
                                model: {
                                  value: _vm.baseForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "name", $$v)
                                  },
                                  expression: "baseForm.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_short_name",
                              attrs: { label: "生产商简称", prop: "short_name" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入生产商简称",
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.baseForm.short_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "short_name", $$v)
                                  },
                                  expression: "baseForm.short_name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "拼音码", prop: "pinyin_code" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入拼音码",
                                  maxlength: "200"
                                },
                                model: {
                                  value: _vm.baseForm.pinyin_code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "pinyin_code", $$v)
                                  },
                                  expression: "baseForm.pinyin_code"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_duty_paragraph",
                              attrs: { label: "税号", prop: "duty_paragraph" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入税号",
                                  maxlength: "100"
                                },
                                model: {
                                  value: _vm.baseForm.duty_paragraph,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.baseForm,
                                      "duty_paragraph",
                                      $$v
                                    )
                                  },
                                  expression: "baseForm.duty_paragraph"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_legal_person",
                              attrs: { label: "法人", prop: "legal_person" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入法人",
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.baseForm.legal_person,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "legal_person", $$v)
                                  },
                                  expression: "baseForm.legal_person"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_prov_city_area",
                              attrs: { label: "详细注册地址" }
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "flex-wrap": "wrap" },
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "baseForm_province_name",
                                          staticStyle: {
                                            "margin-bottom": "5px"
                                          },
                                          attrs: {
                                            label: "",
                                            prop: "province_name"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请选择省份"
                                              },
                                              on: {
                                                change: _vm.provinceChange
                                              },
                                              model: {
                                                value:
                                                  _vm.baseForm.province_name,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.baseForm,
                                                    "province_name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "baseForm.province_name"
                                              }
                                            },
                                            _vm._l(_vm.provinceList, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.ID,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.name
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "baseForm_city_name",
                                          staticStyle: {
                                            margin: "0 5px 5px 5px"
                                          },
                                          attrs: {
                                            label: "",
                                            prop: "city_name"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请选择城市"
                                              },
                                              on: { change: _vm.cityChange },
                                              model: {
                                                value: _vm.baseForm.city_name,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.baseForm,
                                                    "city_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "baseForm.city_name"
                                              }
                                            },
                                            _vm._l(_vm.cityList, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.ID,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.name
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "baseForm_district_name",
                                          staticStyle: {
                                            "margin-bottom": "5px"
                                          },
                                          attrs: {
                                            label: "",
                                            prop: "district_name"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请选择区/县"
                                              },
                                              model: {
                                                value:
                                                  _vm.baseForm.district_name,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.baseForm,
                                                    "district_name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "baseForm.district_name"
                                              }
                                            },
                                            _vm._l(_vm.areaList, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.ID,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.name
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "baseForm_address",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { label: "", prop: "address" }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入详细地址",
                                              maxlength: "550"
                                            },
                                            model: {
                                              value: _vm.baseForm.address,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.baseForm,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression: "baseForm.address"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_place_business",
                              attrs: {
                                label: "办公地址",
                                prop: "place_business"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入办公地址",
                                  maxlength: "500"
                                },
                                model: {
                                  value: _vm.baseForm.place_business,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.baseForm,
                                      "place_business",
                                      $$v
                                    )
                                  },
                                  expression: "baseForm.place_business"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_nature_business",
                              attrs: {
                                label: "经营范围",
                                prop: "nature_business"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 3,
                                  placeholder: "请输入经营范围",
                                  maxlength: "2000"
                                },
                                model: {
                                  value: _vm.baseForm.nature_business,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.baseForm,
                                      "nature_business",
                                      $$v
                                    )
                                  },
                                  expression: "baseForm.nature_business"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_bank",
                              attrs: { label: "开户银行", prop: "bank" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入开户银行",
                                  maxlength: "150"
                                },
                                model: {
                                  value: _vm.baseForm.bank,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "bank", $$v)
                                  },
                                  expression: "baseForm.bank"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_bank_account",
                              attrs: { label: "银行账号", prop: "bank_account" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入银行账号",
                                  maxlength: "100"
                                },
                                model: {
                                  value: _vm.baseForm.bank_account,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "bank_account", $$v)
                                  },
                                  expression: "baseForm.bank_account"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_contacts",
                              attrs: { label: "联系人", prop: "contacts" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系人",
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.baseForm.contacts,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "contacts", $$v)
                                  },
                                  expression: "baseForm.contacts"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_contact_number",
                              attrs: {
                                label: "联系电话",
                                prop: "contact_number"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系电话",
                                  maxlength: "15"
                                },
                                model: {
                                  value: _vm.baseForm.contact_number,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.baseForm,
                                      "contact_number",
                                      $$v
                                    )
                                  },
                                  expression: "baseForm.contact_number"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_payment_method",
                              attrs: {
                                label: "付款方式",
                                prop: "payment_method"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择付款方式"
                                  },
                                  model: {
                                    value: _vm.baseForm.payment_method,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.baseForm,
                                        "payment_method",
                                        $$v
                                      )
                                    },
                                    expression: "baseForm.payment_method"
                                  }
                                },
                                _vm._l(_vm.paymentList, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_mobile_phone",
                              attrs: { label: "移动电话", prop: "mobile_phone" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入移动电话",
                                  maxlength: "20"
                                },
                                model: {
                                  value: _vm.baseForm.mobile_phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "mobile_phone", $$v)
                                  },
                                  expression: "baseForm.mobile_phone"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_fax",
                              attrs: { label: "传真", prop: "fax" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入传真",
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.baseForm.fax,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "fax", $$v)
                                  },
                                  expression: "baseForm.fax"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_postal_code",
                              attrs: { label: "邮政编码", prop: "postal_code" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入邮政编码",
                                  maxlength: "20"
                                },
                                model: {
                                  value: _vm.baseForm.postal_code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "postal_code", $$v)
                                  },
                                  expression: "baseForm.postal_code"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_email",
                              attrs: { label: "电子邮件", prop: "email" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入电子邮件",
                                  maxlength: "100"
                                },
                                model: {
                                  value: _vm.baseForm.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "email", $$v)
                                  },
                                  expression: "baseForm.email"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_qq",
                              attrs: { label: "QQ", prop: "qq" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入QQ",
                                  maxlength: "15"
                                },
                                model: {
                                  value: _vm.baseForm.qq,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "qq", $$v)
                                  },
                                  expression: "baseForm.qq"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_website",
                              attrs: { label: "网址", prop: "website" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入网址",
                                  maxlength: "250"
                                },
                                model: {
                                  value: _vm.baseForm.website,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "website", $$v)
                                  },
                                  expression: "baseForm.website"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.readonly
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { "label-width": "0", align: "center" }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "medium",
                                        type: "primary"
                                      },
                                      on: { click: _vm.baseFormSubmit }
                                    },
                                    [_vm._v("下一步")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  ref: "licenseForm",
                  style: _vm.itemFormStep !== 2 ? "display: none" : "",
                  attrs: {
                    model: _vm.licenseForm,
                    rules: _vm.licenseFormRules,
                    "label-width": "130px",
                    size: "mini",
                    disabled: _vm.readonly,
                    "label-suffix": ":"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.licenseFormSubmit.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-wrap": "wrap" },
                      attrs: { type: "flex" }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_license_no",
                              attrs: {
                                label: "营业执照编号",
                                prop: "license_no"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入营业执照编号",
                                  maxlength: "100"
                                },
                                model: {
                                  value: _vm.licenseForm.license_no,
                                  callback: function($$v) {
                                    _vm.$set(_vm.licenseForm, "license_no", $$v)
                                  },
                                  expression: "licenseForm.license_no"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_name",
                              attrs: { label: "企业名称" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入企业名称",
                                  disabled: true,
                                  maxlength: "150"
                                },
                                model: {
                                  value: _vm.baseForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "name", $$v)
                                  },
                                  expression: "baseForm.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_short_name",
                              attrs: { label: "企业简称", prop: "short_name" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入企业简称",
                                  disabled: true,
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.baseForm.short_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "short_name", $$v)
                                  },
                                  expression: "baseForm.short_name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_enterprise_type",
                              attrs: {
                                label: "企业类型",
                                prop: "enterprise_type"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择企业类型"
                                  },
                                  model: {
                                    value: _vm.licenseForm.enterprise_type,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.licenseForm,
                                        "enterprise_type",
                                        $$v
                                      )
                                    },
                                    expression: "licenseForm.enterprise_type"
                                  }
                                },
                                _vm._l(_vm.enterpriseTypeList, function(item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: { label: item, value: item }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_legal_person",
                              attrs: { label: "法人代表" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入法人代表",
                                  disabled: true,
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.baseForm.legal_person,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "legal_person", $$v)
                                  },
                                  expression: "baseForm.legal_person"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_registered_capital",
                              attrs: {
                                label: "注册资本",
                                prop: "registered_capital"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入注册资本",
                                  maxlength: "30"
                                },
                                model: {
                                  value: _vm.licenseForm.registered_capital,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.licenseForm,
                                      "registered_capital",
                                      $$v
                                    )
                                  },
                                  expression: "licenseForm.registered_capital"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_address",
                              attrs: { label: "注册地址" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入注册地址",
                                  disabled: true,
                                  maxlength: "550"
                                },
                                model: {
                                  value: _vm.baseForm.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "address", $$v)
                                  },
                                  expression: "baseForm.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_establishment_time",
                              attrs: {
                                label: "成立时间",
                                prop: "establishment_time"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择成立时间"
                                },
                                model: {
                                  value: _vm.licenseForm.establishment_time,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.licenseForm,
                                      "establishment_time",
                                      $$v
                                    )
                                  },
                                  expression: "licenseForm.establishment_time"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_registration_authority",
                              attrs: {
                                label: "登记机关",
                                prop: "registration_authority"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入登记机关",
                                  maxlength: "300"
                                },
                                model: {
                                  value: _vm.licenseForm.registration_authority,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.licenseForm,
                                      "registration_authority",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "licenseForm.registration_authority"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_registration_time",
                              attrs: {
                                label: "登记时间",
                                prop: "registration_time"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择登记时间"
                                },
                                model: {
                                  value: _vm.licenseForm.registration_time,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.licenseForm,
                                      "registration_time",
                                      $$v
                                    )
                                  },
                                  expression: "licenseForm.registration_time"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "营业期限" } },
                            [
                              _c(
                                "el-row",
                                { attrs: { type: "flex", gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 9 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "licenseForm_start_time_business",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            "label-width": "0",
                                            prop: "start_time_business"
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "开始日期",
                                              pickerOptions:
                                                _vm.startTimeBusinessOptions,
                                              disabled:
                                                _vm.licenseForm.long_term === 1
                                            },
                                            model: {
                                              value:
                                                _vm.licenseForm
                                                  .start_time_business,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.licenseForm,
                                                  "start_time_business",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "licenseForm.start_time_business"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _vm._v("至")
                                  ]),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 9 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "licenseForm_end_time_business",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            "label-width": "0",
                                            prop: "end_time_business"
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "结束日期",
                                              pickerOptions:
                                                _vm.endTimeBusinessOptions,
                                              disabled:
                                                _vm.licenseForm.long_term === 1
                                            },
                                            model: {
                                              value:
                                                _vm.licenseForm
                                                  .end_time_business,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.licenseForm,
                                                  "end_time_business",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "licenseForm.end_time_business"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "licenseForm_long_term",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            "label-width": "0",
                                            prop: "long_term"
                                          }
                                        },
                                        [
                                          _c("el-checkbox", {
                                            attrs: {
                                              label: "长期",
                                              "true-label": 1,
                                              "false-label": 0
                                            },
                                            on: { change: _vm.longTermChange },
                                            model: {
                                              value: _vm.licenseForm.long_term,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.licenseForm,
                                                  "long_term",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "licenseForm.long_term"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 18 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      key: "licenseForm_extension_time",
                                      attrs: {
                                        label: "延期时间",
                                        prop: "extension_time"
                                      }
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择延期时间",
                                          disabled:
                                            _vm.licenseForm.whether_postpone !==
                                            "1"
                                        },
                                        model: {
                                          value: _vm.licenseForm.extension_time,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.licenseForm,
                                              "extension_time",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenseForm.extension_time"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      key: "licenseForm_whether_postpone",
                                      staticStyle: { "margin-bottom": "0" },
                                      attrs: {
                                        "label-width": "0",
                                        prop: "whether_postpone"
                                      }
                                    },
                                    [
                                      _c("el-checkbox", {
                                        attrs: {
                                          label: "是否延期",
                                          "true-label": "1",
                                          "false-label": "0"
                                        },
                                        model: {
                                          value:
                                            _vm.licenseForm.whether_postpone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.licenseForm,
                                              "whether_postpone",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "licenseForm.whether_postpone"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_extension_certificate_no",
                              attrs: {
                                label: "延期证号",
                                prop: "extension_certificate_no"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入延期证号",
                                  disabled:
                                    _vm.licenseForm.whether_postpone !== "1",
                                  maxlength: "300"
                                },
                                model: {
                                  value:
                                    _vm.licenseForm.extension_certificate_no,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.licenseForm,
                                      "extension_certificate_no",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "licenseForm.extension_certificate_no"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "baseForm_nature_business",
                              attrs: { label: "经营范围" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 3,
                                  placeholder: "请输入经营范围",
                                  disabled: true,
                                  maxlength: "2000"
                                },
                                model: {
                                  value: _vm.baseForm.nature_business,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.baseForm,
                                      "nature_business",
                                      $$v
                                    )
                                  },
                                  expression: "baseForm.nature_business"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_business_license_photo",
                              attrs: {
                                label: "营业执照照片",
                                prop: "business_license_photo"
                              }
                            },
                            [
                              _c(
                                "div",
                                { on: { drop: _vm.reportDrop } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        "align-items": "flex-start"
                                      },
                                      attrs: {
                                        action: _vm.uploadURL,
                                        headers: _vm.uploadToken,
                                        "before-upload": function(file) {
                                          return _vm.uploadBefore(file)
                                        },
                                        "on-remove": function(file, fileList) {
                                          return _vm.uploadRemove(
                                            file,
                                            fileList,
                                            "licenseUploadFileList"
                                          )
                                        },
                                        "on-success": function(
                                          res,
                                          file,
                                          fileList
                                        ) {
                                          return _vm.uploadSuccess(
                                            res,
                                            file,
                                            fileList,
                                            "licenseUploadFileList"
                                          )
                                        },
                                        "on-error": _vm.uploadError,
                                        "on-preview": _vm.uploadPreview,
                                        accept: _vm.uploadFileType.toString(),
                                        "on-exceed": _vm.uploadExceed,
                                        limit: 10,
                                        "show-file-list": true,
                                        "file-list": _vm.licenseUploadFileList,
                                        drag: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          }
                                        },
                                        [_vm._v("点击上传")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip"
                                        },
                                        [
                                          _vm._v(
                                            "只能上传" +
                                              _vm._s(
                                                _vm.uploadFileType
                                                  .toString()
                                                  .toUpperCase()
                                                  .replace(/\./g, "")
                                                  .replace(/,/g, "/")
                                              ) +
                                              "类型文件，且单个文件大小不超过20MB，最多允许上传10个附件"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          !_vm.readonly
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { "label-width": "0", align: "center" }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "medium",
                                        type: "primary"
                                      },
                                      on: { click: _vm.formBack }
                                    },
                                    [_vm._v("上一步")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "medium",
                                        type: "primary"
                                      },
                                      on: { click: _vm.licenseFormSubmit }
                                    },
                                    [_vm._v("下一步")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  ref: "certForm",
                  style: _vm.itemFormStep !== 3 ? "display: none" : "",
                  attrs: {
                    model: _vm.certForm,
                    rules: _vm.certFormRules,
                    "label-width": "130px",
                    size: "mini",
                    disabled: _vm.readonly,
                    "label-suffix": ":"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.certFormSubmit.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_business_license_no",
                              attrs: {
                                label: "经营许可证编号",
                                prop: "business_license_no"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入经营许可证编号",
                                  maxlength: "300"
                                },
                                model: {
                                  value: _vm.certForm.business_license_no,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.certForm,
                                      "business_license_no",
                                      $$v
                                    )
                                  },
                                  expression: "certForm.business_license_no"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_name",
                              attrs: { label: "企业名称" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入企业名称",
                                  disabled: true,
                                  maxlength: "150"
                                },
                                model: {
                                  value: _vm.baseForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "name", $$v)
                                  },
                                  expression: "baseForm.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_address",
                              attrs: { label: "注册地址" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入注册地址",
                                  disabled: true,
                                  maxlength: "550"
                                },
                                model: {
                                  value: _vm.baseForm.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "address", $$v)
                                  },
                                  expression: "baseForm.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_legal_person",
                              attrs: { label: "法人代表" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入法人代表",
                                  disabled: true,
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.baseForm.legal_person,
                                  callback: function($$v) {
                                    _vm.$set(_vm.baseForm, "legal_person", $$v)
                                  },
                                  expression: "baseForm.legal_person"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_person_charge_enterprise",
                              attrs: {
                                label: "企业负责人",
                                prop: "person_charge_enterprise"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入企业负责人",
                                  maxlength: "30"
                                },
                                model: {
                                  value: _vm.certForm.person_charge_enterprise,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.certForm,
                                      "person_charge_enterprise",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "certForm.person_charge_enterprise"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_quality_manager",
                              attrs: {
                                label: "质量管理人",
                                prop: "quality_manager"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入质量管理人",
                                  maxlength: "30"
                                },
                                model: {
                                  value: _vm.certForm.quality_manager,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.certForm,
                                      "quality_manager",
                                      $$v
                                    )
                                  },
                                  expression: "certForm.quality_manager"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_warehouse_address",
                              attrs: {
                                label: "库房地址",
                                prop: "warehouse_address"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入库房地址",
                                  maxlength: "500"
                                },
                                model: {
                                  value: _vm.certForm.warehouse_address,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.certForm,
                                      "warehouse_address",
                                      $$v
                                    )
                                  },
                                  expression: "certForm.warehouse_address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "许可证期限" } },
                            [
                              _c(
                                "el-row",
                                { attrs: { type: "flex", gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 11 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "certForm_license_start_time",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            "label-width": "0",
                                            prop: "license_start_time"
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "开始日期",
                                              pickerOptions:
                                                _vm.licenseStartTimeOptions
                                            },
                                            model: {
                                              value:
                                                _vm.certForm.license_start_time,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.certForm,
                                                  "license_start_time",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "certForm.license_start_time"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("el-col", { attrs: { span: 2 } }, [
                                    _vm._v("至")
                                  ]),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 11 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          key: "certForm_license_end_time",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            "label-width": "0",
                                            prop: "license_end_time"
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "date",
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "结束日期",
                                              pickerOptions:
                                                _vm.licenseEndTimeOptions
                                            },
                                            model: {
                                              value:
                                                _vm.certForm.license_end_time,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.certForm,
                                                  "license_end_time",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "certForm.license_end_time"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_date_issue",
                              attrs: { label: "发证日期", prop: "date_issue" }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择发证日期"
                                },
                                model: {
                                  value: _vm.certForm.date_issue,
                                  callback: function($$v) {
                                    _vm.$set(_vm.certForm, "date_issue", $$v)
                                  },
                                  expression: "certForm.date_issue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_issuing_authority",
                              attrs: {
                                label: "发证机关",
                                prop: "issuing_authority"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入发证机关",
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.certForm.issuing_authority,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.certForm,
                                      "issuing_authority",
                                      $$v
                                    )
                                  },
                                  expression: "certForm.issuing_authority"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 18 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      key: "certForm_license_extension_time",
                                      attrs: {
                                        label: "延期时间",
                                        prop: "license_extension_time"
                                      }
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择延期时间",
                                          disabled:
                                            _vm.certForm
                                              .whether_license_extended ==
                                              "0" ||
                                            _vm.certForm
                                              .whether_license_extended == ""
                                              ? true
                                              : false
                                        },
                                        model: {
                                          value:
                                            _vm.certForm.license_extension_time,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certForm,
                                              "license_extension_time",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certForm.license_extension_time"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      key: "certForm_whether_license_extended",
                                      attrs: {
                                        prop: "whether_license_extended",
                                        "label-width": "0"
                                      }
                                    },
                                    [
                                      _c("el-checkbox", {
                                        attrs: {
                                          label: "是否延期",
                                          "true-label": "1",
                                          "false-label": "0"
                                        },
                                        model: {
                                          value:
                                            _vm.certForm
                                              .whether_license_extended,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certForm,
                                              "whether_license_extended",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certForm.whether_license_extended"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_license_extension_no",
                              attrs: {
                                label: "延期证号",
                                prop: "license_extension_no"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入延期证号",
                                  disabled:
                                    _vm.certForm.whether_license_extended ==
                                      "0" ||
                                    _vm.certForm.whether_license_extended == ""
                                      ? true
                                      : false,
                                  maxlength: "50"
                                },
                                model: {
                                  value: _vm.certForm.license_extension_no,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.certForm,
                                      "license_extension_no",
                                      $$v
                                    )
                                  },
                                  expression: "certForm.license_extension_no"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "licenseForm_nature_business",
                              attrs: { label: "经营范围" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: "请输入经营范围",
                                  disabled: true,
                                  maxlength: "2000"
                                },
                                model: {
                                  value: _vm.baseForm.nature_business,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.baseForm,
                                      "nature_business",
                                      $$v
                                    )
                                  },
                                  expression: "baseForm.nature_business"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              key: "certForm_picture_business_license",
                              attrs: {
                                label: "经营许可证照片",
                                prop: "picture_business_license"
                              }
                            },
                            [
                              _c(
                                "div",
                                { on: { drop: _vm.reportDrop } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        "align-items": "flex-start"
                                      },
                                      attrs: {
                                        action: _vm.uploadURL,
                                        headers: _vm.uploadToken,
                                        "before-upload": function(file) {
                                          return _vm.uploadBefore(file)
                                        },
                                        "on-remove": function(file, fileList) {
                                          return _vm.uploadRemove(
                                            file,
                                            fileList,
                                            "businessUploadFileList"
                                          )
                                        },
                                        "on-success": function(
                                          res,
                                          file,
                                          fileList
                                        ) {
                                          return _vm.uploadSuccess(
                                            res,
                                            file,
                                            fileList,
                                            "businessUploadFileList"
                                          )
                                        },
                                        "on-error": _vm.uploadError,
                                        "on-preview": _vm.uploadPreview,
                                        accept: _vm.uploadFileType.toString(),
                                        "on-exceed": _vm.uploadExceed,
                                        limit: 10,
                                        "show-file-list": true,
                                        "file-list": _vm.businessUploadFileList,
                                        drag: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          }
                                        },
                                        [_vm._v("点击上传")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip"
                                        },
                                        [
                                          _vm._v(
                                            "只能上传" +
                                              _vm._s(
                                                _vm.uploadFileType
                                                  .toString()
                                                  .toUpperCase()
                                                  .replace(/\./g, "")
                                                  .replace(/,/g, "/")
                                              ) +
                                              "类型文件，且单个文件大小不超过20MB，最多允许上传10个附件"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.itemFormStep >= 3
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              !_vm.readonly
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        align: "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "medium",
                                            type: "primary"
                                          },
                                          on: { click: _vm.formBack }
                                        },
                                        [_vm._v("上一步")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "medium",
                                            type: "primary"
                                          },
                                          on: { click: _vm.certFormSubmit }
                                        },
                                        [_vm._v("下一步")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  ref: "manuForm",
                  style: _vm.itemFormStep !== 4 ? "display: none" : "",
                  attrs: {
                    model: _vm.manuForm,
                    rules: _vm.manuFormRules,
                    "label-width": "150px",
                    size: "mini",
                    disabled: _vm.readonly,
                    "label-suffix": ":"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.allFormSubmit.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { type: "card", addable: !_vm.readonly },
                          on: { edit: _vm.handleTabsEdit },
                          model: {
                            value: _vm.editableTabsValue,
                            callback: function($$v) {
                              _vm.editableTabsValue = $$v
                            },
                            expression: "editableTabsValue"
                          }
                        },
                        [
                          _c(
                            "el-tab-pane",
                            {
                              key: "manuLicense1",
                              attrs: { label: "许可证1", name: "manuLicense1" }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _c("span", [_vm._v("许可证1")]),
                                  _vm.manuForm.manu_license_first === 1
                                    ? _c("i", {
                                        staticClass: "el-icon-star-on",
                                        staticStyle: { color: "red" }
                                      })
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "许可证编号",
                                            prop: "manu_license_code"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              maxlength: "255"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm.manu_license_code,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_code",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_code"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "统一社会信用代码",
                                            prop: "manu_license_unicode"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              maxlength: "191"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm
                                                  .manu_license_unicode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_unicode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_unicode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "企业名称",
                                            prop: "manu_license_company"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              maxlength: "191"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm
                                                  .manu_license_company,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_company",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_company"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "法定代表人",
                                            prop: "manu_license_legal"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              maxlength: "191"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm.manu_license_legal,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_legal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_legal"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "住所",
                                            prop: "manu_license_home"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              maxlength: "500"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm.manu_license_home,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_home",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_home"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "企业负责人",
                                            prop: "manu_license_boss"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              maxlength: "191"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm.manu_license_boss,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_boss",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_boss"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "生产地址",
                                            prop: "manu_license_address"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              maxlength: "500"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm
                                                  .manu_license_address,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_address"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "发证部门",
                                            prop: "manu_license_department"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              maxlength: "191"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm
                                                  .manu_license_department,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_department",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_department"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "许可期限" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" }
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "0"
                                                  },
                                                  attrs: {
                                                    "label-width": "0",
                                                    prop: "manu_license_start"
                                                  }
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      type: "date",
                                                      format: "yyyy-MM-dd",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      placeholder: "开始日期",
                                                      pickerOptions:
                                                        _vm.manuLicenseStartOptions
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.manuForm
                                                          .manu_license_start,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.manuForm,
                                                          "manu_license_start",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "manuForm.manu_license_start"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    margin: "0 10px"
                                                  }
                                                },
                                                [_vm._v("至")]
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "0"
                                                  },
                                                  attrs: {
                                                    "label-width": "0",
                                                    prop: "manu_license_end"
                                                  }
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      type: "date",
                                                      format: "yyyy-MM-dd",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      placeholder: "结束日期",
                                                      pickerOptions:
                                                        _vm.manuLicenseEndOptions
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.manuForm
                                                          .manu_license_end,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.manuForm,
                                                          "manu_license_end",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "manuForm.manu_license_end"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "是否为主生产许可证",
                                            prop: "manu_license_first"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                input: function($event) {
                                                  return _vm.manuLicenseFirstChange(
                                                    -1
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.manuForm
                                                    .manu_license_first,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.manuForm,
                                                    "manu_license_first",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "manuForm.manu_license_first"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("是")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("否")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "生产范围",
                                            prop: "manu_license_range"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              rows: 3,
                                              maxlength: "500"
                                            },
                                            model: {
                                              value:
                                                _vm.manuForm.manu_license_range,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manuForm,
                                                  "manu_license_range",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manuForm.manu_license_range"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.manuForm.manu_license_list, function(
                            item,
                            index
                          ) {
                            return _c(
                              "el-tab-pane",
                              {
                                key: "manuLicense" + (index + 2) + "",
                                attrs: {
                                  label: "许可证" + (index + 2) + "",
                                  name: "manuLicense" + (index + 2) + "",
                                  closable: !_vm.readonly
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [
                                    _c("span", [
                                      _vm._v("许可证" + _vm._s(index + 2))
                                    ]),
                                    item.manu_license_first === 1
                                      ? _c("i", {
                                          staticClass: "el-icon-star-on",
                                          staticStyle: { color: "red" }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            key:
                                              "manu_license_list." +
                                              index +
                                              ".manu_license_code",
                                            attrs: {
                                              label: "许可证编号",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_code",
                                              rule:
                                                _vm.manuFormRules
                                                  .manu_license_code
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              ref:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_code",
                                              refInFor: true,
                                              attrs: {
                                                type: "text",
                                                maxlength: "255"
                                              },
                                              model: {
                                                value: item.manu_license_code,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_code",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_code"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "统一社会信用代码",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_unicode"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                maxlength: "191"
                                              },
                                              model: {
                                                value:
                                                  item.manu_license_unicode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_unicode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_unicode"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "企业名称",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_company"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                maxlength: "191"
                                              },
                                              model: {
                                                value:
                                                  item.manu_license_company,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_company",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_company"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "法定代表人",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_legal"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                maxlength: "191"
                                              },
                                              model: {
                                                value: item.manu_license_legal,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_legal",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_legal"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "住所",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_home"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                maxlength: "500"
                                              },
                                              model: {
                                                value: item.manu_license_home,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_home",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_home"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "企业负责人",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_boss"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                maxlength: "191"
                                              },
                                              model: {
                                                value: item.manu_license_boss,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_boss",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_boss"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "生产地址",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_address"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                maxlength: "500"
                                              },
                                              model: {
                                                value:
                                                  item.manu_license_address,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_address",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_address"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "发证部门",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_department"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                maxlength: "191"
                                              },
                                              model: {
                                                value:
                                                  item.manu_license_department,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_department",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_department"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "许可期限" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { display: "flex" }
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      "label-width": "0",
                                                      prop:
                                                        "manu_license_list." +
                                                        index +
                                                        ".manu_license_start"
                                                    }
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%"
                                                      },
                                                      attrs: {
                                                        type: "date",
                                                        format: "yyyy-MM-dd",
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        placeholder: "开始日期",
                                                        pickerOptions:
                                                          _vm.manuLicenseStartOptions
                                                      },
                                                      model: {
                                                        value:
                                                          item.manu_license_start,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "manu_license_start",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.manu_license_start"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      margin: "0 10px"
                                                    }
                                                  },
                                                  [_vm._v("至")]
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      "label-width": "0",
                                                      prop:
                                                        "manu_license_list." +
                                                        index +
                                                        ".manu_license_end"
                                                    }
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%"
                                                      },
                                                      attrs: {
                                                        type: "date",
                                                        format: "yyyy-MM-dd",
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        placeholder: "结束日期",
                                                        pickerOptions:
                                                          _vm.manuLicenseEndOptions
                                                      },
                                                      model: {
                                                        value:
                                                          item.manu_license_end,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "manu_license_end",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.manu_license_end"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "是否为主生产许可证",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_first"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                on: {
                                                  input: function($event) {
                                                    return _vm.manuLicenseFirstChange(
                                                      index
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    item.manu_license_first,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "manu_license_first",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.manu_license_first"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 1 } },
                                                  [_vm._v("是")]
                                                ),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 0 } },
                                                  [_vm._v("否")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "生产范围",
                                              prop:
                                                "manu_license_list." +
                                                index +
                                                ".manu_license_range"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                rows: 3,
                                                maxlength: "500"
                                              },
                                              model: {
                                                value: item.manu_license_range,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "manu_license_range",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.manu_license_range"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.itemFormStep >= 4
                    ? _c(
                        "div",
                        [
                          !_vm.readonly
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { "label-width": "0", align: "center" }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "medium",
                                        type: "primary"
                                      },
                                      on: { click: _vm.formBack }
                                    },
                                    [_vm._v("上一步")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "prevent-re-click",
                                          rawName: "v-prevent-re-click"
                                        }
                                      ],
                                      attrs: {
                                        size: "medium",
                                        type: "primary"
                                      },
                                      on: { click: _vm.allFormSubmit }
                                    },
                                    [_vm._v("确定")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }