import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listWarehouse } from '@/api/warehouse.js';
import { listInwarehouseDetail, listInWarehouse, listInWarehouseExport } from '@/api/stock.js';
import { listSupplier } from '@/api/supplier.js';
import dayjs from 'dayjs';
import YTable from '@/views/components/drag-table/table.vue';
import JsBarcode from 'jsbarcode';
export default {
  name: 'stock-inwarehouse-check-index',
  components: {
    YTable: YTable
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (vm) {
      vm.loadData(); // 调用方法
    });
  },
  data: function data() {
    var _this = this;

    return {
      orderLoading: false,
      detailLoading: false,
      searchForm: {
        page: 1,
        pageSize: 5,
        total: 1,
        warehouseId: null,
        dateRange: [],
        timeStart: null,
        timeEnd: null,
        inWarehouseStatus: '',
        manufacturerId: null,
        orderNumber: null,
        batchNumber: null,
        inWarehouseType: null,
        financeMenu: 1
      },
      detailForm: {
        page: 1,
        pageSize: 5,
        total: 0,
        inWarehouseId: 0
      },
      orderList: [],
      detailList: [],
      orderRowIndex: -1,
      inWarehouseStatusList: [{
        ID: '',
        name: '全部',
        type: ''
      }, {
        ID: 1,
        name: '暂存',
        type: 'info'
      }, {
        ID: 2,
        name: '已提交',
        type: 'primary'
      }, {
        ID: 3,
        name: '已审核',
        type: 'warning'
      }, {
        ID: 4,
        name: '已入库',
        type: 'success'
      }, {
        ID: 6,
        name: '已作废',
        type: 'danger'
      }],
      warehouseList: [],
      manufacturerList: [],
      inWarehouseTypeList: [{
        ID: 1,
        name: '采购入库',
        type: 'success'
      }, {
        ID: 2,
        name: '期初入库',
        type: 'success2'
      }, {
        ID: 3,
        name: '销售退货',
        type: 'warning'
      }, {
        ID: 4,
        name: '调拨入库',
        type: 'info'
      }, {
        ID: 5,
        name: '盘点入库',
        type: 'danger'
      }, {
        ID: 6,
        name: '成品入库',
        type: 'primary'
      }, {
        ID: 7,
        name: '寄售入库',
        type: 'primary2'
      }],
      searchFormExpand: false,
      columns: [{
        label: '仓库',
        prop: 'warehouseName',
        checked: true,
        width: 200
      }, {
        label: '入库类型',
        prop: 'inWarehouseType',
        checked: true,
        width: 100,
        formatter: function formatter(val) {
          var findRow = _this.inWarehouseTypeList.find(function (item) {
            return item.ID === val;
          });

          if (findRow) {
            return '<a class="el-link el-link--' + findRow.type + '"><span class="el-link--inner">' + findRow.name + '</span></a>';
          }
        }
      }, {
        label: '入库单号',
        prop: 'orderNumber',
        checked: true,
        width: 200,
        slot: 'orderNumber'
      }, {
        label: '单据状态',
        prop: 'inWarehouseStatus',
        checked: true,
        width: 100,
        formatter: function formatter(val) {
          var findRow = _this.inWarehouseStatusList.find(function (item) {
            return item.ID === val;
          });

          if (findRow) {
            return '<a class="el-link el-link--' + findRow.type + '"><span class="el-link--inner">' + findRow.name + '</span></a>';
          }
        }
      }, {
        label: '业务部门',
        prop: 'departmentName',
        checked: true,
        width: 200
      }, {
        label: '供应商',
        prop: 'manufacturerName',
        checked: true,
        width: 200
      }, {
        label: '入库人',
        prop: 'inWarehouseName',
        checked: true,
        width: 100
      }, {
        label: '入库时间',
        prop: 'inWarehouseTime',
        checked: true,
        width: 150,
        type: 'time'
      }, {
        label: '审核人',
        prop: 'examineName',
        checked: true,
        width: 100
      }, {
        label: '审核时间',
        prop: 'examineTime',
        checked: true,
        width: 150,
        type: 'time'
      }, {
        label: '记账人',
        prop: 'accountName',
        checked: true,
        width: 100
      }, {
        label: '记账时间',
        prop: 'accountTime',
        checked: true,
        width: 150,
        type: 'time'
      }, {
        label: '操作',
        prop: 'operations',
        checked: true,
        width: '100',
        fixed: 'right',
        slot: 'operations',
        dragIgnore: true,
        fixedWidth: true
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.loadWarehouse();
      that.loadManufacturer();
      that.loadData();
    },
    loadData: function loadData() {
      var that = this;

      if (that.orderLoading) {
        return;
      }

      that.orderRowIndex = -1;
      var form = JSON.parse(JSON.stringify(that.searchForm));

      if (form.dateRange[0] && form.dateRange[1]) {
        form.timeStart = form.dateRange[0];
        form.timeEnd = form.dateRange[1];
      }

      delete form.dateRange;
      that.orderLoading = true;
      that.orderList = [];
      that.detailList = [];
      listInWarehouse(form).then(function (res) {
        that.orderList = res.list;
        that.searchForm.total = res.total;
      }).finally(function () {
        that.orderLoading = false;
      });
    },
    dataExport: function dataExport() {
      var that = this;
      var form = JSON.parse(JSON.stringify(that.searchForm));

      if (form.dateRange[0] && form.dateRange[1]) {
        form.timeStart = form.dateRange[0];
        form.timeEnd = form.dateRange[1];
      }

      delete form.dateRange;
      form.pageSize = form.total;
      form.toExcel = 1;
      listInWarehouseExport(form).then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = '入库审核.xlsx'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    searchFormSubmit: function searchFormSubmit() {
      var that = this;
      that.searchForm.page = 1;
      that.searchForm.total = 0;
      that.loadData();
    },
    orderPageChange: function orderPageChange(page) {
      var that = this;
      that.searchForm.page = page;
      that.loadData();
    },
    orderSizeChange: function orderSizeChange(val) {
      var that = this;
      that.searchForm.page = 1;
      that.searchForm.pageSize = val;
      that.loadData();
    },
    orderRowClick: function orderRowClick(row) {
      var that = this;
      that.orderRowIndex = row.rowIndex;
      that.detailForm.page = 1;
      that.detailForm.inWarehouseId = row.ID;
      that.loadDetail();
    },
    orderRowDbClick: function orderRowDbClick(row) {
      var that = this;
      that.orderRowClick(row);
      that.orderEdit(row);
    },
    loadDetail: function loadDetail() {
      var that = this;
      that.detailList = [];
      that.detailLoading = true;
      listInwarehouseDetail(that.detailForm).then(function (res) {
        that.detailList = res.list;
        that.detailForm.total = res.total;
      }).finally(function () {
        that.detailLoading = false;
      });
    },
    detailPageChange: function detailPageChange(page) {
      var that = this;
      that.detailForm.page = page;
      that.loadDetail();
    },
    detailSizeChange: function detailSizeChange(val) {
      var that = this;
      that.detailForm.page = 1;
      that.detailForm.pageSize = val;

      if (that.detailForm.inWarehouseId) {
        that.loadDetail();
      }
    },
    orderEdit: function orderEdit(row) {
      var that = this;
      that.$router.push({
        path: '/stock/inwarehouse/check/detail/' + row.ID
      });
    },
    loadWarehouse: function loadWarehouse() {
      var that = this;
      listWarehouse({
        page: 1,
        pageSize: 9999
      }).then(function (res) {
        that.warehouseList = res.list;
      });
    },
    loadManufacturer: function loadManufacturer() {
      var that = this;
      that.manufacturerList = [];
      listSupplier({
        page: 1,
        pageSize: 9999,
        all_examine_status: true
      }).then(function (res) {
        res.list.forEach(function (row) {
          that.manufacturerList.push(row);
        });
      });
    },
    orderListFormat: function orderListFormat(rows) {
      var resultRows = [];
      rows.forEach(function (row, index) {
        var item = row;
        item.rowIndex = index;
        item.accountTime = item.accountTime ? dayjs(item.accountTime).format('YYYY-MM-DD HH:mm:ss') : '';
        item.examineTime = item.examineTime ? dayjs(item.examineTime).format('YYYY-MM-DD HH:mm:ss') : '';
        resultRows.push(item);
      });
      return resultRows;
    },
    inWarehouseTypeFormatter: function inWarehouseTypeFormatter(inWarehouseType) {
      var that = this;
      var find = that.inWarehouseTypeList.filter(function (item) {
        return item.ID === inWarehouseType;
      });

      if (find.length > 0) {
        return '<span class="el-tag el-tag--small el-tag--light el-tag--' + find[0].type + '">' + find[0].name + '</span>';
      }
    },
    inWarehouseStatusFormatter: function inWarehouseStatusFormatter(status) {
      var that = this;
      var find = that.inWarehouseStatusList.filter(function (item) {
        return item.ID === status;
      });

      if (find.length > 0) {
        return '<span class="el-tag el-tag--small el-tag--light el-tag--' + find[0].type + '">' + find[0].name + '</span>';
      }
    },
    rowClassName: function rowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;
      row.rowIndex = rowIndex;
    },
    indexMethod: function indexMethod(index, pagination) {
      return (pagination.page - 1) * pagination.pageSize + index + 1;
    },
    makeBarCode: function makeBarCode(id, code) {
      setTimeout(function () {
        JsBarcode('#barCodeImage' + id, code, {
          height: 40,
          width: 2,
          fontSize: 14,
          margin: 0
        });
      }, 500);
    }
  }
};