//
//
//
//
//
//
//
import FormComponent1 from '../form.vue';
import FormComponent2 from '../consignment/form.vue';
export default {
  name: 'purchase-examine-approve',
  props: {
    type: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    }
  },
  components: {
    FormComponent1: FormComponent1,
    FormComponent2: FormComponent2
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {
    console.log(this.type, this.id);
  },
  methods: {}
};