var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.action === "strategy"
        ? _c(
            "div",
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "100px",
                    size: "small",
                    "label-suffix": ":"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.formSubmit.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "策略名称", prop: "strategyName" }
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "100", clearable: "" },
                                model: {
                                  value: _vm.form.strategyName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "strategyName", $$v)
                                  },
                                  expression: "form.strategyName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "关联经销商",
                                prop: "manufacturerIds"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: {
                                        placeholder: "关联经销商",
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                        "collapse-tags": "",
                                        disabled:
                                          _vm.form.isAllManufacturer === 1
                                            ? true
                                            : false
                                      },
                                      model: {
                                        value: _vm.form.manufacturerIds,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "manufacturerIds",
                                            $$v
                                          )
                                        },
                                        expression: "form.manufacturerIds"
                                      }
                                    },
                                    _vm._l(_vm.retailerList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: "retailer" + index,
                                        attrs: {
                                          label: item.name,
                                          value: item.ID
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 2
                                      },
                                      on: { change: _vm.manufacturerIdsChange },
                                      model: {
                                        value: _vm.form.isAllManufacturer,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isAllManufacturer",
                                            $$v
                                          )
                                        },
                                        expression: "form.isAllManufacturer"
                                      }
                                    },
                                    [_vm._v("所有经销商")]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "是否启用", prop: "stateEnable" }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.stateEnable,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "stateEnable", $$v)
                                    },
                                    expression: "form.stateEnable"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("启用")
                                  ]),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("禁用")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "优先级", prop: "priority" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("el-slider", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      step: 1,
                                      min: 0,
                                      max: 10,
                                      "show-stops": ""
                                    },
                                    model: {
                                      value: _vm.form.priority,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "priority", $$v)
                                      },
                                      expression: "form.priority"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-danger",
                                      staticStyle: { "margin-left": "10px" }
                                    },
                                    [_vm._v("（值越大，优先级越高）")]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "关联医院", prop: "hospitalIds" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: {
                                        placeholder: "关联医院",
                                        clearable: "",
                                        filterable: "",
                                        multiple: "",
                                        "collapse-tags": "",
                                        disabled:
                                          _vm.form.isAllHospital === 1
                                            ? true
                                            : false
                                      },
                                      model: {
                                        value: _vm.form.hospitalIds,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "hospitalIds", $$v)
                                        },
                                        expression: "form.hospitalIds"
                                      }
                                    },
                                    _vm._l(_vm.hospitalList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: "hospital" + index,
                                        attrs: {
                                          label: item.name,
                                          value: item.ID
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 2
                                      },
                                      on: { change: _vm.hospitalIdsChange },
                                      model: {
                                        value: _vm.form.isAllHospital,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isAllHospital",
                                            $$v
                                          )
                                        },
                                        expression: "form.isAllHospital"
                                      }
                                    },
                                    [_vm._v("所有医院")]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "生效日期", required: true } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "effectivePeriodStart" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "130px" },
                                        attrs: {
                                          type: "date",
                                          "value-format": "yyyy-MM-dd",
                                          format: "yyyy-MM-dd",
                                          placeholder: "开始日期"
                                        },
                                        model: {
                                          value: _vm.form.effectivePeriodStart,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "effectivePeriodStart",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.effectivePeriodStart"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "effectivePeriodEnd",
                                        label: "结束日期"
                                      }
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "130px" },
                                        attrs: {
                                          type: "date",
                                          "value-format": "yyyy-MM-dd",
                                          format: "yyyy-MM-dd",
                                          placeholder: "结束日期",
                                          disabled:
                                            _vm.form.isLongTerm === 1
                                              ? true
                                              : false
                                        },
                                        model: {
                                          value: _vm.form.effectivePeriodEnd,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "effectivePeriodEnd",
                                              $$v
                                            )
                                          },
                                          expression: "form.effectivePeriodEnd"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        "true-label": 1,
                                        "false-label": 2
                                      },
                                      on: { change: _vm.isLongTermChange },
                                      model: {
                                        value: _vm.form.isLongTerm,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "isLongTerm", $$v)
                                        },
                                        expression: "form.isLongTerm"
                                      }
                                    },
                                    [_vm._v("长期有效")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "策略描述",
                                prop: "strategyDescription"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "200", clearable: "" },
                                model: {
                                  value: _vm.form.strategyDescription,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "strategyDescription",
                                      $$v
                                    )
                                  },
                                  expression: "form.strategyDescription"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "0" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            display: "flex",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                color: "#f00"
                              }
                            },
                            [
                              _vm._v(
                                " 注：产品数据采用级联数据结构支持逐级配置，当父级配置生效时，将同时应用于所有子级数据。 "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.importDetail()
                                    }
                                  }
                                },
                                [_vm._v("导入")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.detailAdd()
                                    }
                                  }
                                },
                                [_vm._v("添加")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.detailRemove()
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              ref: "detailTableRef",
                              staticStyle: { width: "100%" },
                              attrs: {
                                border: "",
                                size: "mini",
                                "header-cell-style": {
                                  background: "#f5f7fa",
                                  borderColor: "#ebeef5",
                                  color: "#333"
                                },
                                data: _vm.detailList.slice(
                                  (_vm.detailForm.page - 1) *
                                    _vm.detailForm.pageSize,
                                  _vm.detailForm.page * _vm.detailForm.pageSize
                                ),
                                "highlight-current-row": ""
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "#",
                                  width: "60",
                                  type: "index",
                                  align: "center",
                                  index: function(index) {
                                    return _vm.indexMethod(
                                      index,
                                      _vm.detailForm
                                    )
                                  }
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  width: "50",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "品牌",
                                  width: "100",
                                  prop: "brandName",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "分类",
                                  "min-width": "100",
                                  prop: "cateName",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品名称",
                                  "min-width": "150",
                                  prop: "productName",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格型号",
                                  width: "100",
                                  prop: "specificationModel",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "主码",
                                  width: "100",
                                  prop: "barCode",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "直销价",
                                  width: "200",
                                  prop: "directPrice",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "left",
                                                width: "400",
                                                trigger: "click"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      attrs: {
                                                        "true-label": 2,
                                                        "false-label": 1
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.detailSaveOne(
                                                            row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: row.directEnable,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "directEnable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.directEnable"
                                                      }
                                                    },
                                                    [_vm._v("直销价")]
                                                  ),
                                                  _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "100px",
                                                      margin: "0px 10px"
                                                    },
                                                    attrs: {
                                                      min: 0.01,
                                                      controls: false,
                                                      disabled:
                                                        row.directEnable === 1
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.detailSaveOne(
                                                          row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: row.directPrice,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "directPrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.directPrice"
                                                    }
                                                  }),
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          row.directEnable === 1
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.detailSaveOne(
                                                            row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: row.directType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "directType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.directType"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: 1,
                                                            disabled: row.specsId
                                                              ? false
                                                              : true
                                                          }
                                                        },
                                                        [_vm._v("固定金额")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 2 } },
                                                        [
                                                          _vm._v(
                                                            "基础直销价折扣比"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 3 } },
                                                        [
                                                          _vm._v(
                                                            "采购进价折扣比"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    slot: "reference",
                                                    type: "primary"
                                                  },
                                                  slot: "reference"
                                                },
                                                [
                                                  row.directEnable === 2
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.directType ===
                                                                1
                                                                ? row.directPrice.toFixed(
                                                                    2
                                                                  )
                                                                : row.directPrice
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f00"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " (" +
                                                                _vm._s(
                                                                  row.directType ===
                                                                    1
                                                                    ? "固额"
                                                                    : row.directType ===
                                                                      2
                                                                    ? "基础直销价折扣比"
                                                                    : "采购进价折扣比"
                                                                ) +
                                                                ") "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("未启用")
                                                      ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  599580670
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "销售价",
                                  width: "200",
                                  prop: "salesPrice",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "left",
                                                width: "400",
                                                trigger: "click"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      attrs: {
                                                        "true-label": 2,
                                                        "false-label": 1
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.detailSaveOne(
                                                            row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: row.salesEnable,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "salesEnable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.salesEnable"
                                                      }
                                                    },
                                                    [_vm._v("销售价")]
                                                  ),
                                                  _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "100px",
                                                      margin: "0px 10px"
                                                    },
                                                    attrs: {
                                                      min: 0.01,
                                                      controls: false,
                                                      disabled:
                                                        row.salesEnable === 1
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.detailSaveOne(
                                                          row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: row.salesPrice,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "salesPrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.salesPrice"
                                                    }
                                                  }),
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          row.salesEnable === 1
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.detailSaveOne(
                                                            row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: row.salesType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "salesType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.salesType"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: 1,
                                                            disabled: row.specsId
                                                              ? false
                                                              : true
                                                          }
                                                        },
                                                        [_vm._v("固定金额")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 2 } },
                                                        [
                                                          _vm._v(
                                                            "基础销售价折扣比"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 3 } },
                                                        [
                                                          _vm._v(
                                                            "采购进价折扣比"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    slot: "reference",
                                                    type: "primary"
                                                  },
                                                  slot: "reference"
                                                },
                                                [
                                                  row.salesEnable === 2
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.salesType ===
                                                                1
                                                                ? row.salesPrice.toFixed(
                                                                    2
                                                                  )
                                                                : row.salesPrice
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f00"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " (" +
                                                                _vm._s(
                                                                  row.salesType ===
                                                                    1
                                                                    ? "固额"
                                                                    : row.salesType ===
                                                                      2
                                                                    ? "基础销售价折扣比"
                                                                    : "采购进价折扣比"
                                                                ) +
                                                                ") "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("未启用")
                                                      ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1998518305
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "寄售价",
                                  width: "200",
                                  prop: "sellingPrice",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "left",
                                                width: "400",
                                                trigger: "click"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      attrs: {
                                                        "true-label": 2,
                                                        "false-label": 1
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.detailSaveOne(
                                                            row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          row.sellingEnable,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "sellingEnable",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.sellingEnable"
                                                      }
                                                    },
                                                    [_vm._v("寄售价")]
                                                  ),
                                                  _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "100px",
                                                      margin: "0px 10px"
                                                    },
                                                    attrs: {
                                                      min: 0.01,
                                                      controls: false,
                                                      disabled:
                                                        row.sellingEnable === 1
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.detailSaveOne(
                                                          row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: row.sellingPrice,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "sellingPrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.sellingPrice"
                                                    }
                                                  }),
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          row.sellingEnable ===
                                                          1
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.detailSaveOne(
                                                            row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: row.sellingType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "sellingType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.sellingType"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: 1,
                                                            disabled: row.specsId
                                                              ? false
                                                              : true
                                                          }
                                                        },
                                                        [_vm._v("固定金额")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 2 } },
                                                        [
                                                          _vm._v(
                                                            "基础寄售价折扣比"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        { attrs: { label: 3 } },
                                                        [
                                                          _vm._v(
                                                            "采购进价折扣比"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    slot: "reference",
                                                    type: "primary"
                                                  },
                                                  slot: "reference"
                                                },
                                                [
                                                  row.sellingEnable === 2
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.sellingType ===
                                                                1
                                                                ? row.sellingPrice.toFixed(
                                                                    2
                                                                  )
                                                                : row.sellingPrice
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#f00"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " (" +
                                                                _vm._s(
                                                                  row.sellingType ===
                                                                    1
                                                                    ? "固额"
                                                                    : row.sellingType ===
                                                                      1
                                                                    ? "基础寄售价折扣比"
                                                                    : "采购进价折扣比"
                                                                ) +
                                                                ") "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("未启用")
                                                      ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2818651288
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  align: "center",
                  background: "",
                  layout: "total, prev, pager, next",
                  "current-page": _vm.detailForm.page,
                  "page-size": _vm.detailForm.pageSize,
                  total: _vm.detailForm.total
                },
                on: {
                  "current-change": _vm.detailPageChange,
                  "size-change": _vm.detailSizeChange
                }
              }),
              _c(
                "div",
                { attrs: { align: "center" } },
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("DetailComponent", {
        ref: "DetailComponentRef",
        on: { add: _vm.addToDetailList }
      }),
      _c("ImportDetailComponent", {
        ref: "ImportDetailComponentRef",
        on: { reload: _vm.loadDetail }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }