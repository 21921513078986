var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购验收")]),
              _c("el-breadcrumb-item", [_vm._v("采购订购审批")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.toBatchExamine()
                    }
                  }
                },
                [_vm._v("批量审批")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "订单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "订单号", clearable: "" },
                      model: {
                        value: _vm.searchForm.orderNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "orderNumber", $$v)
                        },
                        expression: "searchForm.orderNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.manufacturerId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "manufacturerId", $$v)
                          },
                          expression: "searchForm.manufacturerId"
                        }
                      },
                      _vm._l(_vm.manufacturerList, function(item) {
                        return _c("el-option", {
                          key: "manu-" + item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          multiple: "",
                          "collapse-tags": "",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.warehouseIds,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseIds", $$v)
                          },
                          expression: "searchForm.warehouseIds"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: "warehouseIds-" + item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "采购员" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.buyerUid,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "buyerUid", $$v)
                          },
                          expression: "searchForm.buyerUid"
                        }
                      },
                      _vm._l(_vm.userList, function(item) {
                        return _c("el-option", {
                          key: "buyer-" + item.id,
                          attrs: { label: item.name, value: item.uid }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "订单类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "150px" },
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.searchForm.orderType,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "orderType", $$v)
                              },
                              expression: "searchForm.orderType"
                            }
                          },
                          _vm._l(_vm.orderTypeList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "日期查询" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "picker-options": _vm.pickerOptions,
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.searchForm.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange", $$v)
                            },
                            expression: "searchForm.dateRange"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
          [
            _c(
              "div",
              {
                class: _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(1)
                  }
                }
              },
              [
                _vm._v("全部("),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.tabCounts.allExamineCount))
                ]),
                _vm._v(")")
              ]
            ),
            _c(
              "div",
              {
                class: _vm.activeTabId === 2 ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(2)
                  }
                }
              },
              [
                _c(
                  "el-badge",
                  {
                    attrs: {
                      value: _vm.tabCounts.checkExamineCount,
                      hidden: _vm.tabCounts.checkExamineCount === 0
                    }
                  },
                  [_vm._v("待审批")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                class: _vm.activeTabId === 3 ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(3)
                  }
                }
              },
              [
                _vm._v("审核通过("),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.tabCounts.confirmExamineCount))
                ]),
                _vm._v(")")
              ]
            ),
            _c(
              "div",
              {
                class: _vm.activeTabId === 4 ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(4)
                  }
                }
              },
              [
                _vm._v("审批未通过("),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.tabCounts.rejectExamineCount))
                ]),
                _vm._v(")")
              ]
            )
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "dataTableRef",
          attrs: {
            selectable: _vm.selectable,
            hasSelection: true,
            hasPagination: true,
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList,
            selections: _vm.selections
          },
          on: {
            "row-click": _vm.orderRowClick,
            "row-dblclick": _vm.orderRowDbClick,
            pageChange: _vm.orderPageChange,
            sizeChange: _vm.orderSizeChange
          },
          scopedSlots: _vm._u([
            {
              key: "orderNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-popover",
                    {
                      ref: "barCodePopover" + row.ID,
                      attrs: { placement: "right", trigger: "click" }
                    },
                    [
                      _c("div", [
                        _c("img", { attrs: { id: "barCodeImage" + row.ID } })
                      ]),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            slot: "reference",
                            underline: false,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.makebarCode(row)
                            }
                          },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(row.orderNumber))]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "examine_status",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.examine_status === 2
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "驳回原因：" + row.approvalOpinions,
                            placement: "top"
                          }
                        },
                        [
                          _c("el-link", { attrs: { type: "danger" } }, [
                            _vm._v("审核未通过")
                          ])
                        ],
                        1
                      )
                    : _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.examineStatusToTag(row.examine_status)
                          )
                        }
                      })
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.toViewDetail(row)
                            }
                          }
                        },
                        [_vm._v("详情")]
                      ),
                      _vm.activeTabId === 2 && row.examine_status === 1
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toApprove(row)
                                }
                              }
                            },
                            [_vm._v("审批")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailTableRef",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            hasPagination: true,
            hasIndex: true,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name + "-detail",
            columns: _vm.detailColumns,
            data: _vm.detailList
          },
          on: {
            pageChange: _vm.orderPageChange,
            sizeChange: _vm.orderSizeChange
          }
        }),
        _c("ExamineComponent", {
          ref: "ExamineComponentRef",
          on: { confirm: _vm.examineConfirm }
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }