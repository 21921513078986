var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center",
            "margin-bottom": "10px"
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "detailForm",
              attrs: { "label-width": "70px", size: "small", inline: true },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              },
              model: {
                value: _vm.detailForm,
                callback: function($$v) {
                  _vm.detailForm = $$v
                },
                expression: "detailForm"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "销售单号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入销售单号" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.detailFormSubmit.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.detailForm.salesOrderNo,
                      callback: function($$v) {
                        _vm.$set(_vm.detailForm, "salesOrderNo", $$v)
                      },
                      expression: "detailForm.salesOrderNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "0" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.detailFormSubmit }
                    },
                    [_vm._v("查 询")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", [
            _c("span", [_vm._v("仓库：" + _vm._s(_vm.warehouseName))]),
            _c("span", { staticStyle: { margin: "0 10px" } }, [
              _vm._v("销售总数："),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.quantityUsedSum))
              ])
            ]),
            _c("span", [
              _vm._v("导入总数："),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.inWarehouseQuantitySum))
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "detailListTable",
          attrs: {
            data: _vm.detailList,
            border: "",
            "highlight-current-row": "",
            size: "mini",
            "max-height": "260px",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "40", fixed: "" }
          }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "60",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "productNumber",
              label: "产品编号",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              property: "productName",
              label: "物资名称",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              property: "genericName",
              label: "通用名",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              property: "specificationModel",
              label: "规格型号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "barCode",
              label: "UDI-DI",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "batchNumber",
              label: "产品批号",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "trackingCode",
              label: "序列号",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "manufacturerDate",
              label: "生产日期",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "validityDate",
              label: "有效期",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "sterilizationBatchNumber",
              label: "灭菌批号",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              property: "sterilizationDate",
              label: "灭菌效期",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              property: "brandName",
              label: "品牌",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              property: "unit",
              label: "单位",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              property: "quantityUsed",
              label: "销售数量",
              align: "center",
              fixed: "right"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              property: "inWarehouseQuantity",
              label: "导入数量",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input-number", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        controls: false,
                        min: 0,
                        max: row.normalQuantity,
                        size: "mini"
                      },
                      model: {
                        value: row.inWarehouseQuantity,
                        callback: function($$v) {
                          _vm.$set(row, "inWarehouseQuantity", $$v)
                        },
                        expression: "row.inWarehouseQuantity"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              property: "afterTaxPrice",
              label: "销售单价",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              property: "registrationNumber",
              label: "注册证号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              property: "manuLicenseCode",
              label: "生产许可证号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.detailForm.total,
              "page-sizes": [5, 10, 20, 50, 100],
              "current-page": _vm.detailForm.page,
              "page-size": _vm.detailForm.pageSize
            },
            on: {
              "current-change": _vm.detailPageChange,
              "size-change": _vm.detailSizeChange
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "text-danger", attrs: { align: "right" } }, [
        _vm._v("注：销售单价将作为采购单价带到入库单中")
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.importConfirm } },
            [_vm._v("确认导入")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }