var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { attrs: { align: "right" } },
        [
          _c(
            "el-checkbox",
            {
              attrs: { "true-label": "无需物流", "false-label": "自营物流" },
              model: {
                value: _vm.itemForm.logisticsType,
                callback: function($$v) {
                  _vm.$set(_vm.itemForm, "logisticsType", $$v)
                },
                expression: "itemForm.logisticsType"
              }
            },
            [_vm._v("无需配送")]
          ),
          _vm.itemForm.logisticsType === "无需物流"
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(" 注：当选择了无需配送，则不会产生配送单。 ")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.itemForm.logisticsType !== "无需物流"
        ? _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.itemForm.logisticsType,
                    callback: function($$v) {
                      _vm.$set(_vm.itemForm, "logisticsType", $$v)
                    },
                    expression: "itemForm.logisticsType"
                  }
                },
                [
                  _vm.deliveryOutStatus === 0
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "自营物流", name: "自营物流" } },
                        [
                          _c(
                            "el-form",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.formLoading,
                                  expression: "formLoading"
                                }
                              ],
                              ref: "itemForm",
                              attrs: {
                                model: _vm.itemForm,
                                rules: _vm.itemFormRules,
                                "label-width": "90px",
                                size: "small",
                                "status-icon": true
                              },
                              nativeOn: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "出库核验员",
                                    prop: "warehouseCheckUid"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                        filterable: ""
                                      },
                                      model: {
                                        value: _vm.itemForm.warehouseCheckUid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.itemForm,
                                            "warehouseCheckUid",
                                            $$v
                                          )
                                        },
                                        expression: "itemForm.warehouseCheckUid"
                                      }
                                    },
                                    _vm._l(_vm.warehouseUserList, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.uid,
                                        attrs: {
                                          label: item.name,
                                          value: item.uid
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "配送员",
                                    prop: "deliveryUid"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                        filterable: ""
                                      },
                                      on: { change: _vm.deliveryUidChange },
                                      model: {
                                        value: _vm.itemForm.deliveryUid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.itemForm,
                                            "deliveryUid",
                                            $$v
                                          )
                                        },
                                        expression: "itemForm.deliveryUid"
                                      }
                                    },
                                    _vm._l(_vm.userList, function(item) {
                                      return _c("el-option", {
                                        key: item.uid,
                                        attrs: {
                                          label: item.name,
                                          value: item.uid,
                                          disabled:
                                            item.uid ===
                                            _vm.itemForm.secondDeliveryUid
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "配送员收益",
                                    prop: "expressFee"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      precision: 2,
                                      "controls-position": "right",
                                      min: 0,
                                      disabled:
                                        _vm.itemForm.isZeroFromUserEdit === 0
                                    },
                                    model: {
                                      value: _vm.itemForm.expressFee,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.itemForm,
                                          "expressFee",
                                          $$v
                                        )
                                      },
                                      expression: "itemForm.expressFee"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "从配送员",
                                    prop: "secondDeliveryUid"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                        disabled: !_vm.itemForm.deliveryUid,
                                        filterable: ""
                                      },
                                      on: { change: _vm.secondDeliveryChange },
                                      model: {
                                        value: _vm.itemForm.secondDeliveryUid,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.itemForm,
                                            "secondDeliveryUid",
                                            $$v
                                          )
                                        },
                                        expression: "itemForm.secondDeliveryUid"
                                      }
                                    },
                                    _vm._l(_vm.userList, function(item) {
                                      return _c("el-option", {
                                        key: item.uid,
                                        attrs: {
                                          label: item.name,
                                          value: item.uid,
                                          disabled:
                                            item.uid ===
                                            _vm.itemForm.deliveryUid
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "从配送员收益",
                                    prop: "secondExpressFee"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      precision: 2,
                                      "controls-position": "right",
                                      min: 0,
                                      disabled: !_vm.itemForm.secondDeliveryUid
                                    },
                                    model: {
                                      value: _vm.itemForm.secondExpressFee,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.itemForm,
                                          "secondExpressFee",
                                          $$v
                                        )
                                      },
                                      expression: "itemForm.secondExpressFee"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "附件", prop: "attach" } },
                                [
                                  _c("UploadComponent", {
                                    ref: "UploadComponentRef",
                                    attrs: {
                                      fileList: _vm.itemForm.attach,
                                      mode: "DRAG"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注", prop: "remark" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 2,
                                      maxlength: "100",
                                      "show-word-limit": ""
                                    },
                                    model: {
                                      value: _vm.itemForm.remark,
                                      callback: function($$v) {
                                        _vm.$set(_vm.itemForm, "remark", $$v)
                                      },
                                      expression: "itemForm.remark"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "结算方式",
                                    prop: "settleType"
                                  }
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "32px"
                                      },
                                      attrs: {
                                        disabled: _vm.deliverySettleRule != 3
                                      },
                                      on: { change: _vm.settleTypeChange },
                                      model: {
                                        value: _vm.itemForm.settleType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.itemForm,
                                            "settleType",
                                            $$v
                                          )
                                        },
                                        expression: "itemForm.settleType"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "按单次配送" } },
                                        [_vm._v("按单次配送")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "按手术订单" } },
                                        [_vm._v("按手术订单")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "el-button",
                                { on: { click: _vm.dialogClose } },
                                [_vm._v("关闭")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "prevent-re-click",
                                      rawName: "v-prevent-re-click"
                                    }
                                  ],
                                  attrs: { type: "primary" },
                                  on: { click: _vm.itemFormSubmit }
                                },
                                [_vm._v("确定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "三方物流", name: "三方物流" } },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.formLoading,
                              expression: "formLoading"
                            }
                          ],
                          ref: "itemForm2",
                          attrs: {
                            model: _vm.itemForm,
                            rules: _vm.itemFormRules2,
                            "label-width": "80px",
                            size: "small"
                          },
                          nativeOn: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物流公司", prop: "expressName" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.itemForm.expressName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.itemForm, "expressName", $$v)
                                    },
                                    expression: "itemForm.expressName"
                                  }
                                },
                                _vm._l(_vm.expressList, function(item) {
                                  return _c("el-option", {
                                    key: item.ID,
                                    attrs: {
                                      label: item.name,
                                      value: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物流单号",
                                prop: "expressNumber"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入物流单号" },
                                model: {
                                  value: _vm.itemForm.expressNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "expressNumber", $$v)
                                  },
                                  expression: "itemForm.expressNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "快递费用", prop: "expressFee" }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  precision: 2,
                                  "controls-position": "right",
                                  min: 0
                                },
                                model: {
                                  value: _vm.itemForm.expressFee,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "expressFee", $$v)
                                  },
                                  expression: "itemForm.expressFee"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "附件", prop: "attach" } },
                            [
                              _c("UploadComponent", {
                                ref: "UploadComponentRef",
                                attrs: {
                                  fileList: _vm.itemForm.attach,
                                  mode: "DRAG"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  maxlength: "100",
                                  "show-word-limit": ""
                                },
                                model: {
                                  value: _vm.itemForm.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemForm, "remark", $$v)
                                  },
                                  expression: "itemForm.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { align: "center" } },
                        [
                          _c("el-button", { on: { click: _vm.dialogClose } }, [
                            _vm._v("关闭")
                          ]),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "prevent-re-click",
                                  rawName: "v-prevent-re-click"
                                }
                              ],
                              attrs: { type: "primary" },
                              on: { click: _vm.itemFormSubmit2 }
                            },
                            [_vm._v("确定")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.itemForm.logisticsType === "无需物流"
        ? _c(
            "el-form",
            {
              ref: "noNeedForm",
              attrs: {
                model: _vm.noNeedForm,
                rules: _vm.noNeedFormRules,
                "label-width": "auto",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "出库核验员", prop: "warehouseCheckUid" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: "请选择",
                        filterable: ""
                      },
                      model: {
                        value: _vm.noNeedForm.warehouseCheckUid,
                        callback: function($$v) {
                          _vm.$set(_vm.noNeedForm, "warehouseCheckUid", $$v)
                        },
                        expression: "noNeedForm.warehouseCheckUid"
                      }
                    },
                    _vm._l(_vm.warehouseUserList, function(item) {
                      return _c("el-option", {
                        key: item.uid,
                        attrs: { label: item.name, value: item.uid }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { align: "center", "label-width": "0px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.noNeedConfirm()
                        }
                      }
                    },
                    [_vm._v("确认无需配送")]
                  ),
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("关闭")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }